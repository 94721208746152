const BETFAIR_API_BASE_URL = 'https://avai.fi';

export async function loginToBetfair() {
    this.betfairLoggingIn = true;
    
    try {
      const response = await fetch(`${BETFAIR_API_BASE_URL}/betfair/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userData.token}`
        },
        body: JSON.stringify({
          appKey: this.betfairAppKey,
          username: this.betfairUsername,
          password: this.betfairPassword
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Betfair login failed with status:', response.status);
        console.error('Error response:', errorText);
        throw new Error(`Login failed with status ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      
      if (data.status === 'SUCCESS') {
        this.betfairSessionToken = data.token;
        this.betfairLoginStatus = true;
        this.showToastMessage('Betfair kirjautuminen onnistui', 'success');
        
        // Test the connection with the new token
        await this.testBetfairConnection();
        
        // Start keep-alive interval after successful login
        this.startBetfairKeepAlive();
      } else {
        console.error('Betfair login failed:', data.error);
        throw new Error(data.error || 'Login failed');
      }
    } catch (error) {
      console.error('Betfair login error:', error);
      this.betfairLoginStatus = false;
      this.showToastMessage(`Betfair kirjautuminen epäonnistui: ${error.message}`, 'error');
      this.stopBetfairKeepAlive();
    } finally {
      this.betfairLoggingIn = false;
    }
  }

export async function testBetfairConnection() {
    this.testingConnection = true;
    try {

      const response = await fetch(`${BETFAIR_API_BASE_URL}/betfair/test-connection`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userData.token}`
        },
        body: JSON.stringify({
          sessionToken: this.betfairSessionToken,
          appKey: this.betfairAppKey
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Backend test connection failed:', errorText);
        throw new Error(`Connection test failed: ${errorText}`);
      }

      this.showToastMessage('Betfair yhteys toimii', 'success');
      
      // Fetch initial balance after successful connection test
      await this.fetchBetfairBalance();
      
    } catch (error) {
      console.error('Backend test connection error:', error);
      this.showToastMessage('Betfair yhteyden testaus epäonnistui, tai token on vanhentunut', 'error');
    } finally {
      this.testingConnection = false;
    }
  }

export async function fetchBetfairBalance() {
    this.fetchingBalance = true;
    try {     
      const response = await fetch(`${BETFAIR_API_BASE_URL}/betfair/get-balance`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.userData.token}`
        },
        body: JSON.stringify({
          sessionToken: this.betfairSessionToken,
          appKey: this.betfairAppKey
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch balance');
      }

      const data = await response.json();
      
      this.betfairBalance = data.availableToBetBalance.toFixed(2);
      this.betfairExposure = Math.abs(data.exposure).toFixed(2);
      
    } catch (error) {
      console.error('Error fetching Betfair balance:', error);
      this.showToastMessage('Saldon haku epäonnistui', 'error');
      this.betfairBalance = null;
      this.betfairExposure = null;
    } finally {
      this.fetchingBalance = false;
    }
  }

export function handleBetfairApiChange() {
    console.log('Betfair API setting changed:', {
      enabled: this.betfairApiEnabled
    });
    
    if (!this.betfairApiEnabled) {
      console.log('Clearing Betfair credentials and token');
      this.betfairUsername = '';
      this.betfairPassword = '';
      this.betfairSessionToken = '';
      this.betfairLoginStatus = false;
      this.stopBetfairKeepAlive();
    }
  }

export function startBetfairKeepAlive() {
    this.stopBetfairKeepAlive();
    this.lastBetfairKeepAlive = new Date();
    
    this.betfairKeepAliveInterval = setInterval(async () => {
      console.log('Running Betfair keep-alive...');
      await this.testBetfairConnection();
      this.lastBetfairKeepAlive = new Date();
    }, 36000000);
    
    console.log('Betfair keep-alive started');
  }

export function stopBetfairKeepAlive() {
    if (this.betfairKeepAliveInterval) {
      clearInterval(this.betfairKeepAliveInterval);
      this.betfairKeepAliveInterval = null;
      console.log('Betfair keep-alive stopped');
    }
  }

export async function fetchBetfairOrders() {
  try {
    const response = await fetch(`${BETFAIR_API_BASE_URL}/betfair/list-current-orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.userData.token}`
      },
      body: JSON.stringify({
        sessionToken: this.betfairSessionToken,
        appKey: this.betfairAppKey
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Betfair orders request failed:', errorText);
      throw new Error(`Failed to fetch orders: ${errorText}`);
    }

    const data = await response.json();
    
    // Store the orders in the component's data
    this.betfairCurrentOrders = data.currentOrders || [];
    
    return data;
  } catch (error) {
    console.error('Error fetching Betfair orders:', error);
    this.showToastMessage('Vedonlyöntihistorian haku epäonnistui', 'error');
    throw error;
  }
}

export async function cancelBetfairOrder(marketId, betId, sizeReduction = null) {
  try {
    const response = await fetch(`${BETFAIR_API_BASE_URL}/betfair/cancel-orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionToken: this.betfairSessionToken,
        appKey: this.betfairAppKey,
        marketId,
        betId,
        sizeReduction
      })
    });

    if (!response.ok) {
      const error = await response.text();
      throw new Error(error);
    }

    const result = await response.json();
    
    // Refresh orders after cancellation
    await this.fetchBetfairOrders();
    
    return result;
  } catch (error) {
    console.error('Error canceling Betfair order:', error);
    this.showToastMessage('Vedon peruminen epäonnistui: ' + error.message, 'error');
    throw error;
  }
}

function roundToValidOdds(odds, isBack) {
  // Helper function to round to nearest increment
  function roundToIncrement(value, increment, roundUp) {
    const divided = value / increment;
    const rounded = roundUp ? Math.ceil(divided) : Math.floor(divided);
    return (rounded * increment).toFixed(2);
  }

  // Determine increment and round based on odds range
  if (odds >= 2 && odds <= 3) {
    return roundToIncrement(odds, 0.02, isBack);
  } else if (odds > 3 && odds <= 4) {
    return roundToIncrement(odds, 0.05, isBack);
  } else if (odds > 4 && odds <= 6) {
    return roundToIncrement(odds, 0.1, isBack);
  } else if (odds > 6 && odds <= 10) {
    return roundToIncrement(odds, 0.2, isBack);
  }
  return odds.toFixed(2);
}

export async function placeBetfairOrder() {
  if (!this.betfairApiEnabled || !this.betfairLoginStatus) {
    try {
      this.showToastMessage('Betfair yhteys katkennut. Yritetään kirjautua uudelleen...', 'info', 5000);
      await this.loginToBetfair();
      if (!this.betfairLoginStatus) {
        this.showToastMessage('Automaattinen uudelleenkirjautuminen epäonnistui', 'error', 5000);
        return;
      }
    } catch (error) {
      this.showToastMessage('Automaattinen uudelleenkirjautuminen epäonnistui', 'error', 5000);
      return;
    }
  }

  const value = (this.selectedOdds.oddsRatio - 1) * 100;
  if (value < 0 && this.betfairOrderType === 'BACK') {
    const typedOdds = parseFloat(this.betfairOdds);
    const originalOdds = parseFloat(this.selectedOdds.odds);
    const oddsImprovement = ((typedOdds / originalOdds - 1) * 100);
    
    // Only show warning if the odds improvement doesn't cover the negative value
    if (oddsImprovement < Math.abs(value)) {
      const confirmed = window.confirm('Vedon alkuperäinen value on ' + value.toFixed(1) + '%. Jos kerrointa ei ole korotettu manuaalisesti on veto todennäkoisesti tappiollinen. Haluatko jatkaa?');
      if (!confirmed) {
        return;
      }
    }
  }

  try {
    this.placingBetfairOrder = true;

    const odds = parseFloat(this.betfairOdds);
    const isBack = this.betfairOrderType === 'BACK';
    const adjustmentFactor = isBack ? 1.02 : 0.98;
    const adjustedOdds = parseFloat(((odds - 1) * adjustmentFactor + 1).toFixed(2));
    
    // Round to valid Betfair increment
    const finalOdds = parseFloat(roundToValidOdds(adjustedOdds, isBack));

    const response = await fetch(`${BETFAIR_API_BASE_URL}/betfair/place-orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionToken: this.betfairSessionToken,
        appKey: this.betfairAppKey,
        marketId: this.selectedOdds.betfairMarketId,
        selectionId: this.selectedOdds.betfairRunnerId,
        side: this.betfairOrderType,
        size: parseFloat(this.betfairStake),
        price: finalOdds,
        handicap: this.selectedOdds.handicap || null
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to place order');
    }

    const result = await response.json();
    if (result.status === 'SUCCESS') {
      this.showToastMessage('Veto asetettu onnistuneesti', 'success', 5000);
      this.showOddsDetails = false;
      await this.fetchBetfairOrders();
    } else {
      this.showToastMessage(`Virhe vedon asettamisessa: ${result.errorCode}`, 'error', 5000);
    }
  } catch (error) {
    this.showToastMessage(`Virhe (Koodi 2) vedon asettamisessa: ${error.message}`, 'error', 5000);
  } finally {
    this.placingBetfairOrder = false;
  }
}

export function findMatchByBetfairMarketId(marketId) {
  return this.nestedMatches.find(match => {
    return Object.values(match.odds).some(market => 
      Object.values(market).some(outcome => {
        if (outcome.betfair_market_id) {
          return outcome.betfair_market_id === marketId;
        }
        return Object.values(outcome).some(handicap => 
          handicap.betfair_market_id === marketId
        );
      })
    );
  });
}

export function validateStake() {
  try {
    if (this.betfairStake > 200) {
      this.betfairStake = 200;
    }

    const typedOdds = parseFloat(this.betfairOdds);
    const originalOdds = parseFloat(this.selectedOdds.odds);

    if (parseFloat(this.betfairStake) > this.selectedOdds.panosraja && 
      typedOdds >= originalOdds && 
      this.betfairOrderType === 'BACK') {
      this.stakeWarning = "Koko panosta ei todennäköisesti hyväksytä.";
    } else {
      this.stakeWarning = null;
    }
    
  } catch (error) {
    console.error('Error validating stake:', error);
    this.stakeWarning = null;
  }
}

export function validateBetfairOdds() {
  if (!this.betfairOdds) {
    this.oddsError = null;
    return;
  }

  const odds = parseFloat(this.betfairOdds);
  
  if (odds >= 2 && odds <= 3) {
    // Check if odds have exactly 0.02 decimal increments
    const decimalPart = (odds % 1).toFixed(2);
    const validDecimals = Array.from({length: 50}, (_, i) => (i * 0.02).toFixed(2));
    if (!validDecimals.includes(decimalPart)) {
      this.oddsError = 'Kertoimien 2-3 välillä täytyy olla tasan 0.02 desimaalin välein (esim. 2.02, 2.04)';
      return;
    }
  } else if (odds > 3 && odds <= 4) {
    // Check if odds have exactly 0.05 decimal increments
    const decimalPart = (odds % 1).toFixed(2);
    const validDecimals = Array.from({length: 20}, (_, i) => (i * 0.05).toFixed(2));
    if (!validDecimals.includes(decimalPart)) {
      this.oddsError = 'Kertoimien 3-4 täytyy olla tasan 0.05 desimaalin välein (esim. 3.05, 3.10)';
      return;
    }
  } else if (odds > 4 && odds <= 6) {
    // Check if odds have exactly 0.1 decimal increments
    const decimalPart = (odds % 1).toFixed(2);
    const validDecimals = Array.from({length: 10}, (_, i) => (i * 0.1).toFixed(2));
    if (!validDecimals.includes(decimalPart)) {
      this.oddsError = 'Kertoimien 4-6 täytyy olla tasan 0.1 desimaalin välein (esim. 4.1, 4.2)';
      return;
    }
  } else if (odds > 6 && odds <= 10) {
    // Check if odds have exactly 0.2 decimal increments
    const decimalPart = (odds % 1).toFixed(2);
    const validDecimals = Array.from({length: 5}, (_, i) => (i * 0.2).toFixed(2));
    if (!validDecimals.includes(decimalPart)) {
      this.oddsError = 'Kertoimien 6-10 täytyy olla tasan 0.2 desimaalin välein (esim. 6.2, 6.4)';
      return;
    }
  }

  this.oddsError = null;
}
