import axios from 'axios';

const API_URL = window.location.hostname === 'localhost' 
  ? 'http://localhost:5364'
  : 'https://avai.fi';

export { API_URL };

const getToken = () => {
  const userData = localStorage.getItem('userData') || sessionStorage.getItem('userData');
  if (userData) {
    const parsed = JSON.parse(userData);
    return parsed.token;
  }
  return null;
};

export const settingsService = {
  async getAllSettings() {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }
    
    const response = await axios.get(`${API_URL}/settings`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  },

  async saveSetting(key, value) {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await axios.post(`${API_URL}/settings`, 
      { key, value: JSON.stringify(value) },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  },

  async deleteSetting(key) {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await axios.delete(`${API_URL}/settings/${key}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  }
}; 