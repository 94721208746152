<style src="./styles.css"></style>
<template>
  <v-app :class="{ 'dark-theme': darkMode }" :theme="darkMode ? 'dark' : 'light'">
    <v-main class="pa-0">
      <template v-if="!isAuthenticated">
        <LoginForm 
          @login-success="handleLoginSuccess"
          @show-toast="(data) => showToastMessage(data.message, data.type)"
        />
      </template>
      
      <template v-else>
        <!-- Add transitions for elements -->
        <transition name="fade" max-height="20px">
          <div>
            <v-snackbar
              v-model="showToast"
              :color="toastType"
              :timeout="toastTimeout"
              top
            >
              {{ toastMessage }}
              <template v-slot:actions>
                <v-btn color="white" text @click="showToast = false">Sulje</v-btn>
              </template>
            </v-snackbar>
          </div>
        </transition>
        <v-app-bar app v-once>
          <v-text-field
            v-model="searchTerm"
            label="Haku"
            clearable
            hide-details
            class="mx-4"
            style="max-width: 220px; font-size: 0.8rem;"
            ref="searchField"
            density="compact"
            variant="outlined"
          ></v-text-field>
          
          <v-spacer></v-spacer>

          <!-- Center filters -->
          <v-row align="center" justify="center" class="mx-0" v-if="!$vuetify.display.smAndDown">
            <v-col cols="auto">
              <v-text-field
                v-model="valueFilter"
                label="Value"
                type="number"
                step="0.5"
                @input="handleFilterChange"
                hide-details
                density="compact"
                variant="outlined"
                class="mx-2 filter-field"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-model="payoutFilter"
                label="Palautus"
                type="number"
                step="0.5"
                @input="handleFilterChange"
                hide-details
                density="compact"
                variant="outlined"
                class="mx-2 filter-field"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-model="maxOddsFilter"
                label="Kerroin"
                type="number"
                step="0.5"
                @input="handleFilterChange"
                hide-details
                density="compact"
                variant="outlined"
                class="mx-2 filter-field"
              ></v-text-field>
            </v-col>
          </v-row>
          
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-icon
                :color="isConnected ? 'green' : 'red'"
                :class="{ 'blink': !isConnected }"
                size="x-small"
                v-bind="props"
                class="mr-2 connection-status"
              >
                mdi-circle
              </v-icon>
            </template>
            <span class="text-caption">{{ isConnected ? 'Yhdistetty' : 'Ei yhteyttä' }}</span>
          </v-tooltip>

          <v-btn icon @click="showSettings = true">
            <v-icon>mdi-cog</v-icon>
            <v-tooltip activator="parent" location="bottom">
              Asetukset
            </v-tooltip>
          </v-btn>

          <v-btn icon @click="showInfo = true">
            <v-icon>mdi-information</v-icon>
            <v-tooltip activator="parent" location="bottom">
              Tietoa
            </v-tooltip>
          </v-btn>

          <v-btn icon @click="showTracking = true">
            <v-icon>mdi-chart-line</v-icon>
            <v-tooltip activator="parent" location="bottom">
              Seuranta
            </v-tooltip>
          </v-btn>

          <v-btn icon @click="showLiveEvents = true">
            <v-icon>mdi-chart-box</v-icon>
            <v-tooltip activator="parent" location="bottom">
              Live-kertoimet
            </v-tooltip>
          </v-btn>

          <v-btn
            v-if="betfairApiEnabled"
            icon
            @click="showBetfairPanel = true"
            class="mr-2"
          >
            B
            <v-tooltip activator="parent" location="bottom">
              Betfair
            </v-tooltip>
          </v-btn>

        </v-app-bar>
        <v-main>
          <v-container>
            <v-card>
              <v-card-text>
                <v-row class="mb-0" v-once>
                  <template v-if="$vuetify.display.width > 955">
                    <!-- Desktop version -->
                    <v-col cols="12" sm="3" v-for="(field, index) in sortFields" :key="index">
                      <v-btn 
                        block
                        size="small"
                        @click="setSorting(field.key)"
                      >
                        {{ field.label }}
                        <span v-show="sortBy === field.key">{{ sortDesc ? '▼' : '▲' }}</span>
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
                <!-- Add a simple loading indicator -->
                <template v-if="isLoading">
                  <div class="loading-overlay" :style="{ opacity: 0.7 }">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      size="32"
                      width="3"
                    ></v-progress-circular>
                  </div>
                </template>
                <div v-show="!isLoading && !hasError">

                  <!-- Replace v-virtual-scroll with a regular div -->
                  <div class="match-list">
                    <template v-for="item in paginatedMatches" :key="item.common_id">
                      <v-list-item 
                        @click="toggleMatchDetails(item.common_id)"
                        class="match-item"
                        :class="{ 
                          'selected': selectedMatchId === item.common_id,
                          'filtered-out': !matchPassesFilters(item),
                          'below-threshold': matchesBelowThreshold[item.common_id],
                          'veikkaus-text': item.bookie === 'veikkaus',
                          'nubet-text': item.bookie === 'nubet',
                          'pinnacle-text': item.bookie === 'pinnacle',
                          'betfair-text': item.bookie === 'betfair',
                          'prediction-text': item.bookie === 'prediction'
                        }"
                      >
                        <v-list-item-title class="d-flex align-center">
                          <v-tooltip location="top" v-if="item.home.length > 22 || item.away.length > 22">
                            <template v-slot:activator="{ props }">
                              <span v-bind="props" :class="$vuetify.display.smAndDown ? 'text-caption' : 'text-subtitle-1'">
                                {{ item.home }} vs {{ item.away }}
                              </span>
                            </template>
                            <span>{{ item.home }} vs {{ item.away }}</span>
                          </v-tooltip>
                          <span v-else :class="$vuetify.display.smAndDown ? 'text-caption' : 'text-subtitle-1'">
                            {{ item.home }} vs {{ item.away }}
                          </span>
                          <v-icon 
                            v-if="playedMatches[item.common_id]" 
                            :size="$vuetify.display.smAndDown ? 'x-small' : 'small'" 
                            color="red" 
                            class="ml-2"
                          >mdi-check-circle</v-icon>
                          <v-icon 
                            v-if="matchesBelowThreshold[item.common_id]" 
                            :size="$vuetify.display.smAndDown ? 'x-small' : 'small'" 
                            color="orange" 
                            class="ml-2"
                          >mdi-alert</v-icon>
                          <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                              <span
                                v-if="matchesAboveThreshold[item.common_id] && 
                                      valueLastChanged[item.common_id] && 
                                      getMinutesAgo(valueLastChanged[item.common_id]) >= 3"
                                :class="[
                                  $vuetify.display.smAndDown ? 'text-caption' : 'text-subtitle-2',
                                  'ml-2 d-flex align-center',
                                  'text-white'
                                ]"
                                v-bind="props"
                              >
                                <v-icon 
                                  :size="$vuetify.display.smAndDown ? 'x-small' : 'small'"
                                  color="white"
                                  class="mr-1"
                                >
                                  mdi-clock-outline
                                </v-icon>
                                {{ getMinutesAgoText(valueLastChanged[item.common_id]) }}
                              </span>
                            </template>
                            <span>Value ei ole muuttunut {{ getMinutesAgo(valueLastChanged[item.common_id]) }} minuuttiin. Tämä voi johtua vedonvälittäjän luottamuksesta kertoimeen.</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <v-list-item-subtitle :class="$vuetify.display.smAndDown ? 'text-caption' : 'text-body-2'">
                          <div class="d-flex" :class="$vuetify.display.smAndDown ? 'flex-column' : ''">
                            <div>
                              <span class="d-none d-sm-inline">Alkaa: </span>{{ item.start_time }}
                              <span class="d-none d-sm-inline"> | Laji: </span>{{ item.sport }}
                              <template v-if="item.league">
                                <span class="d-none d-sm-inline"> | Sarja: </span>{{ item.league }}
                                <v-tooltip v-if="isFinnishLeague(item.league)" location="bottom">
                                  <template v-slot:activator="{ props }">
                                    <v-icon
                                      v-bind="props"
                                      color="warning"
                                      size="small"
                                      class="ml-1"
                                    >
                                      mdi-alert
                                    </v-icon>
                                  </template>
                                  <span>Suomalainen sarja, vältä pelaamista Veikkaukselle.</span>
                                </v-tooltip>
                              </template>
                            </div>
                            
                            <span 
                              class="best-value-chip" 
                              :style="[
                                item.bestValueStyle,
                                $vuetify.display.smAndDown ? 'margin-top: 4px' : '',
                                'display: inline-block',
                                'width: fit-content'
                              ]"
                              @click.stop="showOddsDetailsFromBestValue(item)"
                              style="cursor: pointer;"
                            >
                              {{ item.bestValue }}
                              <template v-if="showDetailedBestValue && item.bestValueDetails">
                                | {{ item.bestValueDetails.market }}
                                {{ item.bestValueDetails.outcome }}
                                <template v-if="item.bestValueDetails.handicap">
                                  {{ item.bestValueDetails.handicap }}
                                </template>
                                @ {{ item.bestValueDetails.odds }}
                                <template v-if="item.bestValueDetails.bookie">
                                  ({{ item.bestValueDetails.bookie }})
                                </template>
                                | {{ item.bestValueDetails.recommendedStake }}€
                              </template>
                            </span>
                          </div>
                        </v-list-item-subtitle>
                        <template v-slot:append>
                          <!-- Desktop version -->
                          <div class="d-none d-sm-flex align-center">
                            <v-btn 
                              icon="mdi-play-circle" 
                              size="medium" 
                              @click.stop="togglePlayedStatus(item.common_id)" 
                              class="mr-1"
                              :title="'Merkkaa pelatuksi (pikanäppäin L)'"
                            ></v-btn>
                            
                            <v-menu location="bottom end">
                              <template v-slot:activator="{ props }">
                                <v-btn 
                                  icon="mdi-eye-off" 
                                  size="medium" 
                                  v-bind="props"
                                  :title="'Piilota/poista ottelu (pikanäppäin: Delete)'"
                                ></v-btn>
                              </template>
                              <v-list density="compact">
                                <v-list-item
                                  v-for="(duration, index) in hideDurations"
                                  :key="index"
                                  @click="hideMatch(item, duration.value)"
                                >
                                  <v-list-item-title>{{ duration.label }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-icon size="small" class="ml-1">
                              {{ selectedMatchId === item.common_id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </div>

                          <!-- Mobile version -->
                          <div class="d-flex d-sm-none align-center">
                            <v-menu location="bottom end">
                              <template v-slot:activator="{ props }">
                                <v-btn 
                                  icon="mdi-dots-vertical" 
                                  size="small" 
                                  v-bind="props"
                                ></v-btn>
                              </template>
                              <v-list density="compact">
                                <v-list-item
                                  dense
                                  @click.stop="togglePlayedStatus(item.common_id)"
                                >
                                  <template v-slot:prepend>
                                    <v-icon size="small">mdi-play-circle</v-icon>
                                  </template>
                                  <v-list-item-title class="text-caption">Merkkaa pelatuksi</v-list-item-title>
                                </v-list-item>
                                
                                <v-divider></v-divider>
                                
                                <v-list-item
                                  v-for="(duration, index) in hideDurations"
                                  :key="index"
                                  dense
                                  @click="hideMatch(item, duration.value)"
                                >
                                  <template v-slot:prepend>
                                    <v-icon size="small">mdi-eye-off</v-icon>
                                  </template>
                                  <v-list-item-title class="text-caption">{{ duration.label }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-icon size="small" class="ml-1">
                              {{ selectedMatchId === item.common_id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </div>
                        </template>
                      </v-list-item>
                      <div 
                        v-if="selectedMatchId === item.common_id" 
                        class="nested-details"
                        :class="{ 'expanded': selectedMatchId === item.common_id }"
                      >
                        <div class="market-grid">
                          <div
                            v-for="(marketData, marketName) in sortedMarkets(item.odds)"
                            :key="marketName"
                            class="market-section"
                          >
                            <h5>{{ marketName }}</h5>
                            <v-table dense class="market-section">
                              <thead>
                                <tr>
                                  <template v-if="!(
                                    (marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle') && 
                                    $vuetify.display.width <= 770
                                  )">
                                    <th v-for="header in getTableHeaders(marketName)" :key="header" class="text-left">
                                      {{ header }}
                                    </th>
                                  </template>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-if="marketName === '1X2' || marketName === 'Voittaja'">
                                  <tr v-for="(oddsData, outcomeName) in marketData" :key="outcomeName" class="hoverable-odds-row">
                                    <td class="text-left">{{ outcomeName }}</td>
                                    <td
                                      v-for="bookie in bookieOrder"
                                      :key="bookie"
                                      @click="handleOddsClick(item.common_id, marketName, outcomeName, bookie, getOddsValue(oddsData, bookie).value)"
                                      :class="[
                                        'bookie-cell',
                                        'odds-cell',
                                        getOddsValue(oddsData, bookie).bookie || bookie,
                                        getOddsMovement(item.common_id, marketName, outcomeName, bookie),
                                        `${bookie}-text`,
                                        {
                                          'odds-up-flash': getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'up',
                                          'odds-down-flash': getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'down',
                                          'odds-added-flash': getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'added',
                                          'odds-removed-flash': getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'removed'
                                        }
                                      ]"
                                      :style="bookie === 'top' ? getTopOddsStyle(oddsData) : ''"
                                      class="odds-cell"
                                      style="cursor: pointer;"
                                      :data-match-id="item.common_id"
                                      :data-market="marketName"
                                      :data-outcome="outcomeName"
                                      :data-bookie="bookie"
                                    >
                                      <span class="odds-value-container">
                                        {{ getOddsValue(oddsData, bookie).value }}
                                        <v-icon
                                          v-if="saveOddsHistory && bookie !== 'prediction' && bookie !== 'top'"
                                          @click.stop="showOddsHistoryModal(item.common_id, marketName, outcomeName, bookie)"
                                          size="x-small"
                                          class="odds-movement-icon"
                                          :color="getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'up' ? 'green' :
                                                 getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'down' ? 'red' :
                                                 getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'added' ? 'blue' :
                                                 getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'removed' ? 'orange' : ''"
                                        >
                                          {{ getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'up' ? 'mdi-arrow-up' : 
                                             getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'down' ? 'mdi-arrow-down' :
                                             getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'added' ? 'mdi-plus' :
                                             getOddsMovement(item.common_id, marketName, outcomeName, bookie, null, 'direction') === 'removed' ? 'mdi-minus' : ''
                                          }}
                                        </v-icon>
                                      </span>
                                    </td>
                                    <td :style="getValueCellStyle(getOddsAndValue(oddsData, outcomeName, marketData, item.common_id).value)">
                                      {{ formatValueDisplay(getOddsAndValue(oddsData, outcomeName, marketData, item.common_id).value) }}
                                    </td>
                                  </tr>
                                  <tr class="hoverable-odds-row">
                                    <td class="text-left">Palautus</td>
                                    <td
                                      v-for="bookie in bookieOrder"
                                      :key="'payout-' + bookie"
                                      :style="getPayoutCellStyle(calculatePayout(marketData, bookie))"
                                    >
                                      {{ formatPayoutDisplay(calculatePayout(marketData, bookie)) }}
                                    </td>
                                    <td colspan="2"></td>
                                  </tr>
                                </template>
                                <template v-else-if="marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle'">
                                  <template v-if="$vuetify.display.width <= 770">
                                    <div class="handicap-table-container split-view">
                                      <!-- Left side table -->
                                      <div class="handicap-table left-side">
                                        <v-table class="market-section">
                                          <thead>
                                            <tr>
                                              <th>{{ marketName === 'Yli/Alle' ? 'Yli' : 'Koti' }}</th>
                                              <th v-for="bookie in bookieOrder" :key="'left-header-' + bookie">
                                                {{ bookieAbbreviations[bookie] || bookie }}
                                              </th>
                                              <th>Value</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <template v-for="(row, index) in generatePairedHandicapRows(marketData, marketName, item.common_id)" :key="index">
                                              <tr class="hoverable-odds-row">
                                                <td>{{ row.handicap }}</td>
                                                <td v-for="bookie in bookieOrder"
                                                    :key="'left-' + bookie + '-' + index"
                                                    @click="handleOddsClick(item.common_id, marketName, row.leftLabel, bookie, getOddsValue(row.leftOdds, bookie).value, row.handicap)"
                                                    :class="[
                                                      'bookie-cell', 
                                                      'odds-cell', 
                                                      getOddsValue(row.leftOdds, bookie).bookie || bookie,
                                                      getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap),
                                                      {
                                                        'odds-up-flash': getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'up',
                                                        'odds-down-flash': getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'down',
                                                        'odds-added-flash': getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'added',
                                                        'odds-removed-flash': getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'removed'
                                                      }
                                                    ]"
                                                    style="cursor: pointer;">
                                                  <span class="odds-value-container">
                                                    {{ getOddsValue(row.leftOdds, bookie).value }}
                                                    <v-icon
                                                      v-if="saveOddsHistory && bookie !== 'prediction' && bookie !== 'top'"
                                                      @click.stop="showOddsHistoryModal(item.common_id, marketName, row.leftLabel, bookie, row.handicap)"
                                                      size="x-small"
                                                      class="odds-movement-icon"
                                                      :color="getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'up' ? 'green' :
                                                             getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'down' ? 'red' :
                                                             getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'added' ? 'blue' :
                                                             getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'removed' ? 'orange' : ''"
                                                    >
                                                      {{ getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'up' ? 'mdi-arrow-up' : 
                                                         getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'down' ? 'mdi-arrow-down' :
                                                         getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'added' ? 'mdi-plus' :
                                                         getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'removed' ? 'mdi-minus' : ''
                                                      }}
                                                    </v-icon>
                                                  </span>
                                                </td>
                                                <td :style="getValueCellStyle(row.leftValue)">
                                                  {{ formatValueDisplay(row.leftValue) }}
                                                </td>
                                              </tr>
                                            </template>
                                          </tbody>
                                        </v-table>
                                      </div>
                                      <!-- Right side table -->
                                      <div class="handicap-table right-side">
                                        <v-table class="market-section">
                                          <thead>
                                            <tr>
                                              <th>{{ marketName === 'Yli/Alle' ? 'Alle' : 'Vieras' }}</th>
                                              <th v-for="bookie in bookieOrder" :key="'right-header-' + bookie">
                                                {{ bookieAbbreviations[bookie] || bookie }}
                                              </th>
                                              <th>Value</th>
                                              <th>Palautus</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <template v-for="(row, index) in generatePairedHandicapRows(marketData, marketName, item.common_id)" :key="index">
                                              <tr class="hoverable-odds-row">
                                                <!-- Change this line to show opposite handicap for Vieras -->
                                                <td>{{ marketName === 'Aasialainen tasoitus' ? getOppositeHandicap(row.handicap) : row.handicap }}</td>
                                                <td v-for="bookie in bookieOrder"
                                                    :key="'right-' + bookie + '-' + index"
                                                    @click="handleOddsClick(item.common_id, marketName, row.rightLabel, bookie, getOddsValue(row.rightOdds, bookie).value, row.correspondingHandicap)"
                                                    :class="[
                                                      'bookie-cell', 
                                                      'odds-cell', 
                                                      getOddsValue(row.rightOdds, bookie).bookie || bookie, 
                                                      getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap),
                                                      {
                                                        'odds-up-flash': getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'up',
                                                        'odds-down-flash': getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'down',
                                                        'odds-added-flash': getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'added',
                                                        'odds-removed-flash': getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'removed'
                                                      }
                                                    ]">
                                                  <span class="odds-value-container">
                                                    {{ getOddsValue(row.rightOdds, bookie).value }}
                                                    <v-icon
                                                      v-if="saveOddsHistory && bookie !== 'prediction' && bookie !== 'top'"
                                                      @click.stop="showOddsHistoryModal(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap)"
                                                      size="x-small"
                                                      class="odds-movement-icon"
                                                      :color="getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'up' ? 'green' :
                                                             getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'down' ? 'red' :
                                                             getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'added' ? 'blue' :
                                                             getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'removed' ? 'orange' : ''"
                                                    >
                                                      {{ getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'up' ? 'mdi-arrow-up' : 
                                                         getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'down' ? 'mdi-arrow-down' :
                                                         getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'added' ? 'mdi-plus' :
                                                         getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'removed' ? 'mdi-minus' : ''
                                                      }}
                                                    </v-icon>
                                                  </span>
                                                </td>
                                                <td :style="getValueCellStyle(row.rightValue)">
                                                  {{ formatValueDisplay(row.rightValue) }}
                                                </td>
                                                <td :style="getPayoutCellStyle(row.payout)">
                                                  {{ formatPayoutDisplay(row.payout) }}
                                                </td>
                                              </tr>
                                            </template>
                                          </tbody>
                                        </v-table>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <!-- Original single table view -->
                                    <tr v-for="(row, index) in generatePairedHandicapRows(marketData, marketName, item.common_id)" :key="index" class="hoverable-odds-row">
                                      <td>{{ row.handicap }}</td>
                                      <td v-for="bookie in bookieOrder"
                                          :key="'left-' + bookie + '-' + index"
                                          @click="handleOddsClick(item.common_id, marketName, row.leftLabel, bookie, getOddsValue(row.leftOdds, bookie).value, row.handicap)"
                                          :class="[
                                            'bookie-cell', 
                                            'odds-cell', 
                                            getOddsValue(row.leftOdds, bookie).bookie || bookie,
                                            getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap),
                                            {
                                              'odds-up-flash': getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'up',
                                              'odds-down-flash': getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'down',
                                              'odds-added-flash': getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'added',
                                              'odds-removed-flash': getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'removed'
                                            }
                                          ]">
                                        <span class="odds-value-container">
                                          {{ getOddsValue(row.leftOdds, bookie).value }}
                                          <v-icon
                                            v-if="saveOddsHistory && bookie !== 'prediction' && bookie !== 'top'"
                                            @click.stop="showOddsHistoryModal(item.common_id, marketName, row.leftLabel, bookie, row.handicap)"
                                            size="x-small"
                                            class="odds-movement-icon"
                                            :color="getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'up' ? 'green' :
                                                   getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'down' ? 'red' :
                                                   getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'added' ? 'blue' :
                                                   getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'removed' ? 'orange' : ''"
                                          >
                                            {{ getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'up' ? 'mdi-arrow-up' : 
                                               getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'down' ? 'mdi-arrow-down' :
                                               getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'added' ? 'mdi-plus' :
                                               getOddsMovement(item.common_id, marketName, row.leftLabel, bookie, row.handicap, 'direction') === 'removed' ? 'mdi-minus' : ''
                                            }}
                                          </v-icon>
                                        </span>
                                      </td>
                                      <td :style="getValueCellStyle(row.leftValue)">
                                        {{ formatValueDisplay(row.leftValue) }}
                                      </td>
                                      <td>{{ row.correspondingHandicap }}</td>
                                      <td v-for="bookie in bookieOrder"
                                          :key="'right-' + bookie + '-' + index"
                                          @click="handleOddsClick(item.common_id, marketName, row.rightLabel, bookie, getOddsValue(row.rightOdds, bookie).value, row.correspondingHandicap)"
                                          :class="[
                                            'bookie-cell', 
                                            'odds-cell', 
                                            getOddsValue(row.rightOdds, bookie).bookie || bookie,  // Use the actual bookie from odds value
                                            getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap),
                                            {
                                              'odds-up-flash': getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'up',
                                              'odds-down-flash': getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'down',
                                              'odds-added-flash': getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'added',
                                              'odds-removed-flash': getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'removed'
                                            }
                                          ]">
                                        <span class="odds-value-container">
                                          {{ getOddsValue(row.rightOdds, bookie).value }}
                                          <v-icon
                                            v-if="saveOddsHistory && bookie !== 'prediction' && bookie !== 'top'"
                                            @click.stop="showOddsHistoryModal(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap)"
                                            size="x-small"
                                            class="odds-movement-icon"
                                            :color="getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'up' ? 'green' :
                                                   getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'down' ? 'red' :
                                                   getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'added' ? 'blue' :
                                                   getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'removed' ? 'orange' : ''"
                                          >
                                            {{ getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'up' ? 'mdi-arrow-up' : 
                                               getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'down' ? 'mdi-arrow-down' :
                                               getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'added' ? 'mdi-plus' :
                                               getOddsMovement(item.common_id, marketName, row.rightLabel, bookie, row.correspondingHandicap, 'direction') === 'removed' ? 'mdi-minus' : ''
                                            }}
                                          </v-icon>
                                        </span>
                                      </td>
                                      <td :style="getValueCellStyle(row.rightValue)">
                                        {{ formatValueDisplay(row.rightValue) }}
                                      </td>
                                      <td :style="getPayoutCellStyle(row.payout)">
                                        {{ formatPayoutDisplay(row.payout) }}
                                      </td>
                                    </tr>
                                  </template>
                                </template>
                              </tbody>
                            </v-table>
                          </div>
                          </div>
                          <!-- Add new analysis section -->
                            <div v-if="matchAnalysis[item.common_id]" class="analysis-section mt-4">
                              <v-card>
                                <div class="d-flex align-center justify-space-between pa-2">
                                  <v-btn
                                    icon
                                    size="small"
                                    :loading="loadingAnalysis"
                                    @click="getMatchAnalysis(item)"
                                  >
                                    <v-icon>mdi-refresh</v-icon>
                                  </v-btn>
                                  <v-btn
                                    icon
                                    size="small"
                                    @click="matchAnalysis[item.common_id].isCollapsed = !matchAnalysis[item.common_id].isCollapsed"
                                  >
                                    <v-icon>{{ matchAnalysis[item.common_id].isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                  </v-btn>
                                </div>
                                <v-expand-transition>
                                  <div v-show="!matchAnalysis[item.common_id].isCollapsed">
                                    <v-card-text class="pa-4">
                                      <div v-html="formatAnalysis(matchAnalysis[item.common_id])"></div>
                                    </v-card-text>
                                    <div class="text-caption text-grey mt-2">
                                      Tiedot haettu {{ matchAnalysis[item.common_id].timestamp }}
                                    </div>
                                  </div>
                                </v-expand-transition>
                              </v-card>
                            </div>
                            
                            <button 
                              v-if="!matchAnalysis[item.common_id]"
                              @click="getMatchAnalysis(item)"
                              :disabled="loadingAnalysis"
                              class="analyze-button"
                            >
                              <span class="button-content">
                                <i class="fas fa-chart-line"></i>
                                {{ loadingAnalysis ? 'Haetaan...' : 'Hae tietoa' }}
                              </span>
                            </button>
                        </div>
                      </template>
                    </div>

                    <!-- Updated pagination bar with match count -->
                    <div class="pagination-container">
                      <div class="pagination-bar">
                        <span class="text-caption match-count">
                          {{ filteredMatches.length }} ottelua
                          <template v-if="filteredMatches.length !== paginatedMatches.length">
                            ({{ paginatedMatches.length }} näkyvissä)
                          </template>
                          <template v-if="filteredMatches.length !== nestedMatches.length">
                          {{ nestedMatches.length }} yhteensä
                        </template>
                        </span>
                        <v-pagination
                          v-model="currentPage"
                          :length="totalPages"
                          :total-visible="5"
                          class="pagination-custom"
                          density="compact"
                        ></v-pagination>
                      </div>
                    </div>
                    </div>
            </v-card-text>
          </v-card>
          </v-container>
        </v-main>
        <v-dialog v-model="showSettings" max-width="900" max-height="95vh" scrollable class="settings-dialog">
          <v-card>
            <div class="d-flex align-center pa-4 position-relative">
              <v-card-title class="text-h5 pa-0">Asetukset</v-card-title>
              <v-btn
                icon
                @click="showSettings = false"
                class="position-absolute"
                style="right: 8px; top: 8px;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            
            <v-divider></v-divider>

            <v-card-text style="height: auto; max-height: 80vh; overflow-y: auto;">
              <v-container>
                <!-- Filters for mobile -->
                <v-row v-if="$vuetify.display.smAndDown">
                  <v-col cols="12">
                    <div class="text-subtitle-1 font-weight-bold mb-2">Suodattimet</div>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="valueFilter"
                          label="Value"
                          type="number"
                          step="0.5"
                          @input="handleFilterChange"
                          hide-details
                          density="compact"
                          variant="outlined"
                          class="mx-2 filter-field"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="payoutFilter"
                          label="Palautus"
                          type="number"
                          step="0.5"
                          @input="handleFilterChange"
                          hide-details
                          density="compact"
                          variant="outlined"
                          class="mx-2 filter-field"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="maxOddsFilter"
                          label="Kerroin"
                          type="number"
                          step="0.5"
                          @input="handleFilterChange"
                          hide-details
                          density="compact"
                          variant="outlined"
                          class="mx-2 filter-field"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- Sorting buttons for mobile -->
                <v-row v-if="$vuetify.display.smAndDown">
                  <v-col cols="12">
                    <div class="text-subtitle-1 font-weight-bold mb-2">Järjestys</div>
                    <v-row>
                      <v-col cols="6" v-for="(field, index) in sortFields" :key="index">
                        <v-btn
                          block
                          :color="sortBy === field.key ? 'primary' : ''"
                          size="small"
                          @click="setSorting(field.key)"
                        >
                          {{ field.label }}
                          <span v-show="sortBy === field.key">{{ sortDesc ? '▼' : '▲' }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- General Settings -->
                <v-row>
                  <v-col cols="12">
                    <div class="text-subtitle-1 font-weight-bold mb-2">Yleiset asetukset</div>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="selectedSound"
                          :items="soundOptions"
                          item-title="text"
                          item-value="value"
                          label="Äänimerkki"
                          density="compact"
                          hide-details
                          class="mb-4"
                          @update:model-value="handleSoundChange"
                        ></v-select>

                        <v-text-field
                          v-model.number="notificationSettings.valueUpSpeed"
                          label="Value äänimerkin nopeus"
                          type="number"
                          min="0.5"
                          max="4"
                          step="0.5"
                          density="compact"
                          hide-details
                          class="mb-4"
                        ></v-text-field>

                        <v-text-field
                          v-model.number="notificationSettings.valueDropSpeed"
                          label="Value laskenut nopeus" 
                          type="number"
                          min="0.5"
                          max="4"
                          step="0.5"
                          density="compact"
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-switch v-model="showDetailedBestValue" label="Nopea vetotieto" density="compact" color="primary"></v-switch>
                        <v-switch v-model="saveOddsHistory" label="Tallenna kerroinhistoria" density="compact" color="primary"></v-switch>
                        <v-switch
                         v-model="darkMode"
                         label="Tumma teema"
                         density="compact"
                         color="primary"
                         hide-details
                         class="mb-4"
                       ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model.number="bankroll"
                          label="Pelikassa (€)"
                          type="number"
                          min="0"
                          step="500"
                          density="compact"
                          hide-details
                          class="mb-3"
                        ></v-text-field>
                        <v-select
                          v-model="stakeRounding"
                          :items="[
                            { title: 'Ei pyöristystä', value: 1 },
                            { title: '5€', value: 5 },
                            { title: '10€', value: 10 },
                            { title: '50€', value: 50 },
                            { title: '100€', value: 100 }
                          ]"
                          label="Panoksen pyöristys"
                          density="compact"
                          hide-details
                          class="mb-3"
                        ></v-select>
                        <v-select
                          v-model="itemsPerPage"
                          :items="pageSizeOptions"
                          label="Ottelua per sivu"
                          @change="handlePageSizeChange"
                          density="compact"
                          hide-details
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider class="my-4"></v-divider>

                <!-- Telegram Settings -->
                <v-row>
                  <v-col cols="12">
                    <div class="text-subtitle-1 font-weight-bold mb-2">Telegram-ilmoitukset</div>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-switch v-model="telegramEnabled" label="Telegram-viestit käytössä" density="compact" color="primary"></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="telegramToken"
                          label="Telegram Bot Token"
                          :disabled="!telegramEnabled"
                          density="compact"
                          hide-details
                          class="mb-3"
                          :type="showTelegramToken ? 'text' : 'password'"
                          :append-inner-icon="showTelegramToken ? 'mdi-eye-off' : 'mdi-eye'"
                          @click:append-inner="showTelegramToken = !showTelegramToken"
                        ></v-text-field>
                        <v-text-field
                          v-model="telegramChatId"
                          label="Telegram Chat ID"
                          :disabled="!telegramEnabled"
                          density="compact"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider class="my-4"></v-divider>

                <!-- Bookmaker Settings -->
                <v-row>
                  <v-col cols="12">
                    <div class="text-subtitle-1 font-weight-bold mb-2">Vedonvälittäjät</div>
                    <v-row>
                      <v-col 
                        v-for="(enabled, bookie) in filteredmanuallyenabledbookies" 
                        :key="bookie" 
                        cols="6" 
                        sm="4" 
                        md="3"
                      >
                        <v-checkbox
                          v-model="manuallyenabledbookies[bookie]"
                          :label="bookieAbbreviations[bookie] || bookie"
                          @change="handleBookieChange"
                          density="compact"
                          color="primary"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <label class="text-body-2 d-flex align-center mb-1">
                  <input 
                    type="checkbox" 
                    v-model="usePinnacleValues"
                    @change="handleValueToggle('usePinnacleValues')"
                    class="mr-1"
                  >
                  Herkempi Pinnacle value
                </label>
                <label class="text-body-2 d-flex align-center">
                  <input 
                    type="checkbox" 
                    v-model="useBetfairValues"
                    @change="handleValueToggle('useBetfairValues')"
                    class="mr-1"
                  >
                  Herkempi Betfair value
                </label>

                <v-divider class="my-4"></v-divider>

                <!-- Additional Tools -->
                <v-row>
                  <v-col cols="12">
                    <div class="text-subtitle-1 font-weight-bold mb-2">Työkalut</div>
                    <v-btn 
                      color="primary" 
                      variant="outlined" 
                      @click="showHiddenMatches = true"
                      class="mr-2"
                    >
                      <v-icon left>mdi-eye-off</v-icon>
                      Piilotetut ottelut
                    </v-btn>
                    <v-btn 
                      color="error" 
                      variant="outlined" 
                      @click="clearAllData"
                      class="mr-2"
                    >
                      <v-icon left>mdi-delete</v-icon>
                      Tyhjennä kaikki data
                    </v-btn>
                    <v-btn 
                      color="primary" 
                      variant="outlined" 
                      @click="showCalculationSettings = true"
                      class="mr-2"
                    >
                      <v-icon left>mdi-calculator-variant</v-icon>
                      Kerroinrajasäädöt
                    </v-btn>
                    <v-btn
                      color="primary" 
                      variant="outlined"
                      class="mr-2"
                      @click="showTeamMappingsEditor = true"
                    >
                      Joukkuenimet
                    </v-btn>
                  </v-col>
                </v-row>

                <v-divider class="my-4"></v-divider>
                
                <div class="text-h6 mb-2">Betfair API</div>
                <v-switch
                  v-model="betfairApiEnabled"
                  label="Käytä Betfair rajapintaa"
                  @change="handleBetfairApiChange"
                  density="compact"
                ></v-switch>

                <template v-if="betfairApiEnabled">
                  <div class="betfair-fields">
                    <v-text-field
                      v-model="betfairAppKey"
                      label="Betfair App Key"
                      :type="showBetfairToken ? 'text' : 'password'"
                      :append-icon="showBetfairToken ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="showBetfairToken = !showBetfairToken"
                      density="compact"
                    ></v-text-field>

                    <v-text-field
                      v-model="betfairUsername"
                      label="Betfair Käyttäjätunnus"
                      density="compact"
                    ></v-text-field>

                    <v-text-field
                      v-model="betfairPassword"
                      label="Betfair Salasana"
                      :type="showBetfairToken ? 'text' : 'password'"
                      :append-icon="showBetfairToken ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="showBetfairToken = !showBetfairToken"
                      density="compact"
                    ></v-text-field>

                    <div class="d-flex align-center">
                      <v-btn
                        color="primary"
                        :loading="betfairLoggingIn"
                        @click="loginToBetfair"
                        :disabled="!betfairAppKey || !betfairUsername || !betfairPassword"
                        class="mr-2"
                        density="compact"
                      >
                        {{ betfairLoginStatus ? 'Päivitä token' : 'Kirjaudu' }}
                      </v-btn>

                      <v-btn
                        color="secondary"
                        :loading="testingConnection"
                        @click="testBetfairConnection"
                        :disabled="!betfairLoginStatus"
                        density="compact"
                      >
                        Testaa yhteyttä
                      </v-btn>
                    </div>
                  </div>
                </template>

                <!-- Add spacing div -->
                <div class="my-8"></div>

                <!-- Admin buttons -->
                <template v-if="userData?.username === 'eemeli'">
                  <div class="d-flex justify-center">
                    
                    <v-btn
                      color="info"
                      :loading="isTraining"
                      @click="runTrain"
                      prepend-icon="mdi-brain"
                      variant="tonal"
                      size="medium"
                    >
                      Päivitä Arvio
                    </v-btn>
                  </div>
                </template>

                <v-divider class="my-4"></v-divider>

                <div class="text-caption">Käyttäjä: {{ userData?.username }}</div>

                <!-- Add this button after the user info -->
                <v-btn
                  color="error"
                  variant="text"
                  size="small"
                  class="mt-2"
                  @click="handleLogout"
                  prepend-icon="mdi-logout"
                >
                  Kirjaudu ulos
                </v-btn>

                
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showHiddenMatches" max-width="800">
          <v-card>
            <v-card-title>Piilotetut ottelut</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item v-for="(matchData, matchId) in hiddenMatches" :key="matchId">
                  <template v-slot:prepend>
                    <v-btn icon @click="unhideMatch(matchId)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <v-list-item-title>
                    {{ matchData.home }} vs {{ matchData.away }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    ID: {{ matchId }} | Alkaa: {{ matchData.start_time }} | Piilotettu: {{ formatHiddenTime(matchData.hideUntil) }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="showHiddenMatches = false">Sulje</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showOddsHistory" :max-width="selectedOddsHistory.length >= 3 ? '600px' : '300px'">
          <v-card class="odds-history-modal" elevation="0">
            <v-card-title class="pa-4">
              <span class="text-h6">Kerroinhistoria</span>
            </v-card-title>

            <v-row class="pa-4">
              <v-col :cols="selectedOddsHistory.length >= 3 ? 6 : 12">
                <div class="odds-history-list">
                  <div v-for="(change, index) in selectedOddsHistory" 
                       :key="index"
                       class="odds-history-item"
                  >
                    <div class="odds-change">
                      <span :style="getOddsChangeStyle(change, 'old')">
                        {{ change.oldValue !== undefined ? parseFloat(change.oldValue).toFixed(3) : '-' }}
                      </span>
                      <span class="odds-arrow">→</span>
                      <span :style="getOddsChangeStyle(change, 'new')">
                        {{ change.newValue !== undefined ? parseFloat(change.newValue).toFixed(3) : '-' }}
                      </span>
                      <v-icon :color="getDirectionColor(change.direction)" size="small">
                        {{ getDirectionIcon(change.direction) }}
                      </v-icon>
                    </div>
                    <div class="odds-timestamp">
                      {{ getSecondsAgo(change.timestamp) }}
                    </div>
                  </div>
                </div>
              </v-col>
              
              <v-col v-if="selectedOddsHistory.length >= 3" cols="6">
                <OddsHistoryChart :oddsHistory="selectedOddsHistory" />
              </v-col>
            </v-row>

            <v-divider class="transparent-divider"></v-divider>
            
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn
                variant="text"
                color="primary"
                @click="showOddsHistory = false"
              >
                Sulje
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog 
          v-model="showOddsDetails" 
          max-width="400"
          :scrim="false"
        >
          <v-card v-if="selectedOdds" :key="selectedOdds.odds" class="odds-details-card" elevation="0">
            <!-- Header -->
            <div class="odds-header pa-3 backdrop-blur">
              <div class="d-flex justify-space-between">
                <div class="teams-container">
                  <div class="text-subtitle-1 font-weight-medium">{{ getMatchTeams(selectedOdds.matchId).home }}</div>
                  <div class="text-subtitle-1 font-weight-medium text-grey-darken-1">{{ getMatchTeams(selectedOdds.matchId).away }}</div>
                </div>
                <v-chip
                  size="x-small"
                  :color="selectedOdds.bookie === 'betfair' ? 'warning' : 'primary'"
                  class="ml-2"
                >
                  {{ bookieAbbreviations[selectedOdds.bookie] || selectedOdds.bookie }}
                </v-chip>
              </div>
            </div>

            <v-divider class="transparent-divider"></v-divider>

            <!-- Content -->
            <div class="odds-content pa-3 backdrop-blur">
              <div class="d-flex flex-column gap-3">
                <!-- Market & Outcome -->
                <div class="odds-info-row">
                  <div class="d-flex justify-space-between align-center">
                    <span class="text-caption text-grey">Kohde</span>
                    <div class="text-body-2">
                      {{ selectedOdds.market }} {{ selectedOdds.outcome }}
                      <span v-if="selectedOdds.handicap">({{ selectedOdds.handicap }})</span>
                    </div>
                  </div>
                </div>

                <!-- Odds -->
                <div class="odds-info-row">
                  <div class="d-flex justify-space-between align-center">
                    <span class="text-caption text-grey">Kerroin</span>
                    <div class="d-flex align-center">
                      <span class="text-body-1 font-weight-medium">{{ Number(selectedOdds.odds).toFixed(2) }}</span>
                      <span class="text-caption text-grey ml-2">(Raja: {{ Number(selectedOdds.fairOdds).toFixed(2) }})</span>
                    </div>
                  </div>
                </div>

                <!-- Value -->
                <div class="odds-info-row">
                  <div class="d-flex justify-space-between align-center">
                    <span class="text-caption text-grey">Value</span>
                    <span class="text-body-1 font-weight-large" :style="getValueGradientStyle(selectedOdds.value)">
                      {{ selectedOdds.value }}
                    </span>
                  </div>
                </div>

                <!-- Stake -->
                <div class="odds-info-row">
                  <div class="d-flex justify-space-between align-center">
                    <span class="text-caption text-grey">Panos</span>
                    <div class="d-flex align-center">
                      <span class="text-body-1 font-weight-medium">{{ selectedOdds.recommendedStake }}€</span>
                      <span v-if="selectedOdds.panosraja" class="text-caption text-grey ml-2">
                        (Raja: {{ selectedOdds.panosraja }}€)
                      </span>
                    </div>
                  </div>
                </div>

                <!-- Betfair specific info and betting controls -->
                <template v-if="selectedOdds.bookie === 'set_to_betfair_if_debug'">
                  <div class="odds-info-row">
                    <div class="d-flex justify-space-between align-center">
                      <span class="text-caption text-grey">Betfair ID</span>
                      <div class="text-caption">
                        {{ selectedOdds.betfairMarketId }}
                        <span class="text-grey">({{ selectedOdds.betfairRunnerId }})</span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- && betfairLoginStatus -->
            <v-divider class="transparent-divider"></v-divider>

            <div v-if="selectedOdds.bookie === 'betfair' && betfairApiEnabled " 
                class="betfair-betting-section">
              <div class="betting-controls">
                <div class="bet-type-selector">
                  <button :class="{ active: betfairOrderType === 'BACK' }"
                          @click="betfairOrderType = 'BACK'"
                          style="padding: 4px 8px; font-size: 0.875rem;">
                    Back
                  </button>
                  <button :class="{ active: betfairOrderType === 'LAY' }"
                          @click="betfairOrderType = 'LAY'"
                          style="padding: 4px 8px; font-size: 0.875rem;">
                    Lay
                  </button>
                </div>
                
                <div class="bet-inputs" style="gap: 8px;">
                  <div class="input-group" style="font-size: 0.875rem;">
                    <label style="font-size: 0.75rem;">Nettokerroin:</label>
                    <input type="number" 
                          v-model="betfairOdds" 
                          step="0.01" 
                          min="1.01" 
                          max="1000"
                          style="height: 28px; padding: 2px 6px; font-size: 0.875rem;">
                    <div v-if="oddsError" class="error-text text-error text-caption">
                      {{ oddsError }}
                    </div>
                  </div>
                  
                  <div class="input-group" style="font-size: 0.875rem;">
                    <label style="font-size: 0.75rem;">Panos (max 200€):</label>
                    <input type="number" 
                          v-model="betfairStake" 
                          step="1" 
                          min="2"
                          max="200"
                          @input="validateStake"
                          style="height: 28px; padding: 2px 6px; font-size: 0.875rem;">
                    <div v-if="stakeWarning" class="warning-text text-warning text-caption">
                      {{ stakeWarning }}
                    </div>
                  </div>
                </div>
                
                <button class="place-bet-button"
                        :disabled="placingBetfairOrder || !betfairOdds || !betfairStake || oddsError || Number(betfairStake) <= 0"
                        @click="placeBetfairOrder"
                        style="padding: 6px 12px; font-size: 0.875rem;">
                  {{ placingBetfairOrder ? 'Lyödään vetoa...' : 'Lyö veto' }}
                </button>
              </div>
            </div>

            <v-card-actions v-if="selectedOdds?.bookie !== 'betfair'" class="pa-4">
              <v-btn
                variant="text"
                prepend-icon="mdi-content-copy"
                @click="copyToClipboard(getMatchTeams(selectedOdds.matchId).home)"
              >
                Koti
              </v-btn>
              <v-btn
                variant="text"
                prepend-icon="mdi-content-copy"
                @click="copyToClipboard(getMatchTeams(selectedOdds.matchId).away)"
              >
                Vieras
              </v-btn>
              
              <v-btn
                variant="text"
                prepend-icon="mdi-eye"
                @click="addToTracking(selectedOdds)"
                @click.stop="togglePlayedStatus(selectedOdds.matchId)" 
              >
                Pelattu
              </v-btn>
              
              <v-spacer></v-spacer>
              
              <v-btn
                color="primary"
                variant="text"
                @click="showOddsDetails = false"
              >
                Sulje
              </v-btn>
            </v-card-actions>

            <!-- Add new card actions for Betfair -->
            <v-card-actions v-else class="pa-4">
              <v-btn
                variant="text"
                prepend-icon="mdi-content-copy"
                @click="copyToClipboard(getMatchTeams(selectedOdds.matchId).home)"
              >
                Koti
              </v-btn>
              <v-btn
                variant="text"
                prepend-icon="mdi-content-copy"
                @click="copyToClipboard(getMatchTeams(selectedOdds.matchId).away)"
              >
                Vieras
              </v-btn>
              
              <v-btn
                variant="text"
                prepend-icon="mdi-eye"
                @click="addToTracking(selectedOdds)"
              >
                Pelattu
              </v-btn>
              
              <v-spacer></v-spacer>
              
              <v-btn
                color="primary"
                variant="text"
                @click="showOddsDetails = false"
              >
                Sulje
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showInfo" max-width="600">
          <v-card>
            <div class="d-flex align-center pa-4 position-relative">
              <v-card-title class="text-h5 pa-0">Tietoja</v-card-title>
              <v-btn
                icon
                @click="showInfo = false"
                class="position-absolute"
                style="right: 8px; top: 8px;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <v-divider></v-divider>

            <v-card-text class="pa-4" style="max-height: 900px; max-width: 900px; overflow-y: auto;">
              <div class="text-h6 mb-1">
                Versio {{ version }}
              </div>
              <div class="text-caption text-grey mb-4">
                Päivitetty: {{ lastUpdate }}
              </div>

              <v-expansion-panels class="mb-6">
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    <v-icon start>mdi-history</v-icon>
                    Versiohistoria
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-timeline density="compact" align="start">
                      <v-timeline-item
                        v-for="(update, index) in versionHistory"
                        :key="index"
                        :dot-color="index === 0 ? 'primary' : 'grey'"
                        size="small"
                      >
                        <div class="text-caption text-grey">{{ update.date }}</div>
                        <div class="font-weight-medium">Versio {{ update.version }}</div>
                        <div v-for="(change, i) in update.changes" :key="i">
                          {{ change }}
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>

              <div class="text-h6 mb-4">Ottelumäärät ja päivitystiedot</div>
              <v-list>
                <v-list-item v-for="bookie in bookieOrder.filter(b => b !== 'top' && b !== 'prediction')" :key="bookie">
                  <v-list-item-title>
                    {{ bookieAbbreviations[bookie] || bookie }}:
                    {{ bookieMatchCounts[bookie] || 0 }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Viimeisin kerroinmuutos: {{ formatLastUpdateTime(bookieLastUpdateTimes[bookie]) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="d-flex align-center">
                    Keskimääräinen viive: 
                    <v-icon 
                      :color="getLatencyColor(bookieAverageLatencies[bookie])" 
                      size="small"
                      class="mx-1"
                    >
                      mdi-circle
                    </v-icon>
                    {{ formatLatency(bookieAverageLatencies[bookie]) }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showCalculationSettings" max-width="800" class="calculation-settings">
          <v-card>
            <div class="d-flex align-center pa-4 position-relative">
              <v-card-title class="text-h5 pa-0">Kerroinrajasäädöt</v-card-title>
              <v-btn
                icon
                @click="showCalculationSettings = false"
                class="position-absolute"
                style="right: 8px; top: 8px;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <v-divider></v-divider>

            <v-card-text>
              <!-- Panosrajoitukset -->
              <div class="settings-section">
                <div class="text-h6">Panosrajoitukset</div>
                <div class="text-subtitle-2">Säätä kerrointa panosrajan mukaan. Pienemmät panosrajat nostavat kerroinrajaa.</div>
                
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.number="calculationParams.stakeLimit.min"
                      label="Minimi panosraja"
                      type="number"
                      hint="Panosraja, jolla maksimi kerroinrajamuutos"
                      persistent-hint
                      density="compact"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.number="calculationParams.stakeLimit.max"
                      label="Maksimi panosraja"
                      type="number"
                      hint="Panosraja, jolla ei kerroinrajamuutosta"
                      persistent-hint
                      density="compact"
                    ></v-text-field>
                  </v-col>
                </v-row>
                
                <div class="slider-section">
                  <v-text-field
                    :model-value="Number(calculationParams.stakeLimit.multiplierMin).toFixed(3)"
                    @update:model-value="val => calculationParams.stakeLimit.multiplierMin = Number(val)"
                    type="number"
                    density="compact"
                    hide-details
                    :step="0.001"
                    :min="1"
                    :max="1.08"
                    style="width: 5px"
                  ></v-text-field>
                </div>
              </div>

              <div class="mb-6">
                <div class="text-h6 mb-2">Marginaaliasetukset</div>
                <div class="text-subtitle-2 mb-4">Säätää kerroinrajaa vedonvälittäjän marginaalin mukaan. Suurempi marginaali nostaa kerroinrajaa.</div>
                
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.number="calculationParams.margin.min"
                      label="Minimi marginaali (%)"
                      type="number"
                      hint="Marginaali, jolla ei kerroinrajamuutos"
                      persistent-hint
                      density="compact"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.number="calculationParams.margin.max"
                      label="Maksimi marginaali (%)"
                      type="number"
                      hint="Marginaali, jolla maksimi kerroinrajamuutos"
                      persistent-hint
                      density="compact"
                    ></v-text-field>
                  </v-col>
                </v-row>
                
                <div class="slider-section">
                  <v-text-field
                    :model-value="Number(calculationParams.margin.multiplierMax).toFixed(3)"
                    @update:model-value="val => calculationParams.margin.multiplierMax = Number(val)"
                    type="number"
                    density="compact"
                    hide-details
                    :step="0.001"
                    :min="1"
                    :max="1.04"
                    style="width: 5px"
                  ></v-text-field>
                </div>
              </div>

              <div class="mb-6">
                <div class="text-h6 mb-2">Oma arvio</div>
                <div class="text-subtitle-2 mb-4">Säätää kerroinrajaa arvion ja lasketun kertoimen eron mukaan.</div>
                
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.number="calculationParams.prediction.maxAdjustment"
                      label="Maksimi muutos (%)"
                      type="number"
                      hint="Suurin sallittu kerroinrajamuutos"
                      persistent-hint
                      density="compact"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model.number="calculationParams.prediction.maxDifference"
                      label="Maksimi ero (%)"
                      type="number"
                      hint="Ero, jolla maksimi muutos"
                      persistent-hint
                      density="compact"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div class="mb-6">
                <div class="text-h6 mb-2">Kerroinliikeasetukset</div>
                <div class="text-subtitle-2 mb-4">Säätää kerroinrajaa viimeaikaisen kerroinliikkeen mukaan.</div>
                
                <v-text-field
                  v-model.number="calculationParams.movement.timeWindow"
                  label="Aikaikkuna (s)"
                  type="number"
                  hint="Kuinka pitkältä ajalta kerroinliikettä seurataan"
                  persistent-hint
                  density="compact"
                ></v-text-field>
                
                <v-row>
                  <v-col cols="12" sm="6">
                    <div class="slider-section">
                      <v-text-field
                        :model-value="Number(calculationParams.movement.maxDownMultiplier).toFixed(3)"
                        @update:model-value="val => calculationParams.movement.maxDownMultiplier = Number(val)"
                        type="number"
                        label="Positiivinen kerroinliike (kerroin laskenut)"
                        density="compact"
                        hide-details
                        :step="0.001"
                        :min="0.98"
                        :max="1"
                        style="width: 5px"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <div class="slider-section">
                      <v-text-field
                        :model-value="Number(calculationParams.movement.maxUpMultiplier).toFixed(3)"
                        @update:model-value="val => calculationParams.movement.maxUpMultiplier = Number(val)"
                        type="number"
                        label="Negatiivinen kerroinliike (kerroin noussut)"
                        density="compact"
                        hide-details
                        :step="0.001"
                        :min="1"
                        :max="1.02"
                        style="width: 5px"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4">
              <v-btn
                color="error"
                variant="text"
                @click="resetCalculationParams"
              >
                Palauta oletukset
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                variant="text"
                @click="showCalculationSettings = false"
              >
                Sulje
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showPredictionEditor" max-width="900px">
          <v-card>
            <v-card-title class="text-h5 pa-4">
              Muokkaa arviota
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height: 600px; overflow-y: auto;">
              <v-row>
                <!-- Left side: Adjustments -->
                <v-col cols="12" md="6">
                  <!-- Display predictions as text -->
                  <div class="mb-1">
                    <div class="d-flex justify-space-between align-center">
                      <div class="text-subtitle-1">
                        Koti maalimäärä: {{ calculateAdjustedHomePred().toFixed(3) }}
                        <span class="text-caption text-grey-darken-1">({{ predictionData.home_pred?.toFixed(3) }})</span>
                      </div>
                      <div class="text-subtitle-1">
                        Vieras maalimäärä: {{ calculateAdjustedAwayPred().toFixed(3) }}
                        <span class="text-caption text-grey-darken-1">({{ predictionData.away_pred?.toFixed(3) }})</span>
                      </div>
                    </div>
                  </div>

                  <!-- Adjustment inputs -->
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="text-subtitle-2 mb-1">Koti korjausprosentti</div>
                      <v-text-field
                        v-model.number="predictionData.home_adjustment"
                        type="number"
                        step="0.01"
                        variant="outlined"
                        density="compact"
                        bg-color="transparent"
                        hide-details
                        class="prediction-input"
                        :rules="[v => !v || (v >= -0.2 && v <= 0.2) || 'Maksimi muutos on ±0.2']"
                        @input="validateAdjustment('home_adjustment')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="text-subtitle-2 mb-1">Vieras korjausprosentti</div>
                      <v-text-field
                        v-model.number="predictionData.away_adjustment"
                        type="number"
                        step="0.01"
                        variant="outlined"
                        density="compact"
                        bg-color="transparent"
                        hide-details
                        class="prediction-input"
                        :rules="[v => !v || (v >= -0.2 && v <= 0.2) || 'Maksimi muutos on ±0.2']"
                        @input="validateAdjustment('away_adjustment')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <div class="text-subtitle-2 mb-1">Rho (Tasapelikorjaus)</div>
                  <v-text-field
                    v-model.number="predictionData.rho"
                    type="number"
                    step="0.01"
                    variant="outlined"
                    density="compact"
                    bg-color="transparent"
                    hide-details
                    class="prediction-input mb-4"
                  ></v-text-field>

                  <v-textarea
                    v-model="predictionData.reason"
                    rows="5"
                    auto-grow
                    density="comfortable"
                    variant="outlined"
                    class="reason-textarea"
                    :rules="hasAdjustments ? [v => !!v || 'Syy on pakollinen'] : []"
                    :color="darkMode ? 'white' : 'black'"
                    label="Syy muutokselle*"
                    :label-required="hasAdjustments"
                  ></v-textarea>
                </v-col>

                <!-- Right side: Stats -->
                <v-col cols="12" md="6">
                  <div class="stats-container dark h-100">
                    <h3 class="stats-title">Tilastoja</h3>
                    <div class="stats-scroll">
                      <div v-for="(category, categoryName) in formattedStats" 
                           :key="categoryName" 
                           class="stats-category">
                        <h4>{{ categoryName }}</h4>
                        <div class="stats-values">
                          <div v-for="(values, label) in category" 
                               :key="label" 
                               class="stat-item">
                            <span class="stat-label">{{ label }}:</span>
                            <div class="stat-values-container">
                              <span class="stat-value">Koti: {{ values.koti }}</span>
                              <span class="stat-value">Vieras: {{ values.vieras }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="pa-4">
              <!-- Action buttons -->
              <v-col cols="12" class="d-flex justify-end gap-2">
                <v-btn
                  color="error"
                  variant="text"
                  @click="showPredictionEditor = false"
                >
                  Peruuta
                </v-btn>
                <button 
                  :disabled="hasAdjustments && !predictionData.reason"
                  @click="savePredictionChanges"
                  :class="{ 'opacity-50 cursor-not-allowed': hasAdjustments && !predictionData.reason }"
                >
                  {{ savingPrediction ? 'Tallennetaan...' : 'Tallenna' }}
                </button>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showBetfairPanel" max-width="650">
          <v-card>
            <div class="d-flex align-center pa-4 position-relative">
              <v-card-title class="text-h5 pa-0">Betfair</v-card-title>
              <v-btn
                icon
                @click="showBetfairPanel = false"
                class="position-absolute"
                style="right: 8px; top: 8px;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>

            <v-divider></v-divider>

            <v-card-text class="pa-4">
              <v-list>
                <!-- Connection Status -->
                <v-list-item>
                  <template v-slot:prepend>
                    <v-icon :color="betfairLoginStatus ? 'success' : 'error'">
                      {{ betfairLoginStatus ? 'mdi-check-circle' : 'mdi-alert-circle' }}
                    </v-icon>
                  </template>
                  <v-list-item-title>
                    {{ betfairLoginStatus ? 'Yhdistetty' : 'Ei yhteyttä' }}
                  </v-list-item-title>
                </v-list-item>

                <!-- Balance -->
                <v-list-item v-if="betfairLoginStatus">
                  <template v-slot:prepend>
                    <v-icon color="primary">mdi-wallet</v-icon>
                  </template>
                  <v-list-item-title class="d-flex align-center">
                    Saldo: {{ betfairBalance ? `${betfairBalance} €` : 'Ladataan...' }}
                    <v-btn
                      icon="mdi-refresh"
                      size="x-small"
                      :loading="fetchingBalance"
                      @click="fetchBetfairBalance"
                      class="ml-2"
                    ></v-btn>
                  </v-list-item-title>
                </v-list-item>

                <!-- Exposure -->
                <v-list-item v-if="betfairLoginStatus">
                  <template v-slot:prepend>
                    <v-icon :color="betfairExposure > 0 ? 'warning' : 'success'">mdi-scale-balance</v-icon>
                  </template>
                  <v-list-item-title>
                    Avoinna: {{ betfairExposure ? `${betfairExposure} €` : '0.00 �����' }}
                  </v-list-item-title>
                </v-list-item>

                <!-- In your Betfair panel -->
                <v-list-item v-if="betfairLoginStatus && lastBetfairKeepAlive">
                  <template v-slot:prepend>
                    <v-icon color="info">mdi-clock-outline</v-icon>
                  </template>
                  <v-list-item-title>
                    Kirjautuminen tarkastettu: {{ new Date(lastBetfairKeepAlive).toLocaleString() }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>

              <!-- Quick Settings -->
              <v-divider class="my-4"></v-divider>
              
              <div class="text-subtitle-1 mb-2"></div>
              <v-btn
                block
                color="secondary"
                class="mb-2"
                @click="showSettings = true; showBetfairPanel = false"
              >
                <v-icon start>mdi-cog</v-icon>
                Avaa asetukset
              </v-btn>

              <!-- Add Current Orders section -->
              <v-card-text v-if="betfairLoginStatus" class="betfair-orders">
                <div class="text-h6 mb-4">Avoimet vedot</div>
                
                <div class="orders-container">
                  <template v-if="betfairCurrentOrders.length > 0">
                    <div v-for="(orders, marketId) in limitedGroupedOrders" :key="marketId" class="mb-4">
                      <!-- Market Header -->
                      <v-card
                        :color="darkMode ? 'grey-darken-3' : 'grey-lighten-4'"
                        class="mb-2"
                        elevation="0"
                        rounded="lg"
                      >
                      <v-card-text class="pa-3">
                          <div class="d-flex flex-column">
                            <div class="text-subtitle-1 font-weight-medium">
                              {{ orders[0].marketName }}
                            </div>
                            
                            <div class="d-flex justify-space-between align-center mt-1">
                              <div class="text-body-2">
                                {{ findMatchByBetfairMarketId(marketId)?.home }} vs {{ findMatchByBetfairMarketId(marketId)?.away }}
                                <span class="text-caption text-grey">({{ marketId }})</span>
                              </div>
                              <v-btn
                                v-if="orders.some(order => order.sizeRemaining > 0)"
                                color="error"
                                size="x-small"
                                variant="text"
                                density="compact"
                                prepend-icon="mdi-close-circle"
                                @click="cancelBetfairOrder(marketId)"
                                :loading="cancellingMarket === marketId"
                              >
                                Peruuta kaikki
                              </v-btn>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>

                      <!-- Orders List -->
                      <v-list
                        density="compact"
                        :bg-color="darkMode ? 'grey-darken-4' : 'grey-lighten-5'"
                        class="rounded-lg"
                      >
                        <v-list-item
                          v-for="order in orders"
                          :key="order.betId"
                          :class="{
                            'bg-warning-lighten-5': order.status === 'EXECUTABLE' && !darkMode,
                            'bg-warning-darken-4': order.status === 'EXECUTABLE' && darkMode
                          }"
                          class="mb-1 position-relative"
                        >
                          <!-- Move chip to absolute position -->
                          <div class="bet-type-indicator">
                            <v-chip
                              size="x-small"
                              :color="order.side === 'LAY' ? 'pink-lighten-3' : 'success'"
                              :class="order.side === 'LAY' ? 'lay-chip' : ''"
                              variant="flat"
                              class="font-weight-medium tiny-chip"
                            >
                              {{ order.side }}
                            </v-chip>
                          </div>

                          <v-list-item-title class="d-flex align-center justify-space-between pl-8">
                            <div>
                              <span class="font-weight-medium">{{ order.priceSize.price }}</span>
                              <span class="mx-1">@</span>
                              <span :class="order.sizeRemaining > 0 ? 'text-warning' : 'text-success'">
                                {{ order.sizeMatched }}€
                                <span v-if="order.sizeRemaining > 0" class="text-caption">
                                  ({{ order.sizeRemaining }}€ avoinna)
                                </span>
                              </span>
                            </div>
                            
                            <div class="d-flex align-center">
                              <v-chip
                                size="x-small"
                                :color="order.status === 'EXECUTABLE' ? 'warning' : 'success'"
                                variant="flat"
                                class="mr-2"
                              >
                                {{ order.status }}
                              </v-chip>
                              <v-btn
                                v-if="order.sizeRemaining > 0"
                                icon="mdi-close"
                                size="x-small"
                                color="error"
                                variant="text"
                                :loading="cancellingBet === order.betId"
                                @click="cancelBetfairOrder(marketId, order.betId)"
                              ></v-btn>
                            </div>
                          </v-list-item-title>

                          <v-list-item-subtitle class="text-caption mt-1">
                            <span>Kohde: {{ order.selectionId }}</span>
                            <span v-if="order.handicap" class="mx-2">|</span>
                            <span v-if="order.handicap">Tasoitus: {{ order.handicap }}</span>
                            <span class="mx-2">|</span>
                            <span>Pelattu: {{ formatBetfairDate(order.placedDate) }}</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </div>

                    <!-- Show More button -->
                    <div v-if="Object.keys(groupedOrders).length > displayLimit" class="text-center mt-4">
                      <v-btn
                        variant="text"
                        color="primary"
                        @click="displayLimit += 5"
                        size="small"
                      >
                        Näytä lisää ({{ Object.keys(groupedOrders).length - displayLimit }} jäljellä)
                      </v-btn>
                    </div>
                  </template>
                  
                  <!-- Existing alert and refresh button code -->
                </div>

                <v-btn
                  block
                  color="primary"
                  class="mt-4"
                  :loading="loadingOrders"
                  @click="fetchBetfairOrders"
                  variant="tonal"
                >
                  <v-icon start>mdi-refresh</v-icon>
                  Päivitä vedot
                </v-btn>
              </v-card-text>

            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-main>
    <div v-if="hasFatalConnectionError" class="connection-error-overlay">
      <div class="error-content">
        <v-icon size="64" color="error" class="mb-4">mdi-alert-circle</v-icon>
        <h2 class="text-h4 mb-4">Yhteys kerroinpalvelimeen on katkennut</h2>
        <v-btn
          color="primary"
          size="large"
          @click="reloadPage"
        >
          Lataa sivu uudelleen
        </v-btn>
      </div>
    </div>
    <!-- Add this new dialog after the other dialogs -->
    <v-dialog v-model="showTrackingModal" max-width="400">
      <v-card>
        <v-card-title class="text-h6 pa-4">
          Lisää seurantaan
        </v-card-title>

        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="trackingForm.stake"
                label="Panos"
                type="number"
                :rules="[v => !!v || 'Panos vaaditaan']"
                density="compact"
                variant="outlined"
                hide-details="auto"
                class="mb-4"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="trackingForm.odds"
                label="Kerroin"
                type="number"
                step="0.01"
                :rules="[v => !!v || 'Kerroin vaaditaan']"
                density="compact"
                variant="outlined"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            variant="text"
            @click="showTrackingModal = false"
          >
            Peruuta
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            @click="confirmTracking"
          >
            Lisää
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog
       v-model="showTracking"
       fullscreen
       hide-overlay
       transition="dialog-bottom-transition"
     >
       <v-card>
         <v-toolbar 
           dark 
           color="grey-darken-4" 
           density="compact"
           class="tracking-toolbar"
         >
           <v-btn icon dark @click="showTracking = false" density="compact">
             <v-icon size="small">mdi-close</v-icon>
           </v-btn>
           
           <!-- Show title only on desktop -->
           <v-toolbar-title class="d-none d-sm-flex">Seuranta</v-toolbar-title>
           
           <v-spacer></v-spacer>
           
           <!-- Wrap selects in a menu on mobile -->
           <template v-if="$vuetify.display.smAndDown">
            <v-select
              v-model="selectedStatus"
              :items="statusOptions"
              label="Tulos"
              density="compact"
              dense
              class="mr-2"
              style="max-width: 100px; max-height: 35px; font-size: 0.6rem; padding: 0px;"
              item-title="title"
              item-value="value"
              @update:model-value="refreshTrackingData"
              hide-details
            ></v-select>

            <v-select
              v-model="selectedSport"
              :items="sportOptions"
              label="Laji"
              density="compact"
              dense
              class="mr-2"
              style="max-width: 100px; max-height: 35px; font-size: 0.6rem; padding: 0px;"
              item-title="title"
              item-value="value"
              @update:model-value="refreshTrackingData"
              hide-details
            ></v-select>
           </template>

           <!-- Show selects normally on desktop -->
           <template v-else>
             <v-select
               v-model="selectedStatus"
               :items="statusOptions"
               label="Tulos"
               density="compact"
               class="mr-2"
               style="max-width: 150px;"
               item-title="title"
               item-value="value"
               @update:model-value="refreshTrackingData"
               hide-details
             ></v-select>

             <v-select
               v-model="selectedSport"
               :items="sportOptions"
               label="Laji"
               density="compact"
               class="mr-2"
               style="max-width: 150px;"
               item-title="title"
               item-value="value"
               @update:model-value="refreshTrackingData"
               hide-details
             ></v-select>
           </template>

           <v-btn icon @click="refreshTrackingData" density="compact" size="small">
             <v-icon size="small">mdi-refresh</v-icon>
           </v-btn>
         </v-toolbar>
         <TrackingPage 
           ref="trackingPage"
           :selected-status="selectedStatus"
           :selected-sport="selectedSport"
           @refresh-complete="handleRefreshComplete"
         />
       </v-card>
     </v-dialog>

    <v-dialog
      v-model="showTeamMappingsEditor"
      max-width="800px"
      persistent
    >
      <v-card style="height: 600px; display: flex; flex-direction: column;">
        <div class="d-flex align-center pa-4 position-relative">
          <v-card-title class="text-h5 pa-0">Joukkuenimet</v-card-title>
          <v-btn
            icon
            @click="showTeamMappingsEditor = false"
            class="position-absolute"
            style="right: 8px; top: 8px;"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-divider></v-divider>

        <v-card-text class="pt-4 flex-grow-1" style="overflow: hidden;">
          <div style="height: 100%;">
            <v-textarea
              v-model="teamMappings"
              label="Joukkuenimien yhdistäminen (yksi per rivi, muodossa: nimi1=nimi2)"
              hide-details
              class="team-mappings-textarea"
              style="height: 100%;"
              no-resize
              variant="outlined"
            ></v-textarea>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="savingMappings"
            @click="saveTeamMappings"
          >
            Tallenna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showLiveEvents"
      max-width="450"
      max-height="750"
    >
      <LiveEvents />
    </v-dialog>
  </v-app>
</template>
<script>
import { io } from "socket.io-client";
import { ref, shallowRef } from 'vue';
import { debounce, throttle } from 'lodash';
import { showOddsHistoryModal, calculateStorageSize, isStorageNearlyFull, 
  performCleanup, getOddsMovement, trackOddsMovement, decompressOddsHistory, encodeOddsHistoryKey } from './oddshistory.js';
import { loginToBetfair, testBetfairConnection, fetchBetfairBalance, handleBetfairApiChange, 
  startBetfairKeepAlive, stopBetfairKeepAlive, fetchBetfairOrders, cancelBetfairOrder,
  placeBetfairOrder, findMatchByBetfairMarketId, validateStake, validateBetfairOdds } from './betfair.js';
import { getOppositeHandicap, calculateFair2WayOdds, calculateFair3WayOdds, getSecondsAgo, 
  getOddsChangeStyle, sendTelegramNotification, getDirectionColor, getDirectionIcon, 
  parseDateTime, formatHiddenTime, initAudio, playNotificationSound, getBackgroundColor, 
  getTextColor, getColorBrightness, getTopOddsStyle, getContrastYIQ, focusSearch, 
  togglePlayedStatus, handleKeyPress, hideMatch, isMatchHidden, unhideMatch,
  hideSelectedMatch, updateBookieLatency, getLatencyColor, formatLatency, 
  formatLastUpdateTime, updateBookieMatchCounts, formatValue, calculateKellyStake, 
  copyToClipboard, getMatchTeams, showToastMessage, 
  getOddsValue, processOdds, validateAdjustment, formatValueDisplay, formatPayoutDisplay,
  handleSoundChange, formatBetfairDate, calculateValue } from './utils.js';
import '@mdi/font/css/materialdesignicons.css';
import { VTable } from 'vuetify/components';
import { memoize } from 'lodash';
import { useStorage } from '@vueuse/core';
import { VList, VListItem } from 'vuetify/components';
import OddsHistoryChart from './components/OddsHistoryChart.vue'
import { versionInfo } from './versionInfo';
import LoginForm from './components/LoginForm.vue';
import { settingsService } from './services/settingsService';
import TrackingPage from './components/TrackingPage.vue';
import LiveEvents from './components/LiveEvents.vue';

// Update the sendBetToTracker function
async function sendBetToTracker(bet) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error('No authentication token found');
    }

    // Get base URL based on hostname
    const hostname = window.location.hostname;
    const baseUrl = hostname === 'localhost' || hostname === '127.0.0.1'
      ? 'http://127.0.0.1:5360'
      : 'https://avai.fi';

    const response = await fetch(`${baseUrl}/tracker`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(bet)
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Server error response:', errorData);
      throw new Error(errorData.detail || 'Failed to send bet to tracker');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error sending bet to tracker:', error);
    throw error;
  }
}

// Add the getToken function from settingsService
function getToken() {
  const userData = localStorage.getItem('userData') || sessionStorage.getItem('userData');
  if (userData) {
    const parsed = JSON.parse(userData);
    return parsed.token;
  }
  return null;
}

export default {
  name: 'App',
  components: {
    VTable,
    VList,
    VListItem,
    OddsHistoryChart,
    LoginForm,
    TrackingPage,
    LiveEvents,
  },
  data() {
    return {
      saveOddsHistory: useStorage('saveOddsHistory', true),
      nestedMatches: shallowRef([]),
      valueCache: new Map(),
      payoutCache: new Map(),
      paginatedMatches: [],
      selectedMatchId: null,
      valueFilter: useStorage('valueFilter', 3.5),
      payoutFilter: useStorage('payoutFilter', 97),
      maxOddsFilter: useStorage('maxOddsFilter', 5),
      currentPage: 1,
      itemsPerPage: useStorage('itemsPerPage', 10),
      pageSizeOptions: [5, 10, 15, 20],
      isLoading: true,
      darkMode: localStorage.getItem('darkMode') !== 'false',
      showSettings: false,
      hideStartedMatches: true,
      searchTerm: "",
      debouncedSearchTerm: "",
      bookieAbbreviations: {
        pinnacle: "Pinnacle",
        veikkaus: "Veikkaus",
        betfair: "Betfair",
        nubet: "Nubet",
        top: "Korkein",
        prediction: "Arvio",
      },
      bookieColors: {
        veikkaus: {
          light: "rgba(111, 146, 176, 0.15)",
          dark: "rgba(1, 32, 59, 0.4)", // Increased opacity from 0.3 to 0.4
        },
        nubet: {
          light: "rgba(209, 236, 241, 0.15)",
          dark: "rgba(50, 79, 87, 0.4)", // Increased opacity from 0.3 to 0.4
        },
        pinnacle: {
          light: "rgba(226, 227, 229, 0.15)",
          dark: "rgba(31, 30, 30, 0.4)", // Increased opacity from 0.3 to 0.4
        },
        betfair: {
          light: "rgba(255, 243, 205, 0.15)",
          dark: "rgba(64, 66, 36, 0.4)", // Increased opacity from 0.3 to 0.4
        },
        prediction: {
          light: "rgba(175, 174, 174, 0.15)",
          dark: "rgba(49, 49, 49, 0.4)", // Increased opacity from 0.3 to 0.4
        },
      },
      pendingUpdates: [],
      updateTimeout: null,
      hasError: false,
      errorMessage: 'Virhe!',
      sortBy: 'value',
      sortDesc: true,
      sortFields: [
        { key: 'value', label: 'Value' },
        { key: 'payout', label: 'Palautus' },
        { key: 'start_time', label: 'Alkaa' },
        { key: 'last_updated', label: 'Päivitetty' },
      ],
      lastFilterChange: Date.now(),
      debouncedApplyFilters: null,
      debouncedSort: null,
      debouncedSearch: null,

      hideDurations: [
        { label: '2m', value: 2 * 60 * 1000 },
        { label: '15m', value: 15 * 60 * 1000 },
        { label: '2h', value: 2 * 60 * 60 * 1000 },
        { label: '24h', value: 24 * 60 * 60 * 1000 },
        { label: 'Poista', value: 1000 * 60 * 60 * 1000 },
      ],
      hiddenMatches: ref({}),
      showHiddenMatches: false,
      soundEnabled: useStorage('soundEnabled', true),
      audio: null,
      oddsHistory: useStorage('oddsHistory', {}, undefined, {
        serializer: {
          read: (v) => {
            try {
              const parsed = JSON.parse(v) || {};
              const decoded = {};
              for (const key in parsed) {
                decoded[key] = Uint8Array.from(atob(parsed[key]), c => c.charCodeAt(0));
              }
              return decoded;
            } catch (error) {
              console.error('Error parsing oddsHistory:', error);
              return {};
            }
          },
          write: (v) => {
            try {
              const serialized = {};
              for (const key in v) {
                serialized[key] = btoa(String.fromCharCode.apply(null, v[key]));
              }

              const jsonString = JSON.stringify(serialized);

              return jsonString;
            } catch (error) {
              console.error('Error serializing odds history:', error);
              return '{}';
            }
          },
        },
      }),
      showOddsHistory: false,
      selectedOddsHistory: [],
      showDetailedBestValue: useStorage('showDetailedBestValue', true),
      telegramEnabled: useStorage('telegramEnabled', false),
      telegramToken: ref(''),
      telegramChatId: ref(''),
      playedMatches: ref({}),
      matchesAboveThreshold: {},
      matchesBelowThreshold: {},
      bookieMatchCounts: {},
      showInfo: false,
      bookieLatencies: {},
      bookieLastUpdateTimes: {},
      bookieAverageLatencies: {},
      selectedOdds: null,
      showOddsDetails: false,
      lastDisconnectTime: null,
      reconnectionAttempts: 0,
      maxReconnectionAttempts: 10,
      bankroll: ref(10000),
      showToast: false,
      toastTimeout: 5000,
      initialDataLoaded: false,
      isConnected: false,
      toastMessage: '',
      toastType: 'error',
      manuallyenabledbookies: ref({
        veikkaus: true,
        nubet: true,
        pinnacle: true,
        betfair: true,
      }),
      enabledBookies: useStorage('enabledbookies', {
        veikkaus: true,
        nubet: true,
        pinnacle: true,
        betfair: true,
      }),
      oddsHistoryUpdateInterval: null,
      showTelegramToken: false,
      showCalculationSettings: false,
      calculationParams: ref({
        stakeLimit: {
          min: 50,
          max: 450,
          multiplierMin: 1.02,
          multiplierMax: 1.00
        },
        margin: {
          min: 5,
          max: 9,
          multiplierMin: 1,
          multiplierMax: 1.02
        },
        prediction: {
          maxAdjustment: 2,
          maxDifference: 9
        },
        movement: {
          timeWindow: 600,
          maxDownMultiplier: 0.995,
          maxUpMultiplier: 1.01
        }
      }),
      calculationParamsChanged: false,
      version: versionInfo.version,
      lastUpdate: versionInfo.lastUpdate,
      versionHistory: versionInfo.versionHistory,
      isAuthenticated: false,
      userData: null,
      showPredictionEditor: false,
      predictionData: {
        ID: null,
        home_pred: 0,
        away_pred: 0,
        home_adjustment: 0,
        away_adjustment: 0,
        rho: null,
        reason: null
      },
      savingPrediction: false,
      selectedSound: localStorage.getItem('selectedSound') || 'none',
      soundOptions: [
        { text: 'Ei ääntä', value: 'none' },
        { value: 'beep1', text: 'Beep 1' },
        { value: 'beep2', text: 'Beep 2' },
        { value: 'beep3', text: 'Beep 3' },
        { value: 'beep4', text: 'Beep 4' },
        { value: 'beep5', text: 'Beep 5' },
        { value: 'beep6', text: 'Beep 6' },
        { value: 'sound1', text: 'Sound 1' },
        { value: 'sound2', text: 'Sound 2' },
      ],
      isRecalculating: false,
      isRunningExcelToDb: false,
      isTraining: false,
      betfairApiEnabled: ref(false),
      betfairAppKey: ref(''),
      betfairSessionToken: ref(''),
      showBetfairToken: false,
      betfairLoginStatus: ref(false),
      betfairLoggingIn: false,
      betfairUsername: ref(''),
      betfairPassword: ref(''),
      testingConnection: false,
      fetchingBalance: false,
      betfairBalance: null,
      betfairExposure: null,
      showBetfairPanel: false,
      betfairKeepAliveInterval: null,
      lastBetfairKeepAlive: null,
      betfairOpenBets: [],
      betfairSettledBets: [],
      betfairCurrentOrders: [],
      loadingOrders: false,
      cancellingMarket: null,
      cancellingBet: null,
      betfairOrderType: 'BACK',
      betfairStake: null,
      betfairOdds: null,
      placingBetfairOrder: false,
      displayLimit: 5,
      oddsError: null,
      stakeWarning: null,
      invalidBookies: null,
      matchAnalysis: {},
      loadingAnalysis: false,
      oddsDetailsUpdateInterval: null,
      valueLastChanged: {},
      valueUpdateInterval: null,
      useAllBookies: true,
      hasFatalConnectionError: false,
      usePinnacleValues: ref(false),
      useBetfairValues: ref(false),
      stakeRounding: ref(1.0),
      notificationSettings: {
        valueUpSpeed: ref(1.0),
        valueDropSpeed: ref(2.0),
      },
      isLoadingSettings: false,
      trackingForm: {
        stake: '',
        odds: ''
      },
      trackingOdds: null,
      showTrackingModal: false,
      showTracking: false,
      selectedStatus: null,
      selectedSport: null,
      statusOptions: [
        { title: 'Kaikki', value: null },
        { title: 'Avoinna', value: 'pending' },
        { title: 'Käynnissä', value: 'live' },
        { title: 'Ratkennut', value: 'settled' }
      ],
      sportOptions: [
        { title: 'Kaikki', value: null },
        { title: 'Jalkapallo', value: 'Jalkapallo' },
        { title: 'Koripallo', value: 'Koripallo' },
        { title: 'Tennis', value: 'Tennis' }
      ],
      teamMappings: '',
      savingMappings: false,
      showTeamMappingsEditor: false,
      showScrollTop: false,
      showLiveEvents: false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredMatches.length / this.itemsPerPage);
    },
    filteredMatches() {
      const searchLower = (this.debouncedSearchTerm || "").toLowerCase();
      const now = new Date();
      const valueFilter = parseFloat(this.valueFilter);
      const payoutFilter = parseFloat(this.payoutFilter);
      const maxOddsFilter = parseFloat(this.maxOddsFilter);

      return this.nestedMatches.filter(match => {
        if (match.common_id === this.selectedMatchId) {
          return true;
        }

        if (this.isMatchHidden(match)) {
          return false;
        }

        if (searchLower.length >= 3) {
          const matchesLeague = match.league && match.league.toLowerCase().includes(searchLower);
          const matchesId = match.common_id.toString().includes(searchLower) ||
            Object.values(match.bookieIds || {}).some(id => 
              id && id.toString().toLowerCase().includes(searchLower)
            );
          
          return matchesId || 
            matchesLeague ||
            (match.home && match.home.toLowerCase().includes(searchLower)) ||
            (match.away && match.away.toLowerCase().includes(searchLower));
        }

        const bestValue = this.getBestValue(match).value;
        const numericBestValue = parseFloat(bestValue);

        if (valueFilter !== null && valueFilter !== undefined && (isNaN(numericBestValue) || numericBestValue < valueFilter)) {
          return false;
        }

        if (payoutFilter && this.getMaxPayout(match) < payoutFilter) {
          return false;
        }

        if (maxOddsFilter && valueFilter) {
          const maxOddsCheck = this.checkMaxOddsFilter(match);
          if (!maxOddsCheck.hasValidBet || maxOddsCheck.allBetsAboveMaxOdds) {
            return false;
          }
        }

        if (this.hideStartedMatches && this.parseDateTime(match.start_time) <= now) {
          return false;
        }

        return true;
      });
    },
    sortedMarkets() {
      const order = ['Voittaja', '1X2', 'Aasialainen tasoitus', 'Yli/Alle'];
      return (markets) => {
        const sortedMarkets = Object.keys(markets)
          .sort((a, b) => {
            const indexA = order.indexOf(a);
            const indexB = order.indexOf(b);
            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
          })
          .reduce((acc, key) => {
            acc[key] = this.sortOutcomes(key, markets[key]);
            return acc;
          }, {});
        return sortedMarkets;
      };
    },
    bookieOrder() {
      const order = ["veikkaus", "nubet", "pinnacle", "betfair", "top", "prediction"];
      return order.filter(bookie => bookie === "top" || bookie === "prediction" || this.enabledBookies[bookie]);
    },
    hasAdjustments() {
      return !!(this.predictionData.home_adjustment || this.predictionData.away_adjustment);
    },
    isValidForm() {
      if (this.hasAdjustments) {
        return !!this.predictionData.reason &&
          (!this.predictionData.home_adjustment || (this.predictionData.home_adjustment >= -0.2 && this.predictionData.home_adjustment <= 0.2)) &&
          (!this.predictionData.away_adjustment || (this.predictionData.away_adjustment >= -0.2 && this.predictionData.away_adjustment <= 0.2));
      }
      return true;
    },
    groupedOrders() {
      return this.betfairCurrentOrders.reduce((groups, order) => {
        if (!groups[order.marketId]) {
          groups[order.marketId] = [];
        }
        groups[order.marketId].push(order);
        return groups;
      }, {});
    },
    limitedGroupedOrders() {
      return Object.fromEntries(
        Object.entries(this.groupedOrders).slice(0, this.displayLimit)
      );
    },
    formattedStats() {
      if (!this.predictionData?.stats) return {};
      
      try {
        const stats = JSON.parse(this.predictionData.stats);
        
        return {
          '': {
            '32 ottelua xG maaliodottama': {
              koti: stats['MOAT_Home_32'] ? stats['MOAT_Home_32'].toFixed(2) : 'N/A',
              vieras: stats['MOAT_Away_32'] ? stats['MOAT_Away_32'].toFixed(2) : 'N/A'
            },
            '16 ottelua xG maaliodottama': {
              koti: stats['MOAT_Home_16'] ? stats['MOAT_Home_16'].toFixed(2) : 'N/A',
              vieras: stats['MOAT_Away_16'] ? stats['MOAT_Away_16'].toFixed(2) : 'N/A'
            },
            '10 ottelua xG Blend': {
              koti: stats['Blend: xG Predict: xG Home'] ? stats['Blend: xG Predict: xG Home'].toFixed(2) : 'N/A',
              vieras: stats['Blend: xG Predict: xG Away'] ? stats['Blend: xG Predict: xG Away'].toFixed(2) : 'N/A'
            },
            'Kausi xG Blend': {
              koti: stats['Season-To-Date Model Poisson Output: xGH'] ? stats['Season-To-Date Model Poisson Output: xGH'].toFixed(2) : 'N/A',
              vieras: stats['Season-To-Date Model Poisson Output: xGA'] ? stats['Season-To-Date Model Poisson Output: xGA'].toFixed(2) : 'N/A'
            },
            'Sijoitus': {
              koti: stats['League Pos: Home Pos'] !== undefined ? stats['League Pos: Home Pos'].toFixed(0) : 'N/A',
              vieras: stats['League Pos: Away Pos'] !== undefined ? stats['League Pos: Away Pos'].toFixed(0) : 'N/A'
            },
            'Ottelut': {
              koti: stats['Season Game: Home Team Game No.'] !== undefined ? stats['Season Game: Home Team Game No.'].toFixed(0) : 'N/A',
              vieras: stats['Season Game: Away Team Game No.'] !== undefined ? stats['Season Game: Away Team Game No.'].toFixed(0) : 'N/A'
            },
            'Elo sijoitus': {
              koti: stats['Overall Elo Rank: Home Overall Rank'] !== undefined ? stats['Overall Elo Rank: Home Overall Rank'].toFixed(0) : 'N/A',
              vieras: stats['Overall Elo Rank: Away Overall Rank'] !== undefined ? stats['Overall Elo Rank: Away Overall Rank'].toFixed(0) : 'N/A'
            },
            'Valmentajan ottelut': {
              koti: stats['Manager Games: Home Manager'] !== undefined ? stats['Manager Games: Home Manager'].toFixed(0) : 'N/A',
              vieras: stats['Manager Games: Away Manager'] !== undefined ? stats['Manager Games: Away Manager'].toFixed(0) : 'N/A'
            },
            'xG vimeiset 24 ottelua': {
              koti: stats['xG_Home_Last24'] !== undefined ? stats['xG_Home_Last24'].toFixed(2) : 'N/A',
              vieras: stats['xG_Away_Last24'] !== undefined ? stats['xG_Away_Last24'].toFixed(2) : 'N/A'
            },
            'xGa vimeiset 24 ottelua': {
              koti: stats['xGa_Home_Last24'] !== undefined ? stats['xGa_Home_Last24'].toFixed(2) : 'N/A',
              vieras: stats['xGa_Away_Last24'] !== undefined ? stats['xGa_Away_Last24'].toFixed(2) : 'N/A'
            }
          },
        };
      } catch (error) {
        console.error('Error parsing stats:', error);
        return {};
      }
    },
    filteredmanuallyenabledbookies() {
      const desiredBookies = ['pinnacle', 'veikkaus', 'betfair', 'nubet'];
      return Object.fromEntries(Object.entries(this.manuallyenabledbookies).filter(([bookie]) => desiredBookies.includes(bookie)));
    }
  },
  async created() {
    const userData = localStorage.getItem('userData') || sessionStorage.getItem('userData');
    if (userData) {
      try {
        this.userData = JSON.parse(userData);
        this.isAuthenticated = true;
        
        // Load settings before initializing
        await this.loadSettings();
        
        // Initialize after settings are loaded
        this.initializeData();
      } catch (error) {
        console.error('Error during initialization:', error);
        this.showToastMessage('Virhe asetusten lataamisessa', 'error');
      }
    }

    this.debouncedApplyFilters = debounce(this.applyFilters, 500);
    this.debouncedSort = debounce(this.performSort, 500);
    this.memoizedCalculateValueAndFairOdds = memoize(
      this.calculateValueAndFairOdds,
      (oddsData, outcomeName, marketData, timestamp, matchId) => {
        // Create a cache key that includes all odds in the market
        const allOddsString = Object.entries(marketData)
          .map(([outcome, data]) => {
            const pinnacleOdds = data.pinnacle || '';
            const betfairOdds = data.betfair || '';
            return `${outcome}:${pinnacleOdds}:${betfairOdds}`;
          })
          .join('|');
        
        return `${matchId}-${outcomeName}-${allOddsString}-${timestamp}`;
      }
    );
    this.debouncedUpdateBookieMatchCounts = debounce(this.updateBookieMatchCounts, 1000);
    this.debouncedCheckAndNotify = debounce(this.checkAndNotify, 500);
    this.throttledProcessUpdates = throttle(this.processUpdates, 100);

    // Chrome-specific optimizations
    if (window.chrome) {
      // Use Chrome's requestIdleCallback for non-critical updates
      this.debouncedUpdateBookieMatchCounts = (matches) => {
        window.requestIdleCallback(() => {
          this.updateBookieMatchCounts(matches);
        }, { timeout: 2000 });
      };

      // Use Chrome's Intersection Observer for lazy loading
      this.setupLazyLoading();
    }

    // Initialize bookieLastUpdateTimes with the current time for each bookie
    this.bookieOrder.forEach(bookie => {
      this.bookieLastUpdateTimes[bookie] = Date.now();
    });

    this.setupSettingsWatchers();

    // Add ResizeObserver error handler
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        e.stopPropagation();
        e.preventDefault();
      }
    });
  },
  watch: {
    currentPage() {
      this.updatePaginatedMatches();
    },
    filteredMatches: {
      handler() {
        this.updatePaginatedMatches();
      },
      immediate: true
    },
    itemsPerPage() {
      this.handlePageSizeChange();
    },
    saveOddsHistory(newValue) {
      if (!newValue) {
        this.oddsHistory = {};
        localStorage.removeItem('oddsHistory');
      }
    },
    searchTerm: {
      handler(newTerm) {
        this.debouncedSearchTerm = newTerm;
        this.currentPage = 1;
      },
      immediate: true,
    },
    valueFilter() {
      this.handleFilterChange();
    },
    payoutFilter() {
      this.handleFilterChange();
    },
    maxOddsFilter() {
      this.handleFilterChange();
    },
    hideStartedMatches() {
      this.handleFilterChange();
    },
    nestedMatches: {
      handler() {
        this.updateSelectedOdds();
      },
      deep: true
    },
    showOddsHistory(newValue) {
      if (!newValue && this.oddsHistoryUpdateInterval) {
        clearInterval(this.oddsHistoryUpdateInterval);
        this.oddsHistoryUpdateInterval = null;
      }
    },
    calculationParams: {
      deep: true,
    },
    showCalculationSettings(newValue) {
      if (!newValue && this.calculationParamsChanged) {
        this.showToastMessage('Muutokset tulevat voimaan sivun päivityksen jälkeen', 'info', 4000);
        this.calculationParamsChanged = false;
      }
    },
    showOddsDetails(newValue) {
      if (!newValue && this.oddsDetailsUpdateInterval) {
        clearInterval(this.oddsDetailsUpdateInterval);
        this.oddsDetailsUpdateInterval = null;
      } else if (newValue && !this.oddsDetailsUpdateInterval) {
        this.oddsDetailsUpdateInterval = setInterval(() => {
          this.updateSelectedOdds();
        }, 1000);
      }
    },
    showPredictionEditor(newValue) {
      if (!newValue) {
        this.predictionData = {};
      }
    },
    showSettings: {
      async handler(newValue) {
        if (newValue) {
          try {
            await this.loadSettings();
          } catch (error) {
            console.error('Error loading settings when opening modal:', error);
            this.showToastMessage('Virhe asetusten latauksessa', 'error');
          }
        }
      }
    },
    darkMode: {
      handler(newValue) {
        localStorage.setItem('darkMode', newValue);
      },
      immediate: true
    },
    showTracking(val) {
      if (val) {
        this.selectedStatus = null;
      }
    },
    showTeamMappingsEditor(newVal) {
      if (newVal) {
        this.loadTeamMappings();
      }
    }
  },
  async mounted() {
    this.initAudio();
    window.addEventListener('keydown', this.handleKeyPress);
    this.cleanupInterval = setInterval(() => {
      this.performCleanup();
    }, 60000); 
    this.$nextTick(() => {
      this.updatePaginatedMatches();
    });

    if (this.betfairApiEnabled && this.betfairSessionToken) {
      try {
        await this.testBetfairConnection();
        if (this.betfairLoginStatus) {
          this.startBetfairKeepAlive();
        }
      } catch (error) {
        console.error('Failed to restore Betfair session:', error);
        this.betfairLoginStatus = false;
        this.betfairSessionToken = '';
      }
    }

    this.valueUpdateInterval = setInterval(() => {
      this.$forceUpdate();
    }, 1000);

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
    clearInterval(this.cleanupInterval);
    if (this.socket) {
      this.socket.disconnect();
    }
    if (this.oddsHistoryUpdateInterval) {
      clearInterval(this.oddsHistoryUpdateInterval);
    }
    this.stopBetfairKeepAlive();
    if (this.oddsDetailsUpdateInterval) {
      clearInterval(this.oddsDetailsUpdateInterval);
    }
    if (this.valueUpdateInterval) {
      clearInterval(this.valueUpdateInterval);
    }
    if (this.betfairKeepAliveInterval) {
      clearInterval(this.betfairKeepAliveInterval);
    }

    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    initAudio,
    togglePlayedStatus,
    hideMatch,
    showOddsHistoryModal,
    hideSelectedMatch,
    trackOddsMovement,
    updateBookieMatchCounts,
    showToastMessage,
    formatValue,
    calculateKellyStake,
    copyToClipboard,
    getMatchTeams,
    performCleanup,
    getOddsMovement,
    handleKeyPress,
    getTopOddsStyle,
    getContrastYIQ,
    getSecondsAgo,
    getOddsChangeStyle,
    sendTelegramNotification,
    getDirectionColor,
    getDirectionIcon,
    parseDateTime,
    formatHiddenTime,
    playNotificationSound,
    getBackgroundColor,
    getTextColor,
    getColorBrightness,
    focusSearch,
    isMatchHidden,
    unhideMatch,
    calculateStorageSize,
    isStorageNearlyFull,
    updateBookieLatency,
    getLatencyColor,
    formatLatency,
    formatLastUpdateTime,
    getOddsValue,
    processOdds,
    validateAdjustment,
    formatValueDisplay,
    formatPayoutDisplay,
    handleSoundChange,
    formatBetfairDate,
    loginToBetfair,
    testBetfairConnection,
    fetchBetfairBalance,
    handleBetfairApiChange,
    startBetfairKeepAlive,
    stopBetfairKeepAlive,
    fetchBetfairOrders,
    cancelBetfairOrder,
    placeBetfairOrder,
    findMatchByBetfairMarketId,
    validateStake,
    validateBetfairOdds,
    calculateValue,
    async loadSettings() {
      try {
        const settings = await settingsService.getAllSettings();
        
        // Temporarily disable watchers
        this.isLoadingSettings = true;
        
        // Parse and apply each setting
        Object.entries(settings).forEach(([key, value]) => {
          try {
            const parsedValue = JSON.parse(value);
            
            // Handle ref values
            if (this[key] && this[key].value !== undefined) {
              // For ref values, we need to update the .value property
              this[key].value = parsedValue;
            } else if (key === 'enabledbookies') {
              Object.entries(parsedValue).forEach(([bookie, enabled]) => {
                this.enabledbookies[bookie] = enabled;
              });
            } else if (key === 'manuallyenabledbookies') {
              Object.entries(parsedValue).forEach(([bookie, enabled]) => {
                this.manuallyenabledbookies[bookie] = enabled;
              });
            } else if (key === 'notificationSettings') {
              Object.entries(parsedValue).forEach(([settingKey, settingValue]) => {
                if (this.notificationSettings[settingKey]) {
                  this.notificationSettings[settingKey].value = settingValue;
                }
              });
            } else {
              // For non-ref values
              this[key] = parsedValue;
            }

            // Sync with localStorage if needed
            if (key === 'playedMatches' || key === 'hiddenMatches') {
              localStorage.setItem(key, JSON.stringify(parsedValue));
            }
          } catch (e) {
            console.error(`Error parsing setting ${key}:`, e);
          }
        });

        // Re-enable watchers after a short delay
        setTimeout(() => {
          this.isLoadingSettings = false;
        }, 100);

      } catch (error) {
        console.error('Error loading settings:', error);
        this.showToastMessage('Virhe asetusten latauksessa', 'error');
      }
    },

    async saveSetting(key, value) {
      try {
        await settingsService.saveSetting(key, value);
      } catch (error) {
        console.error(`Error saving setting ${key}:`, error);
        this.showToastMessage('Virhe asetuksen tallennuksessa', 'error');
      }
    },

    // Add watchers for all settings you want to save
    setupSettingsWatchers() {
      const settingsToWatch = [
        'telegramToken',
        'telegramChatId', 
        'bankroll',
        'enabledBookies',
        'manuallyenabledbookies',
        'betfairApiEnabled',
        'betfairAppKey',
        'betfairSessionToken',
        'betfairUsername',
        'betfairPassword',
        'usePinnacleValues',
        'useBetfairValues',
        'notificationSettings.valueUpSpeed',
        'notificationSettings.valueDropSpeed',
        'stakeRounding',
        'hiddenMatches',
        'playedMatches',
        'calculationParams'
      ];

      settingsToWatch.forEach(setting => {
        this.$watch(setting, (newValue) => {
          // Only save if not currently loading settings
          if (!this.isLoadingSettings) {
            this.saveSetting(setting, newValue);
          }
        }, { deep: true });
      });
    },
    initializeSocketConnection() {
      if (!this.isAuthenticated) {
        console.warn('Attempted to initialize socket connection without authentication');
        return;
      }

      const token = this.userData.token;

      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      const host = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
        ? '127.0.0.1:5364'
        : 'avai.fi';
        
      this.socket = io(`${protocol}://${host}`, {
        transports: ['websocket', 'polling'],
        query: { token }, 
        cors: {
          origin: '*',
        },
        reconnectionAttempts: this.maxReconnectionAttempts,
        reconnectionDelay: 6000,
        reconnectionDelayMax: 8000,
      });

      this.socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
        if (error.message.includes('Authentication failed')) {
          this.handleAuthError();
        }
      });

      this.socket.on('disconnect', (reason) => {
        if (reason === 'io server disconnect') {
          this.handleAuthError();
        }
      });

      this.socket.on('session_expired', (data) => {
        this.showToastMessage(data.message, 'warning', 5000);
        this.handleSessionExpired();
      });

      this.socket.on("connect", async () => {
        console.log("Connected to the server");
        this.showToastMessage('Yhdistetty!', 'success', 4000);
        this.isConnected = true;
        this.reconnectionAttempts = 0;

        try {
          // Load latest settings after connection
          await this.loadSettings();

          if (this.initialDataLoaded) {
            console.log("Reconnected after disconnect, reloading data...");
            this.reloadInitialData();
          } else if (!this.initialDataLoaded) {
            this.showToastMessage('Ladataan ottelutietoja...', 'info', 5000);
            this.fetchInitialData();
          }
        } catch (error) {
          console.error('Error loading settings after connection:', error);
          this.showToastMessage('Virhe asetusten latauksessa', 'error');
        }
      });

      this.socket.on("initial_data", (matches) => {
        try {
          if (!this.initialDataLoaded) {
            if (Array.isArray(matches)) {
              this.nestedMatches = this.processNestedData(matches);
              
              const now = Date.now();
              this.nestedMatches.forEach(match => {
                const bestValue = this.getBestValue(match);
                const numericBestValue = parseFloat(bestValue.value);
                
                if (!isNaN(numericBestValue) && numericBestValue >= this.valueFilter) {
                  this.matchesAboveThreshold[match.common_id] = true;
                  this.valueLastChanged[match.common_id] = now;
                }
              });

              this.updatePaginatedMatches();
              this.updateBookieMatchCounts();
              this.initialDataLoaded = true;
              console.log("Initial data received");
              this.showToastMessage('Ottelutiedot ladattu onnistuneesti', 'success', 4000);
            } else {
              throw new Error("Initial data is not an array");
            }
          }
          this.isLoading = false;
          this.hasError = false;
        } catch (error) {
          console.error('Error processing initial data:', error);
          this.showToastMessage(`Ottelutietojen lataaminen epäonnistui: ${error}`, 'warning', 60000);
          setTimeout(() => this.reloadInitialData(), 5000);
        }
      });

      this.socket.on('update_matches', (update, ackCallback) => {
        // Process the update
        this.queueUpdate(update);

        // After processing, send acknowledgment
        ackCallback({ status: 'ok' });
      });


      this.socket.on("connect_error", (error) => {
        console.log("Connect error occurred:", error);
        this.handleConnectionError();
      });
      
      this.socket.on("error", (error) => {
        console.log("Socket error occurred:", error);
        this.handleConnectionError();
      });

      this.socket.on("disconnect", (reason) => {
        console.log("Disconnected from server:", reason);
        this.handleConnectionError();
      });
    },
    handleConnectionError() {
      this.isConnected = false;
      this.reconnectionAttempts++;
      
      const currentTime = Date.now();
      const reconnectionWindow = 60000;

      if (this.lastDisconnectTime && (currentTime - this.lastDisconnectTime) <= reconnectionWindow) {
        this.reconnectionAttempts++;
      } else if (this.reconnectionAttempts >= this.maxReconnectionAttempts) {
        this.hasFatalConnectionError = true;
      } else {
        this.showToastMessage(`Ei yhteyttä. Yhdistetään uudelleen... (${this.reconnectionAttempts}/${this.maxReconnectionAttempts})`, 'warning', 6000);
      }
    },
    processNestedData(matches) {
      if (!Array.isArray(matches)) {
        console.error("processNestedData: matches is not an array", matches);
        return [];
      }

      const matchMap = new Map();

      matches.forEach((match) => {
        const commonId = match.common_id;
        if (!matchMap.has(commonId)) {
          matchMap.set(commonId, {
            common_id: commonId,
            bookieIds: {},
            home: match.Koti || "N/A",
            away: match.Vieras || "N/A",
            start_time: match.Alkaa || "N/A",
            sport: match.Laji || "N/A",
            league: match.Sarja || "N/A",
            odds: {},
            timestamps: {},
          });
        }

        const existingMatch = matchMap.get(commonId);
        const bookie = match.Tarjoaja || "N/A";
        existingMatch.timestamps[bookie] = match.Aikaleima;

        existingMatch.bookieIds[bookie] = match.ID;

        if (!this.enabledBookies[bookie] && bookie !== "prediction") {
          return;
        }

        if (!this.manuallyenabledbookies[bookie] && bookie !== "prediction") {
          return;
        } 

        const odds = this.processOdds(JSON.parse(match.Kertoimet), bookie);
        this.mergeOdds(existingMatch, odds, bookie, match.Aikaleima, true);
      });

      // Filter out matches with less than 2 bookies
      return Array.from(matchMap.values()).filter(match => {
        const activeBookies = new Set(
          Object.keys(match.bookieIds).filter(bookie => 
            this.manuallyenabledbookies[bookie] && this.manuallyenabledbookies[bookie] && bookie !== "prediction"
          )
        );
        return activeBookies.size >= 2;
      });
    },
    applyFilters() {
      this.lastFilterChange = Date.now();
      this.$nextTick(() => {
        this.updatePaginatedMatches();
      });
    },
    toggleMatchDetails(matchId) {
      if (this.selectedMatchId === matchId) {
        this.selectedMatchId = null;
        this.matchesBelowThreshold = {};
      } else {
        this.selectedMatchId = matchId;
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    queueUpdate(update) {
      this.pendingUpdates.push(update);

      if (!this.updateTimeout) {
        this.updateTimeout = setTimeout(() => {
          this.processUpdates();
        }, 500);
      }
    },
    processUpdates() {
      // Increase batch size and use requestAnimationFrame
      const BATCH_SIZE = 250;
      const updates = new Map();
      
      // Deduplicate updates by keeping only the latest update for each match
      this.pendingUpdates.forEach(update => {
        updates.set(update.common_id, update);
      });
      
      const processChunk = (entries, index = 0) => {
        const chunk = Array.from(entries).slice(index, index + BATCH_SIZE);
        
        if (chunk.length === 0) {
          this.pendingUpdates = [];
          this.updateTimeout = null;
          return;
        }
        
        requestAnimationFrame(() => {
          chunk.forEach(([, update]) => this.handleUpdate(update)); 
          processChunk(entries, index + BATCH_SIZE);
        });
      };
      
      processChunk(updates.entries());
    },
    recalculateAffectedMatches(updatedMatchIds) {
      const updates = [];
      
      updatedMatchIds.forEach(matchId => {
        const match = this.nestedMatches.find(m => m.common_id === matchId);
        if (match) {
          updates.push(() => {
            this.getBestAndMaxValue(match);
            this.getMaxPayout(match);
          });
        }
      });

      const processCalculations = (startIndex) => {
        requestAnimationFrame(() => {
          const chunk = updates.slice(startIndex, startIndex + 50);
          chunk.forEach(update => update());

          if (startIndex + 50 < updates.length) {
            processCalculations(startIndex + 50);
          } else {
            this.nestedMatches = this.sortMatches(this.nestedMatches);
            this.$nextTick(() => {
              this.applyFilters();
              this.updatePaginatedMatches();
            });
          }
        });
      };

      processCalculations(0);
    },
    handleUpdate(data) {
      let existingMatchIndex = this.nestedMatches.findIndex(match => match.common_id === data.common_id);

      if (existingMatchIndex === -1) {
        existingMatchIndex = this.nestedMatches.findIndex(match => match.ID === data.ID);
      }

      if (existingMatchIndex === -1) {
        this.handleAdd(data);
      } else {
        const existingMatch = this.nestedMatches[existingMatchIndex];
        const bookie = data.Tarjoaja || "N/A";

        this.updateBookieLatency(bookie, data.Aikaleima * 1000);

        // Remove odds for disabled bookies
        if (!this.manuallyenabledbookies[bookie] && bookie !== "prediction") {
          // Remove all odds for this bookie
          for (const marketName in existingMatch.odds) {
            for (const outcome in existingMatch.odds[marketName]) {
              if (marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle') {
                for (const handicap in existingMatch.odds[marketName][outcome]) {
                  delete existingMatch.odds[marketName][outcome][handicap][bookie];
                  delete existingMatch.odds[marketName][outcome][handicap][`${bookie}_panosraja`];
                  if (bookie === 'betfair') {
                    delete existingMatch.odds[marketName][outcome][handicap].betfair_market_id;
                    delete existingMatch.odds[marketName][outcome][handicap].betfair_runner_id;
                    delete existingMatch.odds[marketName][outcome][handicap].betfair_handicap;
                  }
                  // Clear memoized calculations for this outcome
                  if (this.memoizedCalculateValueAndFairOdds && this.memoizedCalculateValueAndFairOdds.cache) {
                    this.memoizedCalculateValueAndFairOdds.cache.delete(
                      JSON.stringify([
                        existingMatch.odds[marketName][outcome],
                        outcome,
                        existingMatch.odds[marketName],
                        Date.now(),
                        existingMatch.common_id
                      ])
                    );
                  }
                  // Recalculate for top odds if they exist
                  const topOddsInfo = this.getOddsValue(existingMatch.odds[marketName][outcome], 'top');
                  if (topOddsInfo.value !== '-') {
                    const result = this.calculateValueAndFairOdds(
                      existingMatch.odds[marketName][outcome],
                      outcome,
                      existingMatch.odds[marketName],
                      Date.now(),
                      existingMatch.common_id
                    );
                    
                    // Update value cache
                    const cacheKey = `${existingMatch.common_id}-${marketName}-${existingMatch.lastUpdateTime}`;
                    this.valueCache.set(cacheKey, result);
                  }
                  // Clean up empty objects
                  if (Object.keys(existingMatch.odds[marketName][outcome][handicap]).length === 0) {
                    delete existingMatch.odds[marketName][outcome][handicap];
                  }
                }
              } else {
                delete existingMatch.odds[marketName][outcome][bookie];
                delete existingMatch.odds[marketName][outcome][`${bookie}_panosraja`];
                if (bookie === 'betfair') {
                  delete existingMatch.odds[marketName][outcome].betfair_market_id;
                  delete existingMatch.odds[marketName][outcome].betfair_runner_id;
                  delete existingMatch.odds[marketName][outcome].betfair_handicap;
                }
                // Clear memoized calculations for this outcome
                if (this.memoizedCalculateValueAndFairOdds && this.memoizedCalculateValueAndFairOdds.cache) {
                  this.memoizedCalculateValueAndFairOdds.cache.delete(
                    JSON.stringify([
                      existingMatch.odds[marketName][outcome],
                      outcome,
                      existingMatch.odds[marketName],
                      Date.now(),
                      existingMatch.common_id
                    ])
                  );
                }
                // Recalculate for top odds if they exist
                const topOddsInfo = this.getOddsValue(existingMatch.odds[marketName][outcome], 'top');
                if (topOddsInfo.value !== '-') {
                  const result = this.calculateValueAndFairOdds(
                    existingMatch.odds[marketName][outcome],
                    outcome,
                    existingMatch.odds[marketName],
                    Date.now(),
                    existingMatch.common_id
                  );
                  
                  // Update value cache
                  const cacheKey = `${existingMatch.common_id}-${marketName}-${existingMatch.lastUpdateTime}`;
                  this.valueCache.set(cacheKey, result);
                }
              }
              // Clean up empty objects
              if (Object.keys(existingMatch.odds[marketName][outcome]).length === 0) {
                delete existingMatch.odds[marketName][outcome];
              }
            }
            // Clean up empty markets
            if (Object.keys(existingMatch.odds[marketName]).length === 0) {
              delete existingMatch.odds[marketName];
            }
          }
          
          // Force recalculation by clearing caches and updating the match
          this.valueCache.clear();
          this.payoutCache.clear();
          this.nestedMatches[existingMatchIndex] = { ...existingMatch };
          
          this.$nextTick(() => {
            this.updatePaginatedMatches();
            this.updateBookieMatchCounts();
            this.updateSelectedOdds();
          });
          
          return;
        }

        const newStartTime = data.Alkaa;
        if (newStartTime && existingMatch.start_time !== newStartTime) {
          existingMatch.start_time = newStartTime;
        }

        let updatedOdds;
        try {
          updatedOdds = this.processOdds(JSON.parse(data.Kertoimet), bookie);
        } catch (error) {
          console.error('Error parsing odds:', error, data);
          return;
        }
        const changedOdds = this.diffOdds(existingMatch.odds, updatedOdds, bookie);
        
        if (Object.keys(changedOdds).length > 0) {
          if (existingMatch.common_id !== data.common_id) {
            delete this.matchesAboveThreshold[existingMatch.common_id];
            delete this.matchesBelowThreshold[existingMatch.common_id];
            existingMatch.common_id = data.common_id;
          }
          this.mergeOdds(existingMatch, changedOdds, bookie, data.Aikaleima, false);
          existingMatch.timestamps = { ...existingMatch.timestamps, [bookie]: data.Aikaleima * 1000 };
          existingMatch.lastUpdateTime = Date.now();

          for (const marketName in changedOdds) {
            this.valueCache.delete(`${existingMatch.common_id}-${marketName}-${existingMatch.lastUpdateTime}`);
            this.payoutCache.delete(`${existingMatch.common_id}-${marketName}-${existingMatch.lastUpdateTime}`);
          }

          this.$nextTick(() => {
            this.checkAndNotify(existingMatch);
            this.updatePaginatedMatches();
            this.updateBookieMatchCounts();
            this.updateSelectedOdds();
          });

          this.debouncedSort();
        }
      }
    },
    diffOdds(existingOdds, newOdds, bookie) {
      const changedOdds = {};

      for (const marketName in existingOdds) {
        const existingMarket = existingOdds[marketName];
        const newMarket = newOdds[marketName] || {};

        for (const outcome in existingMarket) {
          const existingOutcome = existingMarket[outcome];
          const newOutcome = newMarket[outcome] || {};

          if (marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle') {
            for (const handicap in existingOutcome) {
              const existingHandicapOdds = existingOutcome[handicap];
              const newHandicapOdds = newOutcome[handicap] || {};

              const existingOdd = existingHandicapOdds[bookie];
              const newOdd = newHandicapOdds[bookie];

              if (existingOdd !== undefined && newOdd === undefined) {
                if (!changedOdds[marketName]) changedOdds[marketName] = {};
                if (!changedOdds[marketName][outcome]) changedOdds[marketName][outcome] = {};
                if (!changedOdds[marketName][outcome][handicap]) changedOdds[marketName][outcome][handicap] = {};
                changedOdds[marketName][outcome][handicap][bookie] = undefined;
              }
            }
          } else {
            const existingOdd = existingOutcome[bookie];
            const newOdd = newOutcome[bookie];

            if (existingOdd !== undefined && newOdd === undefined) {
              if (!changedOdds[marketName]) changedOdds[marketName] = {};
              if (!changedOdds[marketName][outcome]) changedOdds[marketName][outcome] = {};
              changedOdds[marketName][outcome][bookie] = undefined;
            }
          }
        }
      }

      for (const marketName in newOdds) {
        const newMarket = newOdds[marketName];
        const existingMarket = existingOdds[marketName] || {};

        for (const outcome in newMarket) {
          const newOutcome = newMarket[outcome];
          const existingOutcome = existingMarket[outcome] || {};

          if (marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle') {
            for (const handicap in newOutcome) {
              const newHandicapOdds = newOutcome[handicap];
              const existingHandicapOdds = existingOutcome[handicap] || {};

              const newOdd = newHandicapOdds[bookie];
              const existingOdd = existingHandicapOdds[bookie];

              if (existingOdd !== newOdd) {
                if (!changedOdds[marketName]) changedOdds[marketName] = {};
                if (!changedOdds[marketName][outcome]) changedOdds[marketName][outcome] = {};
                if (!changedOdds[marketName][outcome][handicap]) changedOdds[marketName][outcome][handicap] = {};
                changedOdds[marketName][outcome][handicap][bookie] = newOdd;
              }
            }
          } else {
            const newOdd = newOutcome[bookie];
            const existingOdd = existingOutcome[bookie];

            if (existingOdd !== newOdd) {
              if (!changedOdds[marketName]) changedOdds[marketName] = {};
              if (!changedOdds[marketName][outcome]) changedOdds[marketName][outcome] = {};
              changedOdds[marketName][outcome][bookie] = newOdd;
            }
          }
        }
      }

      return changedOdds;
    },
    mergeOdds(existingMatch, changedOdds, bookie, timestamp, isInitialLoad = false) {
      for (const marketName in changedOdds) {
        if (!existingMatch.odds[marketName]) {
          existingMatch.odds[marketName] = {};
        }

        for (const outcome in changedOdds[marketName]) {
          if (!existingMatch.odds[marketName][outcome]) {
            existingMatch.odds[marketName][outcome] = {};
          }

          if (marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle') {
            for (const handicap in changedOdds[marketName][outcome]) {
              if (!existingMatch.odds[marketName][outcome][handicap]) {
                existingMatch.odds[marketName][outcome][handicap] = {};
              }

              const newValue = changedOdds[marketName][outcome][handicap][bookie];
              const oldValue = existingMatch.odds[marketName][outcome][handicap][bookie];
              const panosraja = changedOdds[marketName][outcome][handicap][`${bookie}_panosraja`];
              const betfairMarketId = changedOdds[marketName][outcome][handicap].betfair_market_id;
              const betfairRunnerId = changedOdds[marketName][outcome][handicap].betfair_runner_id;

              if (!isInitialLoad) {
                // Modified condition to detect additions
                const changeType = (!oldValue || oldValue === 0 || isNaN(oldValue)) && newValue ? 'added' 
                                 : !newValue ? 'removed' 
                                 : 'changed';

                // Only track movements if the difference is significant enough
                if (changeType === 'changed') {
                  const difference = Math.abs(newValue - oldValue);
                  if (difference > 0.009) {
                    this.trackOddsMovement(
                      existingMatch.common_id,
                      marketName,
                      outcome,
                      bookie,
                      oldValue,
                      newValue,
                      handicap,
                      timestamp,
                      changeType
                    );
                  }
                } else {
                  // Still track additions and removals
                  this.trackOddsMovement(
                    existingMatch.common_id,
                    marketName,
                    outcome,
                    bookie,
                    oldValue,
                    newValue,
                    handicap,
                    timestamp,
                    changeType
                  );
                }
              }

              if (newValue !== undefined) {
                existingMatch.odds[marketName][outcome][handicap][bookie] = newValue;
                if (panosraja !== undefined) {
                  existingMatch.odds[marketName][outcome][handicap][`${bookie}_panosraja`] = panosraja;
                }
                if (bookie === 'betfair') {
                  if (betfairMarketId !== undefined) {
                    existingMatch.odds[marketName][outcome][handicap].betfair_market_id = betfairMarketId;
                  }
                  if (betfairRunnerId !== undefined) {
                    existingMatch.odds[marketName][outcome][handicap].betfair_runner_id = betfairRunnerId;
                  }
                }
                existingMatch.odds[marketName][outcome][handicap].timestamp = timestamp;
              } else {
                delete existingMatch.odds[marketName][outcome][handicap][bookie];
                delete existingMatch.odds[marketName][outcome][handicap][`${bookie}_panosraja`];
                if (bookie === 'betfair') {
                  delete existingMatch.odds[marketName][outcome][handicap].betfair_market_id;
                  delete existingMatch.odds[marketName][outcome][handicap].betfair_runner_id;
                  delete existingMatch.odds[marketName][outcome][handicap].betfair_handicap;
                }

                // Add this new code to recalculate fair odds and value
                const marketData = existingMatch.odds[marketName];
                if (marketData) {
                  // Force recalculation for all remaining outcomes in the market
                  Object.keys(marketData).forEach(remainingOutcome => {
                    if (this.memoizedCalculateValueAndFairOdds && this.memoizedCalculateValueAndFairOdds.cache) {
                      this.memoizedCalculateValueAndFairOdds.cache.delete(
                        JSON.stringify([
                          marketData[remainingOutcome],
                          remainingOutcome,
                          marketData,
                          Date.now(),
                          existingMatch.common_id
                        ])
                      );
                    }

                    // Recalculate for top odds if they exist
                    const topOddsInfo = this.getOddsValue(marketData[remainingOutcome], 'top');
                    if (topOddsInfo.value !== '-') {
                      const result = this.calculateValueAndFairOdds(
                        marketData[remainingOutcome],
                        remainingOutcome,
                        marketData,
                        Date.now(),
                        existingMatch.common_id
                      );
                      
                      // Update value cache
                      const cacheKey = `${existingMatch.common_id}-${marketName}-${existingMatch.lastUpdateTime}`;
                      this.valueCache.set(cacheKey, result);
                    }
                  });
                }

                if (Object.keys(existingMatch.odds[marketName][outcome][handicap]).length === 0) {
                  delete existingMatch.odds[marketName][outcome][handicap];
                }
              }
            }

            if (Object.keys(existingMatch.odds[marketName][outcome]).length === 0) {
              delete existingMatch.odds[marketName][outcome];
            }

          } else {
            const newValue = changedOdds[marketName][outcome][bookie];
            const oldValue = existingMatch.odds[marketName][outcome][bookie];
            const panosraja = changedOdds[marketName][outcome][`${bookie}_panosraja`];
            const betfairMarketId = changedOdds[marketName][outcome].betfair_market_id;
            const betfairRunnerId = changedOdds[marketName][outcome].betfair_runner_id;

            if (!isInitialLoad) {
              // Modified condition to detect additions
              const changeType = (!oldValue || oldValue ===0 || isNaN(oldValue)) && newValue ? 'added' 
                               : !newValue ? 'removed' 
                               : 'changed';

              // Only track movements if the difference is significant enough
              if (changeType === 'changed') {
                const difference = Math.abs(newValue - oldValue);
                if (difference > 0.009) {
                  this.trackOddsMovement(
                    existingMatch.common_id,
                    marketName,
                    outcome,
                    bookie,
                    oldValue,
                    newValue,
                    null,
                    timestamp,
                    changeType
                  );
                }
              } else {
                // Still track additions and removals
                this.trackOddsMovement(
                  existingMatch.common_id,
                  marketName,
                  outcome,
                  bookie,
                  oldValue,
                  newValue,
                  null,
                  timestamp,
                  changeType
                );
              }
            }

            if (newValue !== undefined) {
              existingMatch.odds[marketName][outcome][bookie] = newValue;
              if (panosraja !== undefined) {
                existingMatch.odds[marketName][outcome][`${bookie}_panosraja`] = panosraja;
              }
              if (bookie === 'betfair') {
                if (betfairMarketId !== undefined) {
                  existingMatch.odds[marketName][outcome].betfair_market_id = betfairMarketId;
                }
                if (betfairRunnerId !== undefined) {
                  existingMatch.odds[marketName][outcome].betfair_runner_id = betfairRunnerId;
                }
              }
              existingMatch.odds[marketName][outcome].timestamp = timestamp;
            } else {
              delete existingMatch.odds[marketName][outcome][bookie];
              delete existingMatch.odds[marketName][outcome][`${bookie}_panosraja`];
              if (bookie === 'betfair') {
                delete existingMatch.odds[marketName][outcome].betfair_market_id;
                delete existingMatch.odds[marketName][outcome].betfair_runner_id;
                delete existingMatch.odds[marketName][outcome].betfair_handicap;
              }

              // Add this new code to recalculate fair odds and value
              const marketData = existingMatch.odds[marketName];
              if (marketData) {
                // Force recalculation of fair odds and value for this outcome
                if (this.memoizedCalculateValueAndFairOdds && this.memoizedCalculateValueAndFairOdds.cache) {
                  this.memoizedCalculateValueAndFairOdds.cache.delete(
                    JSON.stringify([
                      marketData[outcome],
                      outcome,
                      marketData,
                      Date.now(),
                      existingMatch.common_id
                    ])
                  );
                }

                // Also recalculate for top odds if they exist
                const topOddsInfo = this.getOddsValue(marketData[outcome], 'top');
                if (topOddsInfo.value !== '-') {
                  const result = this.calculateValueAndFairOdds(
                    marketData[outcome],
                    outcome,
                    marketData,
                    Date.now(),
                    existingMatch.common_id
                  );
                  
                  // Update value cache
                  const cacheKey = `${existingMatch.common_id}-${marketName}-${existingMatch.lastUpdateTime}`;
                  this.valueCache.set(cacheKey, result);
                }
              }

              if (Object.keys(existingMatch.odds[marketName][outcome]).length ===0) {
                delete existingMatch.odds[marketName][outcome];
              }
            }
          }

          if (Object.keys(existingMatch.odds[marketName]).length === 0) {
            delete existingMatch.odds[marketName];
          }
        }
      }
    },
    handleAdd(data) {
      const existingIndex = this.nestedMatches.findIndex(match => match.ID === data.ID);
      if (existingIndex !== -1) {
        this.handleUpdate(data);
      } else {
        const newMatch = this.processNestedData([data])[0];
        if (newMatch) {
          this.nestedMatches.push(newMatch);
          this.updateBookieMatchCounts();
        }
      }
    },
    async reloadInitialData() {
      try {
        // Clear existing data
        this.nestedMatches = [];
        this.matchesAboveThreshold = {};
        this.matchesBelowThreshold = {};
        this.valueLastChanged = {};
        this.valueCache.clear();
        this.payoutCache.clear();
        
        // Show loading message
        this.showToastMessage('Ladataan otteludata uudelleen...', 'info', 5000);
        this.isLoading = true;
        
        // Request new initial data
        this.socket.emit('request_initial_data');
        
        // Reset connection-related states
        this.lastDisconnectTime = null;
        this.initialDataLoaded = false;
        
      } catch (error) {
        console.error('Error reloading initial data:', error);
        this.showToastMessage('Virhe tietojen uudelleenlatauksessa', 'error', 5000);
      }
    },
    async checkAndNotify(match) {
      // Early return if match is hidden
      if (this.isMatchHidden(match)) {
        return;
      }

      const wasAboveThreshold = this.matchesAboveThreshold[match.common_id] || false;
      const wasBelowThreshold = this.matchesBelowThreshold[match.common_id] || false;

      // Get the numeric value of the filter
      const valueFilter = parseFloat(this.valueFilter);

      // Ensure that the logic accounts for a valueFilter of 0
      const bestValue = this.getBestValue(match);
      const numericBestValue = parseFloat(bestValue.value);

      // Early return if value is not valid
      if (isNaN(numericBestValue)) {
        return;
      }

      // Early return if payout filter fails
      if (this.payoutFilter) {
        const maxPayout = this.getMaxPayout(match);
        if (maxPayout < this.payoutFilter) {
          return;
        }
      }

      // Early return if max odds filter fails
      if (this.maxOddsFilter && bestValue.details && 
          parseFloat(bestValue.details.odds) > this.maxOddsFilter) {
        return;
      }

      if (!wasAboveThreshold && numericBestValue >= valueFilter) {
        this.matchesBelowThreshold[match.common_id] = false;
        if (this.showDetailedBestValue) {
          this.showToastMessage(`Value ${bestValue.value}\n${match.home} vs ${match.away}`, 'success', 5000);
        }
        this.playNotificationSound(this.notificationSettings.valueUpSpeed.value);
        this.matchesAboveThreshold[match.common_id] = true;
        this.updateValueLastChanged(match.common_id);

        const bookieName = this.bookieAbbreviations[bestValue.details.bookie] || bestValue.details.bookie;

        const messageLines = [
          `${bestValue.value}`,
          `${match.home} vs ${match.away}`,
          `${match.start_time}`,
          bestValue.details 
            ? `${bestValue.details.market} ${bestValue.details.outcome}${bestValue.details.handicap ? ` ${bestValue.details.handicap}` : ''}\nKerroin: ${bestValue.details.odds} (${bookieName})\nPanos: ${bestValue.details.recommendedStake}€`
            : ''
        ];

        const message = messageLines.filter(line => line).join('\n').trim();

        if (this.telegramEnabled) {
          await this.sendTelegramNotification(message);
        }
      } else if (wasAboveThreshold && numericBestValue < valueFilter) {
        this.matchesAboveThreshold[match.common_id] = false;
        if (numericBestValue >= valueFilter) {
          this.updateValueLastChanged(match.common_id);
        } else {
          delete this.valueLastChanged[match.common_id];
        }

        this.showToastMessage(`Value laskenut!\n${bestValue.value}\n${match.home} vs ${match.away}`, 'info', 5000);
        this.playNotificationSound(this.notificationSettings.valueDropSpeed.value);
        
        const message = `Value laskenut!\n${bestValue.value}\n${match.home} vs ${match.away}`;
        if (this.telegramEnabled) {
          await this.sendTelegramNotification(message);
        }
      } else if (wasBelowThreshold && numericBestValue >= valueFilter) {
        this.matchesBelowThreshold[match.common_id] = false;
      }

      this.$nextTick(() => {
        this.updatePaginatedMatches();
        this.$forceUpdate();
      });
    },
    matchPassesFilters(match) {
      // Early return if match is hidden
      if (this.isMatchHidden(match)) {
        return false;
      }

      const valueFilter = parseFloat(this.valueFilter);
      const payoutFilter = parseFloat(this.payoutFilter);
      const maxOddsFilter = parseFloat(this.maxOddsFilter);

      // Early return if no valid filters
      if (!valueFilter && !payoutFilter && !maxOddsFilter) {
        return true;
      }

      // Early return for started matches
      if (this.hideStartedMatches && this.parseDateTime(match.start_time) <= new Date()) {
        return false;
      }

      // Check payout first as it's likely the quickest calculation
      if (payoutFilter) {
        const maxPayout = this.getMaxPayout(match);
        if (maxPayout < payoutFilter) {
          return false;
        }
      }

      // Check value next
      if (valueFilter) {
        const bestValue = this.getBestValue(match);
        if (isNaN(bestValue.value) || parseFloat(bestValue.value) < valueFilter) {
          return false;
        }

        // Only check max odds if we have a valid value
        if (maxOddsFilter) {
          const maxOddsCheck = this.checkMaxOddsFilter(match);
          if (!maxOddsCheck.hasValidBet || maxOddsCheck.allBetsAboveMaxOdds) {
            return false;
          }
        }
      }

      return true;
    },
    getTableHeaders(marketName) {
      if (marketName === "1X2" || marketName === "Voittaja") {
        return [
          "Tulos",
          ...this.bookieOrder.map(
            (bookie) => this.bookieAbbreviations[bookie] || bookie
          ),
          "Value",
        ];
      } else if (
        marketName === "Aasialainen tasoitus" ||
        marketName === "Yli/Alle"
      ) {
        return [
          marketName === "Yli/Alle" ? "Yli" : "Koti",
          ...this.bookieOrder.map(
            (bookie) => this.bookieAbbreviations[bookie] || bookie
          ),
          "Value",
          marketName === "Yli/Alle" ? "Alle" : "Vieras",
          ...this.bookieOrder.map(
            (bookie) => this.bookieAbbreviations[bookie] || bookie
          ),
          "Value",
          "Palautus",
        ];
      } else {
        return [
          "Outcome",
          ...this.bookieOrder.map(
            (bookie) => this.bookieAbbreviations[bookie] || bookie
          ),
        ];
      }
    },
    sortOutcomes(marketName, marketData) {
      if (marketName === '1X2') {
        const order = ['Koti', 'Tasapeli', 'Vieras'];
        return Object.keys(marketData)
          .sort((a, b) => order.indexOf(a) - order.indexOf(b))
          .reduce((acc, key) => {
            acc[key] = marketData[key];
            return acc;
          }, {});
      } else if (marketName === 'Voittaja') {
        const order = ['Koti', 'Vieras'];
        return Object.keys(marketData)
          .sort((a, b) => order.indexOf(a) - order.indexOf(b))
          .reduce((acc, key) => {
            acc[key] = marketData[key];
            return acc;
          }, {});
      }
      return marketData;
    },  
    generatePairedHandicapRows(marketData, marketName, matchId) {
      const isAsianHandicap = marketName === "Aasialainen tasoitus";

      // Early return if market data is empty
      if (!marketData || Object.keys(marketData).length === 0) {
        return [];
      }

      const labelLeftOptions = isAsianHandicap ? ["Koti", "Home"] : ["Yli", "Over"];
      const labelRightOptions = isAsianHandicap ? ["Vieras", "Away"] : ["Alle", "Under"];

      const labelLeft = this.findExistingLabel(marketData, labelLeftOptions);
      const labelRight = this.findExistingLabel(marketData, labelRightOptions);

      // Early return if labels are not found
      if (!labelLeft || !labelRight) {
        console.warn('Labels not found in marketData:', marketData);
        return [];
      }

      // Early return if no handicaps exist
      const handicapsLeft = Object.keys(marketData[labelLeft] || {});
      const handicapsRight = Object.keys(marketData[labelRight] || {});
      if (handicapsLeft.length === 0 && handicapsRight.length === 0) {
        return [];
      }

      const allHandicapsSet = new Set();

      if (isAsianHandicap) {
        handicapsLeft.forEach(h => allHandicapsSet.add(h));
        handicapsRight.forEach(h => {
          const opposite = getOppositeHandicap(h);
          allHandicapsSet.add(opposite);
        });
      } else {
        handicapsLeft.forEach(h => allHandicapsSet.add(h));
        handicapsRight.forEach(h => allHandicapsSet.add(h));
      }

      const sortedHandicaps = Array.from(allHandicapsSet).sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      );

      return sortedHandicaps.map((handicap) => {
        const leftOdds = marketData[labelLeft]?.[handicap] || {};
        let correspondingHandicap = handicap;
        let rightOdds = {};

        if (isAsianHandicap) {
          correspondingHandicap = getOppositeHandicap(handicap);
          rightOdds = marketData[labelRight]?.[correspondingHandicap] || {};
        } else {
          rightOdds = marketData[labelRight]?.[handicap] || {};
        }

        // Get top odds for both sides
        const topLeftOdds = this.getOddsValue(leftOdds, 'top').value;
        const topRightOdds = this.getOddsValue(rightOdds, 'top').value;

        // Skip if either odds are outside the valid range
        if (topLeftOdds === '-' || topRightOdds === '-' ||
            parseFloat(topLeftOdds) > 6.0 || parseFloat(topLeftOdds) < 1.15 ||
            parseFloat(topRightOdds) > 6.0 || parseFloat(topRightOdds) < 1.15) {
          return null;
        }

        const { fairOdds: fairLeftOdds } = this.getOddsAndValue(leftOdds, labelLeft, {
          [labelLeft]: leftOdds,
          [labelRight]: rightOdds,
        }, matchId);

        const { fairOdds: fairRightOdds } = this.getOddsAndValue(rightOdds, labelRight, {
          [labelLeft]: leftOdds,
          [labelRight]: rightOdds,
        }, matchId);

        const { value: leftValue } = this.getOddsAndValue(leftOdds, labelLeft, {
          [labelLeft]: leftOdds,
          [labelRight]: rightOdds,
        }, matchId);

        const { value: rightValue } = this.getOddsAndValue(rightOdds, labelRight, {
          [labelLeft]: leftOdds,
          [labelRight]: rightOdds,
        }, matchId);

        const row = {
          handicap,
          leftOdds,
          fairLeftOdds,
          leftValue,
          correspondingHandicap,
          rightOdds,
          fairRightOdds,
          rightValue,
          leftLabel: labelLeft,
          rightLabel: labelRight,
        };

        const payout = this.calculatePayoutForHandicap(leftOdds, rightOdds);

        if (
          leftValue !== '-' ||
          rightValue !== '-' ||
          (payout !== '-' && parseFloat(payout) > 0)
        ) {
          return {
            ...row,
            payout,
          };
        }

        return null;
      }).filter(row => row !== null);
    },
    findExistingLabel(marketData, options) {
      for (const option of options) {
        if (marketData[option]) {
          return option;
        }
      }
      return null;
    },
    getValueCellStyle(valueStr) {
      const value = parseFloat(valueStr);
      if (isNaN(value)) {
        return '';
      }

      const maxGreen = 6;
      const maxRed = -10;

      let intensity = 0;

      if (value >= 0) {
        intensity = Math.min(255, Math.round((value / maxGreen) * 255));
        return this.darkMode
          ? `background-color: rgba(0, ${intensity}, 0, 0.4); color: rgb(180, 255, 180);`
          : `background-color: rgba(0, ${intensity}, 0, 0.3); color: rgb(${Math.max(0, 100 - intensity)}, 0, 0);`;
      } else {
        intensity = Math.min(255, Math.round((Math.abs(value) / Math.abs(maxRed)) * 255));
        return this.darkMode
          ? `background-color: rgba(${intensity}, 0, 0, 0.4); color: rgb(255, 180, 180);`
          : `background-color: rgba(${intensity}, 0, 0, 0.3); color: rgb(${Math.max(0, 100 - intensity)}, 0, 0);`;
      }
    },
    getBestValueClass (match) {
      return this.getBestValue(match).style;
    },
    getPayoutCellStyle(payoutStr) {
      const payout = parseFloat(payoutStr);
      if (!payoutStr || payoutStr === '-') {
        return '';
      }

      if (isNaN(payout)) {
        return '';
      }

      const minPayout = 90;
      const maxPayout = 103;

      const payoutRatio = (payout - minPayout) / (maxPayout - minPayout);
      const intensity = Math.min(255, Math.max(0, Math.round(payoutRatio * 255)));

      return this.darkMode
        ? `background-color: rgba(${255 - intensity}, ${intensity}, 0, 0.4); color: rgb(255, 255, 180);`
        : `background-color: rgba(${255 - intensity}, ${intensity}, 0, 0.3); color: rgb(${Math.max(0, 100 - intensity)}, ${Math.min(100, intensity)}, 0);`;
    },
    setSorting(field) {
      if (this.sortBy === field) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = field;
        this.sortDesc = true;
      }
      this.debouncedSort();
    },
    sortMatches(matches) {
      const searchLower = (this.debouncedSearchTerm || '').toLowerCase();
      
      const getSortValue = (match) => {
        if (searchLower && 
            ((match.home && match.home.toLowerCase().includes(searchLower)) || 
             (match.away && match.away.toLowerCase().includes(searchLower)))) {
          return Infinity;
        }

        switch (this.sortBy) {
          case 'value':
            return this.getMaxValue(match);
          case 'payout':
            return this.getMaxPayout(match);
          case 'start_time':
            return match.start_time ? this.parseDateTime(match.start_time).getTime() : 0;
          case 'last_updated':
            return this.getLastUpdatedTimestamp(match);
          default:
            return 0;
        }
      };

      return [...matches].sort((a, b) => {
        const aValue = getSortValue(a);
        const bValue = getSortValue(b);

        return this.sortDesc ? bValue - aValue : aValue - bValue;
      });
    },
    getLastUpdatedTimestamp(match) {
      let latestTimestamp = 0;
      for (const bookie in match.timestamps) {
        const timestamp = match.timestamps[bookie];
        if (timestamp > latestTimestamp) {
          latestTimestamp = timestamp;
        }
      }
      return latestTimestamp;
    },
    updatePaginatedMatches: throttle(function() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedMatches = this.filteredMatches.slice(start, end).map(match => {
        const bestValueData = this.getBestValue(match);
        return {
          ...match,
          bestValue: bestValueData.value,
          bestValueDetails: bestValueData.details,
          bestValueStyle: bestValueData.style
        };
      });
    }, 500),
    checkMaxOddsFilter(match) {
      const valueThreshold = parseFloat(this.valueFilter);
      const maxAllowedOdds = parseFloat(this.maxOddsFilter);
      let hasValidBet = false;
      let allBetsAboveMaxOdds = true;

      for (const marketName in match.odds) {
        const marketData = match.odds[marketName];
        if (marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle') {
          this.generatePairedHandicapRows(marketData, marketName, match.common_id).forEach(row => {
            const leftValue = parseFloat(row.leftValue);
            const rightValue = parseFloat(row.rightValue);
            const leftOdds = parseFloat(this.getOddsValue(row.leftOdds, 'top').value);
            const rightOdds = parseFloat(this.getOddsValue(row.rightOdds, 'top').value);

            if (leftValue >= valueThreshold) {
              hasValidBet = true;
              if (leftOdds <= maxAllowedOdds) {
                allBetsAboveMaxOdds = false;
              }
            }
            if (rightValue >= valueThreshold) {
              hasValidBet = true;
              if (rightOdds <= maxAllowedOdds) {
                allBetsAboveMaxOdds = false;
              }
            }
          });
      } else {
          for (const outcomeName in marketData) {
            const value = parseFloat(this.getOddsAndValue(marketData[outcomeName], outcomeName, marketData, match.common_id).value);
            const odds = parseFloat(this.getOddsValue(marketData[outcomeName], 'top').value);
            
            if (value >= valueThreshold) {
              hasValidBet = true;
              if (odds <= maxAllowedOdds) {
                allBetsAboveMaxOdds = false;
              }
            }
          }
        }
      }

      return {
        hasValidBet,
        allBetsAboveMaxOdds
      };
    },
    calculatePayout(marketData, bookie) {
      if (!bookie || bookie === 'prediction') return "";

      const oddsArray = Object.values(marketData).map((outcomeData) => {
        const oddsInfo = this.getOddsValue(outcomeData, bookie);
        const odds = oddsInfo.value;
        return odds !== "-" && !isNaN(parseFloat(odds)) ? 1 / parseFloat(odds) : 0;
      });

      const validOddsCount = oddsArray.filter(prob => prob > 0).length;
      const totalOutcomes = Object.keys(marketData).length;

      // Skip if market type doesn't match required number of outcomes
      if (totalOutcomes !== 2 && totalOutcomes !== 3) {
        return "-";
      }

      const is1X2Market = totalOutcomes === 3;
      const isVoittajaMarket = totalOutcomes === 2;

      // Require exact number of valid odds for each market type
      if ((is1X2Market && validOddsCount !== 3) || (isVoittajaMarket && validOddsCount !== 2)) {
        return "-";
      }

      const totalProbability = oddsArray.reduce((sum, prob) => sum + prob, 0);
      const payout = totalProbability > 0 ? ((1 / totalProbability) * 100) : 0;

      return payout > 0 ? payout.toFixed(3) : "-";
    },
    calculatePayoutForHandicap(leftOdds, rightOdds) {
      const leftOddsCopy = JSON.parse(JSON.stringify(leftOdds));
      const rightOddsCopy = JSON.parse(JSON.stringify(rightOdds));

      const leftTopOddsInfo = this.getOddsValue(leftOddsCopy, 'top');
      const rightTopOddsInfo = this.getOddsValue(rightOddsCopy, 'top');

      const leftTopOdds = leftTopOddsInfo.value;
      const rightTopOdds = rightTopOddsInfo.value;

      if (leftTopOdds !== "-" && rightTopOdds !== "-") {
        const totalProbability = (1 / parseFloat(leftTopOdds)) + (1 / parseFloat(rightTopOdds));
        if (totalProbability > 0) {
          return ((1 / totalProbability) * 100).toFixed(3);
        }
      }

      return "-";
    },
    getOddsAndValue(oddsData, outcomeName, marketData, matchId) {
      if (!oddsData || !marketData) {
        return { value: "-", fairOdds: "-" };
      }

      const timestamp = marketData[outcomeName] && marketData[outcomeName].timestamp 
        ? marketData[outcomeName].timestamp 
        : Date.now();

      const result = this.memoizedCalculateValueAndFairOdds(
        oddsData, 
        outcomeName, 
        marketData,
        timestamp,
        matchId
      );
      return {
        fairOdds: result.fairOdds,
        value: result.value,
        valueParsed: parseFloat(result.value)
      };
    },
    calculateValueAndFairOdds(oddsData, outcomeName, marketData, timestamp, matchId) {
      if (!oddsData || !marketData) {
        return { value: "-", fairOdds: "-" };
      }

      const outcomeIndex = Object.keys(marketData).indexOf(outcomeName);
      const bookmakerOdds = [];
      const topOddsInfo = this.getOddsValue(oddsData, 'top');
      const bookie = topOddsInfo.bookie;
      const topOdds = parseFloat(topOddsInfo.value);
      const predictionOdds = parseFloat(oddsData['prediction'] || "-");

      let bookiesToConsider;
      if (this.useBetfairValues && bookie === 'betfair') {
        bookiesToConsider = ['pinnacle'];
      } else if (this.usePinnacleValues && bookie === 'pinnacle') {
        bookiesToConsider = ['betfair'];
      } else {
        bookiesToConsider = ['betfair', 'pinnacle'];
      }

      const outcomeNames = Object.keys(marketData);
      const validOutcomeNames = outcomeNames.filter(outcome => {
        const oddsDataForOutcome = marketData[outcome];
        return oddsDataForOutcome && bookiesToConsider.some(bookie => !isNaN(parseFloat(oddsDataForOutcome[bookie])));
      });

      const is3WayMarket = validOutcomeNames.length === 3;
      const is2WayMarket = validOutcomeNames.length === 2;

      let originalOdds = [];
      for (let outcome of validOutcomeNames) {
        const oddsDataForOutcome = marketData[outcome];
        if (!oddsDataForOutcome) continue;

        let odds = -1;
        for (let bookie of bookiesToConsider) {
          const value = parseFloat(oddsDataForOutcome[bookie]);
          if (!isNaN(value)) {
            odds = Math.max(odds, value);
          }
        }

        if (odds > 0) {
          bookmakerOdds.push(odds);
          originalOdds.push(odds);
        } else {
          return { value: "-", fairOdds: "-" };
        }
      }

      const margin = (originalOdds.reduce((sum, odds) => sum + (1 / odds), 0) - 1) * 100;
      
      let fairOdds;

      if (is3WayMarket) {
        const [originalHomeOdds, originalDrawOdds, originalAwayOdds] = bookmakerOdds;
        fairOdds = calculateFair3WayOdds(bookmakerOdds, outcomeIndex, originalHomeOdds, originalDrawOdds, originalAwayOdds);
      } else if (is2WayMarket) {
        const [originalHomeOdds, originalAwayOdds] = bookmakerOdds;
        fairOdds = calculateFair2WayOdds(bookmakerOdds, outcomeIndex, originalHomeOdds, originalAwayOdds);
      } else {
        return { value: "-", fairOdds: "-" };
      }

      const pinnacleStakeLimit = oddsData['pinnacle_panosraja'];

      if (pinnacleStakeLimit) {
        const minStakeLimit = this.calculationParams.stakeLimit.min;
        const maxStakeLimit = this.calculationParams.stakeLimit.max;
        const minMultiplier = this.calculationParams.stakeLimit.multiplierMin;
        const maxMultiplier = this.calculationParams.stakeLimit.multiplierMax;

        if (pinnacleStakeLimit < minStakeLimit) {
          fairOdds *= minMultiplier;
        } else if (pinnacleStakeLimit < maxStakeLimit) {
          const stakeLimitRatio = (pinnacleStakeLimit - minStakeLimit) / (maxStakeLimit - minStakeLimit);
          const multiplier = minMultiplier - (stakeLimitRatio * (minMultiplier - maxMultiplier));
          fairOdds *= multiplier;
        }
      }

      if (!isNaN(fairOdds) && margin > this.calculationParams.margin.min) {
        const minMargin = this.calculationParams.margin.min;
        const maxMargin = this.calculationParams.margin.max;
        const minMultiplier = this.calculationParams.margin.multiplierMin;
        const maxMultiplier = this.calculationParams.margin.multiplierMax;
        
        const marginRatio = Math.min(Math.max((margin - minMargin) / (maxMargin - minMargin), 0), 1);
        const multiplier = minMultiplier + (marginRatio * (maxMultiplier - minMultiplier));
        
        fairOdds *= multiplier;
      }

      if (!isNaN(predictionOdds) && !isNaN(fairOdds) && fairOdds !== 0) {
        const percentageDiff = ((predictionOdds - fairOdds) / fairOdds) * 100;
        const maxAdjustmentPercent = this.calculationParams.prediction.maxAdjustment;
        const maxDifferencePercent = this.calculationParams.prediction.maxDifference;
        const adjustmentFactor = Math.min(Math.abs(percentageDiff) / maxDifferencePercent, 1) * maxAdjustmentPercent / 100;
        
        if (predictionOdds > fairOdds) {
          fairOdds *= (1.005 + adjustmentFactor);
        } else if (predictionOdds < fairOdds) {
          fairOdds *= (1 - adjustmentFactor);
        }
      }

      const marketName = Object.keys(marketData).length === 2 ? 'Voittaja' : '1X2';

      const key = encodeOddsHistoryKey(matchId, marketName, outcomeName, bookie);
      const compressedHistory = this.oddsHistory[key];
      let movementHistory = [];
      
      if (compressedHistory) {
        try {
          movementHistory = decompressOddsHistory(compressedHistory);
        } catch (error) {
          console.error('Error decompressing odds history:', error);
        }
      }

      const now = Date.now();
      const maxTimeWindow = this.calculationParams.movement.timeWindow * 1000;
      const maxDownMultiplier = this.calculationParams.movement.maxDownMultiplier;
      const maxUpMultiplier = this.calculationParams.movement.maxUpMultiplier;

      let weightedMovement = 0;
      let totalWeight = 0;

      movementHistory.forEach(movement => {
        const timeDiff = now - movement.timestamp;
        const weight = Math.max(0, (maxTimeWindow - timeDiff) / maxTimeWindow);

        if (weight > 0) {
          const movementValue = movement.newValue - movement.oldValue;
          weightedMovement += movementValue * weight;
          totalWeight += weight;
        }
      });
      
      let movementAdjustmentFactor = 1.005;
      if (totalWeight > 0) {
        const averageMovement = weightedMovement / totalWeight;
        const maxMovement = 0.25;

        const adjustment = (averageMovement / maxMovement);
        if (averageMovement < 0) {
          movementAdjustmentFactor = Math.max(maxDownMultiplier, 1 + adjustment * (1 - maxDownMultiplier));
          //console.log('Time diff:', now - movementHistory[movementHistory.length - 1].timestamp, 'Movement do adjustment factor:', movementAdjustmentFactor, 'Fair odds:', fairOdds, 'Adjusted fair odds:', (movementAdjustmentFactor * fairOdds).toFixed(2));
        } else if (averageMovement > 0) {
          movementAdjustmentFactor = Math.min(maxUpMultiplier, 1.005 + adjustment * (maxUpMultiplier - 1));
          //console.log('Time diff:', now - movementHistory[movementHistory.length - 1].timestamp, 'Movement up adjustment factor:', movementAdjustmentFactor, 'Fair odds:', fairOdds, 'Adjusted fair odds:', (movementAdjustmentFactor * fairOdds).toFixed(2));
        }
      }
      
      fairOdds *= movementAdjustmentFactor;

      if (this.useBetfairValues && bookie === 'betfair') {
        const pinnacleFairOdds = bookmakerOdds[outcomeIndex];
        if (pinnacleFairOdds > 2.6) {
          const multiplier = (pinnacleFairOdds - 2.6) / 10 + 1;
          fairOdds *= multiplier;
        }
      }

      if (this.usePinnacleValues && bookie === 'pinnacle') {
        const betfairFairOdds = bookmakerOdds[outcomeIndex];
        if (betfairFairOdds > 2.6) {
          const multiplier = (betfairFairOdds - 2.6) / 15 + 1;
          fairOdds *= multiplier;
        }
      }

      if (!isNaN(topOdds) && !isNaN(fairOdds) && fairOdds !== 0) {
        const value = calculateValue(topOdds, fairOdds);
        return {
          value: Number(value).toFixed(1) + '%',
          fairOdds: Number(fairOdds).toFixed(3)
        };
      }

      return { value: "-", fairOdds: "-" };
    },
    getBestAndMaxValue: memoize(function(match) { 
      let bestValue = -Infinity;
      let maxValue = -Infinity;
      let bestDetails = null;
      const maxOddsFilter = parseFloat(this.maxOddsFilter);

      for (const marketName in match.odds) {
        const marketData = match.odds[marketName];
        if (marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle') {
          this.generatePairedHandicapRows(marketData, marketName, match.common_id).forEach(row => {
            const leftValue = parseFloat(row.leftValue) || -Infinity;
            const rightValue = parseFloat(row.rightValue) || -Infinity;
            const leftTopOddsInfo = this.getOddsValue(row.leftOdds, 'top');
            const rightTopOddsInfo = this.getOddsValue(row.rightOdds, 'top');
            const leftOdds = parseFloat(leftTopOddsInfo.value);
            const rightOdds = parseFloat(rightTopOddsInfo.value);
            
            maxValue = Math.max(maxValue, leftValue, rightValue);

            // Check left odds against max odds filter
            if (leftValue > bestValue && (!maxOddsFilter || leftOdds <= maxOddsFilter)) {
              bestValue = leftValue;
              bestDetails = {
                market: marketName,
                outcome: row.leftLabel,
                handicap: row.handicap,
                odds: leftTopOddsInfo.value,
                bookie: leftTopOddsInfo.bookie
              };
            }
            // Check right odds against max odds filter
            if (rightValue > bestValue && (!maxOddsFilter || rightOdds <= maxOddsFilter)) {
              bestValue = rightValue;
              bestDetails = {
                market: marketName,
                outcome: row.rightLabel,
                handicap: row.correspondingHandicap,
                odds: rightTopOddsInfo.value,
                bookie: rightTopOddsInfo.bookie
              };
            }
          });
        } else {
          for (const outcomeName in marketData) {
            const value = parseFloat(this.getOddsAndValue(marketData[outcomeName], outcomeName, marketData, match.common_id).value) || -Infinity;
            const topOddsInfo = this.getOddsValue(marketData[outcomeName], 'top');
            const odds = parseFloat(topOddsInfo.value);

            maxValue = Math.max(maxValue, value);
            
            // Check odds against max odds filter
            if (value > bestValue && (!maxOddsFilter || odds <= maxOddsFilter)) {
              bestValue = value;
              bestDetails = {
                market: marketName,
                outcome: outcomeName,
                handicap: null,
                odds: topOddsInfo.value,
                bookie: topOddsInfo.bookie
              };
            }
          }
        }
      }

      if (bestValue !== -Infinity && bestDetails) {
        const fairOdds = this.getFairOdds(match.common_id, bestDetails.market, bestDetails.outcome, bestDetails.handicap);
        const bookieOdds = bestDetails.odds;
        bestDetails.recommendedStake = this.calculateKellyStake(fairOdds, bookieOdds, this.bankroll, this.stakeRounding);
      }

      const value = bestValue === -Infinity ? -Infinity : parseFloat(bestValue.toFixed(1));
      const style = {
        backgroundColor: this.getBackgroundColor(value),
        color: this.getTextColor(value)
      };

      return {
        bestValue: {
          value: bestValue === -Infinity ? "-" : bestValue.toFixed(1) + '%',
          details: bestDetails,
          style: style
        },
        maxValue: maxValue === -Infinity ? -Infinity : maxValue
      };
    }, (match) => `${match.common_id}-${match.lastUpdateTime}`),
    getBestValue(match) {
      return this.getBestAndMaxValue(match).bestValue;
    },
    getMaxValue(match) {
      return this.getBestAndMaxValue(match).maxValue;
    },
    getMaxPayout: memoize(function(match) {
      const cacheKey = `${match.common_id}-${match.lastUpdateTime}`;
      if (this.payoutCache.has(cacheKey)) {
        return this.payoutCache.get(cacheKey);
      }

      let maxPayout = -Infinity;

      for (const marketName in match.odds) {
        const marketData = match.odds[marketName];
          if (marketName === 'Aasialainen tasoitus' || marketName === 'Yli/Alle') {
          const pairedRows = this.generatePairedHandicapRows(marketData, marketName, match.common_id);
          for (const row of pairedRows) {
            const payout = parseFloat(this.calculatePayoutForHandicap(row.leftOdds, row.rightOdds));
            if (!isNaN(payout) && payout > maxPayout) {
              maxPayout = payout;
            }
          }
        } else {
          const payout = parseFloat(this.calculatePayout(marketData, 'top'));
          if (!isNaN(payout) && payout > maxPayout) {
            maxPayout = payout;
          }
        }
      }

      const result = maxPayout === -Infinity ? -Infinity : maxPayout;
      this.payoutCache.set(cacheKey, result);
      return result;
    }, function(match) {
      return `${match.common_id}-${match.lastUpdateTime}`;
    }), 
    handlePageSizeChange() {
      this.currentPage = 1;
      this.updatePaginatedMatches();
    },
    handleFilterChange() {
      this.currentPage = 1;
      this.matchesAboveThreshold = {};
      this.debouncedApplyFilters();
    },
    performSort: throttle(function() {
      this.nestedMatches = this.sortMatches(this.nestedMatches);
      this.applyFilters();
    }, 500),
    async handleOddsClick(matchId, market, outcome, bookie, odds, handicap = null) {
      if (bookie === 'prediction') {
        this.predictionData = {}; // Clear any existing data
        try {
          const response = await fetch(`https://avai.fi/predictions/prediction/${matchId}?token=318860`);

          if (!response.ok) {
            throw new Error('Failed to fetch prediction data');
          }

          const data = await response.json();
          this.predictionData = {
            ID: matchId,
            home_pred: data.home_pred,
            away_pred: data.away_pred,
            home_adjustment: data.home_adjustment,
            away_adjustment: data.away_adjustment,
            rho: data.rho,
            reason: data.reason,
            stats: data.Data
          };
          this.showPredictionEditor = true;
        } catch (error) {
          this.showToastMessage('Virhe ennusteen haussa: ' + error.message, 'error', 5000);
        }
        return;
      }

      this.stakeWarning = null;
      this.oddsError = null;
      
      const marketOdds = this.getMarketOdds(matchId, market, outcome, handicap);
      let actualBookie = bookie;
      let actualOdds = parseFloat(odds);
      if (isNaN(actualOdds)) {
        actualOdds = 0;
      }
      let panosraja = null;
      let betfairMarketId = null;
      let betfairRunnerId = null;

      if (bookie === 'top') {
        const topOddsInfo = this.getOddsValue(marketOdds, 'top');
        actualBookie = topOddsInfo.bookie;
        actualOdds = parseFloat(topOddsInfo.value);
        panosraja = topOddsInfo.panosraja;
      } else {
        const oddsInfo = this.getOddsValue(marketOdds, bookie);
        actualOdds = parseFloat(oddsInfo.value);
        panosraja = oddsInfo.panosraja;
      }
      
      const fairOdds = this.getFairOdds(matchId, market, outcome, handicap);
      const predictionOdds = this.getPredictionOdds(matchId, market, outcome, handicap);
      
      const valueOdds = fairOdds || predictionOdds;
      const value = calculateValue(actualOdds, fairOdds, true);
      const recommendedStake = valueOdds ? this.calculateKellyStake(valueOdds, actualOdds, this.bankroll, this.stakeRounding) : 'N/A';

      if (actualBookie === 'betfair') {
        betfairMarketId = marketOdds.betfair_market_id;
        betfairRunnerId = marketOdds.betfair_runner_id;
        this.betfairOdds = actualOdds.toFixed(2);
        this.betfairStake = recommendedStake !== 'N/A' ? parseFloat(recommendedStake).toFixed(2) : '';
        this.validateStake();
      }

      this.selectedOdds = {
        matchId,
        market,
        outcome,
        bookie: actualBookie,
        odds: actualOdds.toFixed(3),
        handicap,
        fairOdds: fairOdds ? fairOdds.toFixed(3) : 'N/A',
        value,
        recommendedStake,
        panosraja,
        betfairMarketId,
        betfairRunnerId
      };
      
      this.showOddsDetails = true;
    },
    getMarketOdds(matchId, market, outcome, handicap) {
      const match = this.nestedMatches.find(m => m.common_id === matchId);
      if (!match || !match.odds[market]) return {};

      if (handicap !== null) {
        return match.odds[market][outcome][handicap] || {};
      } else {
        return match.odds[market][outcome] || {};
      }
    },
    getFairOdds(matchId, market, outcome, handicap) {
      const match = this.nestedMatches.find(m => m.common_id === matchId);
      if (!match || !match.odds[market]) return null;

      let marketData;
      if (market === 'Aasialainen tasoitus' || market === 'Yli/Alle') {
        // Create a simplified market structure for handicap markets
        marketData = {};
        const oppositeOutcome = this.getOppositeOutcome(outcome);
        
        if (handicap !== null) {
          const oppositeHandicap = market === 'Aasialainen tasoitus' ? 
            getOppositeHandicap(handicap) : 
            handicap;

          // Add the selected outcome
          marketData[outcome] = match.odds[market][outcome]?.[handicap] || {};
          
          // Add the opposite outcome
          marketData[oppositeOutcome] = match.odds[market][oppositeOutcome]?.[oppositeHandicap] || {};
        }
      } else {
        marketData = match.odds[market];
      }

      if (!marketData[outcome]) return null;

      const { fairOdds } = this.getOddsAndValue(marketData[outcome], outcome, marketData, matchId);
      return parseFloat(fairOdds) || null;
    },
    updateSelectedOdds() {
      if (!this.selectedOdds) return;

      const { matchId, market, outcome, bookie, handicap } = this.selectedOdds;
      const marketOdds = this.getMarketOdds(matchId, market, outcome, handicap);
      
      let actualBookie = bookie;
      let actualOdds = parseFloat(this.getOddsValue(marketOdds, bookie).value);
      let panosraja = null;
      let betfairMarketId = null;
      let betfairRunnerId = null;

      if (isNaN(actualOdds)) {
        actualOdds = 0;
      }

      if (bookie === 'top') {
        const topOddsInfo = this.getOddsValue(marketOdds, 'top');
        actualBookie = topOddsInfo.bookie;
        actualOdds = parseFloat(topOddsInfo.value);
        panosraja = topOddsInfo.panosraja;
      } else {
        const oddsInfo = this.getOddsValue(marketOdds, bookie);
        actualOdds = parseFloat(oddsInfo.value);
        panosraja = marketOdds[`${bookie}_panosraja`];
      }

      if (actualBookie === 'betfair') {
        betfairMarketId = marketOdds.betfair_market_id;
        betfairRunnerId = marketOdds.betfair_runner_id;
      }

      const fairOdds = this.getFairOdds(matchId, market, outcome, handicap);
      const value = calculateValue(actualOdds, fairOdds, true);
      const recommendedStake = fairOdds ? this.calculateKellyStake(fairOdds, actualOdds, this.bankroll, this.stakeRounding) : 'N/A';

      this.selectedOdds = {
        ...this.selectedOdds,
        bookie: actualBookie,
        odds: actualOdds.toFixed(3),
        fairOdds: fairOdds ? fairOdds.toFixed(3) : 'N/A',
        value,
        recommendedStake,
        panosraja,
        betfairMarketId,
        betfairRunnerId
      };
    },
    handleBookieChange() {
      this.showToastMessage('Muutokset tulevat voimaan sivun päivityksen jälkeen', 'info', 5000);
    },
    clearAllData() {
      if (confirm('Haluatko varmasti tyhjentää kaiken tallennetun datan? T��tä toimintoa ei voi peruuttaa.')) {
        localStorage.clear();
        this.playedMatches = {};
        this.hiddenMatches = {};
        this.oddsHistory = {};
        this.betfairSessionToken = '';
        this.betfairLoginStatus = false;
        this.showToastMessage('Kaikki data tyhjennetty', 'success', 3000);
      }
    },
    getValueGradientStyle(ratio) {
      const numValue = parseFloat(ratio);
      if (isNaN(numValue)) return {};
      
      const percentage = (numValue - 1) * 100;

      const clampedValue = Math.max(-6, Math.min(6, percentage));
      
      const normalizedValue = (clampedValue + 6) / 12;

      const red = Math.round(255 * (1 - normalizedValue));
      const green = Math.round(255 * normalizedValue);
      
      return {
        color: `rgb(${red}, ${green}, 0)`,
        fontWeight: 'bold'
      };
    },
    showOddsDetailsFromBestValue(match) {
      if (match.bestValueDetails) {
        this.handleOddsClick(
          match.common_id,
          match.bestValueDetails.market,
          match.bestValueDetails.outcome,
          match.bestValueDetails.bookie,
          match.bestValueDetails.odds,
          match.bestValueDetails.handicap
        );
      }
    },
    resetCalculationParams() {
      if (confirm('Haluatko varmasti palauttaa oletusasetukset?')) {
        this.calculationParams = {
          stakeLimit: {
            min: 50,
            max: 450,
            multiplierMin: 1.02,
            multiplierMax: 1.00
          },
          margin: {
            min: 5,
            max: 9,
            multiplierMin: 1,
            multiplierMax: 1.02
          },
          prediction: {
            maxAdjustment: 2,
            maxDifference: 9
          },
          movement: {
            timeWindow: 600,
            maxDownMultiplier: 0.995,
            maxUpMultiplier: 1.01
          }
        };
        this.calculationParamsChanged = true;
      }
    },
    handleLoginSuccess(userData) {
      this.isAuthenticated = true;
      this.userData = userData;
      this.initializeData();
    },
    initializeData() {
      this.initializeSocketConnection();
    },
    fetchInitialData() {
      if (!this.isAuthenticated) {
        console.warn('Attempted to fetch initial data without authentication');
        return;
      }

      if (this.socket && this.socket.connected) {
        console.log("Requesting initial data");
        this.socket.emit("request_initial_data");
      } else {
        console.log("Socket not connected, waiting...");
        setTimeout(() => this.fetchInitialData(), 100);
      }
    },
    handleTooManyAttempts() {
      localStorage.removeItem('userData');
      sessionStorage.removeItem('userData');
      
      this.isAuthenticated = false;
      this.userData = null;

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    },
    handleAuthError() {
      localStorage.removeItem('userData');
      sessionStorage.removeItem('userData');
      
      this.isAuthenticated = false;
      this.userData = null;

      window.location.reload();
    },
    handleSessionExpired() {
      localStorage.setItem('login_error', 'Liian monta samanaikaista kirjautumista.');

      localStorage.removeItem('userData');
      sessionStorage.removeItem('userData');
      
      this.isAuthenticated = false;
      this.userData = null;

      window.location.reload();
    },
    async savePredictionChanges() {
      this.savingPrediction = true;

      if (this.hasAdjustments && !this.predictionData.reason) {
        this.showToastMessage('Syy on pakollinen kun muutoksia on tehty', 'error');
        this.savingPrediction = false;
        return;
      }

      if (!this.isValidForm) {
        this.showToastMessage('Tarkista syött��mäsi arvot', 'error');
        this.savingPrediction = false;
        return;
      }

      try {
        const response = await fetch(`https://avai.fi/predictions/edit_prediction?token=318860`, {  // For productions use: https://avai.fi/predictions/edit_prediction?token=318860 for development use: http://127.0.0.1:5347/predictions/edit_prediction?token=318860
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userData.token}`
          },
          body: JSON.stringify({
            ID: this.predictionData.ID,
            home_adjustment: this.predictionData.home_adjustment,
            away_adjustment: this.predictionData.away_adjustment,
            rho: this.predictionData.rho,
            reason: this.predictionData.reason
          })
        });

        if (!response.ok) {
          throw new Error('Failed to save prediction changes');
        }
        this.showToastMessage('Arvio päivitetty onnistuneesti. Muutos tulee voimaan minuutin sisällä.', 'success', 3000);
      this.showPredictionEditor = false;
      } catch (error) {
        this.showToastMessage('Virhe ennusteen tallennuksessa: ' + error.message, 'error', 5000);
      } finally {
        this.savingPrediction = false;
      }
    },
    async recalculatePredictions() {
      this.isRecalculating = true;
      try {
        const response = await fetch(`https://avai.fi/predictions/recalculate?token=318860`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.userData.token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to recalculate predictions');
        }

        this.showToastMessage('Ennusteiden laskenta aloitettu', 'success');
      } catch (error) {
        this.showToastMessage('Virhe ennusteiden laskennassa: ' + error.message, 'error');
      } finally {
        this.isRecalculating = false;
      }
    },
    async runTrain() {
      this.isTraining = true;
      try {
        const response = await fetch(`https://avai.fi/predictions/run_train?token=318860`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.userData.token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to run training');
        }

        this.showToastMessage('Mallin koulutus aloitettu', 'success');
      } catch (error) {
        this.showToastMessage('Virhe mallin koulutuksessa: ' + error.message, 'error');
      } finally {
        this.isTraining = false;
      }
    },
    getPredictionOdds(matchId, market, outcome, handicap = null) {
      const match = this.nestedMatches[matchId];
      if (!match || !match.odds[market]) return null;

      if (handicap !== null) {
        return match.odds[market][outcome]?.[handicap]?.prediction || null;
      }
      return match.odds[market][outcome]?.prediction || null;
    },
    formatAnalysis(analysis) {
      if (!analysis || typeof analysis !== 'object') return '';
      return analysis.text.replace(/\n/g, '<br>');
    },
    async getMatchAnalysis(match) {
      if (this.loadingAnalysis) return;
      
      this.loadingAnalysis = true;
      try {
        const formattedOdds = Object.entries(match.odds).map(([Markkina, data]) => {
          if (Markkina === 'Aasialainen tasoitus' || Markkina === 'Yli/Alle') {
            const rows = this.generatePairedHandicapRows(data, Markkina, match.common_id);
            return {
              Markkina,
              Lopputulokset: rows.map(row => {

                const leftData = data[row.leftLabel]?.[row.handicap] || {};
                const rightData = data[row.rightLabel]?.[row.correspondingHandicap] || {};

                return {
                  Lopputulos: {
                    [`${row.leftLabel} ${row.handicap}`]: {
                      Kertoimet: {
                        pinnacle: leftData.pinnacle || 'N/A',
                        betfair: leftData.betfair || 'N/A',
                        nubet: leftData.nubet || 'N/A',
                        veikkaus: leftData.veikkaus || 'N/A',
                        oma_arvio: leftData.prediction || 'N/A'
                      },
                      value: row.leftValue ? `${parseFloat(row.leftValue).toFixed(1)}%` : 'N/A'
                    },
                    [`${row.rightLabel} ${row.correspondingHandicap}`]: {
                      Kertoimet: {
                        pinnacle: rightData.pinnacle || 'N/A',
                        betfair: rightData.betfair || 'N/A',
                        nubet: rightData.nubet || 'N/A',
                        veikkaus: rightData.veikkaus || 'N/A',
                        prediction: rightData.prediction || 'N/A'
                      },
                      value: row.rightValue ? `${parseFloat(row.rightValue).toFixed(1)}%` : 'N/A'
                    }
                  },
                };
              })
            };
          } else {
            return {
              Markkina,
              Lopputulokset: Object.entries(data).map(([outcome, oddsData]) => ({
                Lopputulos: outcome,
                Kertoimet: {
                  pinnacle: oddsData.pinnacle || 'N/A',
                  betfair: oddsData.betfair || 'N/A',
                  nubet: oddsData.nubet || 'N/A',
                  veikkaus: oddsData.veikkaus || 'N/A',
                  oma_arvio: oddsData.prediction || 'N/A'
                },
                value: this.getOddsAndValue(oddsData, outcome, data, match.common_id).value ?
                  `${parseFloat(this.getOddsAndValue(oddsData, outcome, data, match.common_id).value).toFixed(1)}%` : 'N/A'
              })),
            };
          }
        });

        const baseUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
          ? 'http://127.0.0.1:5364'
          : 'https://avai.fi';

        console.log(formattedOdds);
        const response = await fetch(`${baseUrl}/analyze_match`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            home: match.home,
            away: match.away,
            start_time: match.start_time,
            sport: match.sport,
            league: match.league,
            odds: formattedOdds,
            max_payout: `${parseFloat(this.getMaxPayout(match)).toFixed(1)}%`
          })
        });

        if (!response.ok) {
          throw new Error('Analysis request failed');
        }

        const data = await response.json();
        const now = new Date().toLocaleString('fi-FI', { 
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });

        this.matchAnalysis = {
          ...this.matchAnalysis,
          [match.common_id]: {
            text: data.analysis,
            isCollapsed: false, 
            timestamp: now
          }
        };
      } catch (error) {
        console.error('Error getting match analysis:', error);
        this.showToastMessage('Tietojen haku epäonnistui', 'error');
      } finally {
        this.loadingAnalysis = false;
      }
    },
    calculateAdjustedHomePred() {
      return this.predictionData.home_pred * (1 + (this.predictionData.home_adjustment || 0));
    },
    calculateAdjustedAwayPred() {
      return this.predictionData.away_pred * (1 + (this.predictionData.away_adjustment || 0));
    },
    getSecondsAgoText(timestamp) {
      const seconds = Math.floor((Date.now() - timestamp) / 1000);
      return `${seconds}s`;
    },
    updateValueLastChanged(matchId) {
      this.valueLastChanged[matchId] = Date.now();
    },
    getMinutesAgo(timestamp) {
      const now = Date.now();
      const diff = now - timestamp;
      return Math.floor(diff / 60000);
    },
    getMinutesAgoText(timestamp) {
      const minutes = this.getMinutesAgo(timestamp);
      return `${minutes}m`;
    },
    reloadPage() {
      window.location.reload();
    },
    setupLazyLoading() {
      const options = {
        root: null,
        rootMargin: '50px',
        threshold: 0.1
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const matchId = entry.target.dataset.matchId;
            this.loadMatchDetails(matchId);
          }
        });
      }, options);

      // Observe match elements
      this.$nextTick(() => {
        document.querySelectorAll('.match-item').forEach(el => {
          observer.observe(el);
        });
      });
    },
    handleValueToggle(type) {
      if ((type === 'usePinnacleValues' && this.usePinnacleValues) || 
          (type === 'useBetfairValues' && this.useBetfairValues)) {
        this.showToastMessage('Tämä ei ole suositeltavaa! Muutokset tulevat voimaan sivun päivityksen jälkeen.', 'warning', 7500);
      } else {
        this.showToastMessage('Muutokset tulevat voimaan sivun päivityksen jälkeen.', 'info', 5000);
      }
    },
    handleVisibilityChange() {
      if (document.hidden) {
        console.log('Page hidden - pausing updates');
        this.pauseUpdates();
      } else {
        console.log('Page visible - reloading');
        window.location.reload();
      }
    },
    pauseUpdates() {
      if (this.socket) {
        this.socket.disconnect();
      }

      if (this.cleanupInterval) {
        clearInterval(this.cleanupInterval);
      }
      if (this.oddsHistoryUpdateInterval) {
        clearInterval(this.oddsHistoryUpdateInterval);
      }
      if (this.oddsDetailsUpdateInterval) {
        clearInterval(this.oddsDetailsUpdateInterval);
      }
      if (this.valueUpdateInterval) {
        clearInterval(this.valueUpdateInterval);
      }
      if (this.betfairKeepAliveInterval) {
        clearInterval(this.betfairKeepAliveInterval);
      }

      this.isConnected = false;
    },
    getOppositeOutcome(outcome) {
      const opposites = {
        'Yli': 'Alle',
        'Alle': 'Yli',
        'Koti': 'Vieras',
        'Vieras': 'Koti'
      };
      return opposites[outcome];
    },
    addToTracking(selectedOdds) {
      // Store the selected odds for later use
      this.trackingOdds = selectedOdds;
      
      // Set default values
      this.trackingForm.stake = selectedOdds.recommendedStake;
      this.trackingForm.odds = selectedOdds.odds;
      
      // Show the modal
      this.showTrackingModal = true;
    },
    async confirmTracking() {
        try {
            // Get the match details
            const match = this.nestedMatches.find(m => m.common_id === this.trackingOdds.matchId);
            
            if (!match.start_time) {
                throw new Error('Invalid match start time');
            }

            // Convert matchId to a string
            const matchId = this.trackingOdds.matchId.toString();

            // Create tracking object with all relevant data
            const trackingData = {
                matchId: matchId,
                pinnacleId: match.bookieIds?.pinnacle || null,
                teams: {
                    home: match.home,
                    away: match.away
                },
                startTime: match.start_time,
                sport: match.sport || '',
                league: match.league || '',
                market: this.trackingOdds.market || '',
                outcome: this.trackingOdds.outcome || null,
                handicap: this.trackingOdds.handicap || null,
                odds: parseFloat(this.trackingForm.odds),
                bookie: this.trackingOdds.bookie || '',
                stake: parseFloat(this.trackingForm.stake),
                fairOdds: parseFloat(this.trackingOdds.fairOdds) || parseFloat(this.trackingForm.odds),
                placement_fairOdds: parseFloat(this.trackingOdds.fairOdds) || parseFloat(this.trackingForm.odds),
                placement_timestamp: new Date().toISOString(),
                expected_profit: 0
            };

            // Log the data being sent for debugging
            console.log('Sending tracking data:', trackingData);

            // Send bet to tracker server
            await sendBetToTracker(trackingData);

            this.showToastMessage('Lisätty seurantaan onnistuneesti', 'success');
            this.showTrackingModal = false;
        } catch (error) {
            console.error('Error adding bet to tracking:', error);
            this.showToastMessage('Virhe lisättäessä seurantaan: ' + error.message, 'error');
        }
    },
    refreshTrackingData() {
      const trackingPage = this.$refs.trackingPage;
      if (trackingPage) {
        // Don't show success message here, wait for the event
        trackingPage.refreshData();
      } else {
        console.error('TrackingPage reference not found');
        this.showToastMessage('Virhe päivityksessä', 'error');
      }
    },
    handleRefreshComplete({ success, error }) {
      if (success) {
        console.log('Seuranta päivitetty');
      } else {
        this.showToastMessage(`Virhe päivityksessä: ${error}`, 'error', 5000);
      }
    },
    async handleLogout() {
      try {
        const token = this.userData?.token;
        if (!token) {
          throw new Error('No authentication token found');
        }

        // Get base URL based on hostname
        const hostname = window.location.hostname;
        const baseUrl = hostname === 'localhost' || hostname === '127.0.0.1'
          ? 'http://127.0.0.1:5364' 
          : 'https://avai.fi';

        // Call logout endpoint
        const response = await fetch(`${baseUrl}/logout`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Logout failed');
        }

        // Clear user data from storage
        localStorage.removeItem('userData');
        sessionStorage.removeItem('userData');
        
        // Reset application state
        this.isAuthenticated = false;
        this.userData = null;

        // Show success message
        this.showToastMessage('Kirjauduttu ulos onnistuneesti', 'success');

        // Close settings dialog if open
        this.showSettings = false;

        // Reload the page to ensure clean state
        window.location.reload();

      } catch (error) {
        console.error('Logout error:', error);
        this.showToastMessage('Uloskirjautuminen epäonnistui: ' + error.message, 'error');
      }
    },
    getOppositeHandicap(handicap) {
      const numericValue = parseFloat(handicap);
      return (-numericValue).toString();
    },
    async loadTeamMappings() {
      try {
        if (!this.userData?.token) {
          throw new Error('No authentication token found');
        }

        const hostname = window.location.hostname;
        const baseUrl = hostname === 'localhost' || hostname === '127.0.0.1'
          ? 'http://127.0.0.1:5364'
          : 'https://avai.fi';

        const response = await fetch(`${baseUrl}/team-mappings`, {
          headers: {
            'Authorization': `Bearer ${this.userData.token}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to load team mappings');
        }
        
        const data = await response.json();
        this.teamMappings = data.mappings.join('\n');
      } catch (error) {
        console.error('Error loading team mappings:', error);
        this.showToastMessage('Virhe joukkuenimien lataamisessa', 'error');
      }
    },
    async saveTeamMappings() {
      this.savingMappings = true;
      try {
        if (!this.userData?.token) {
          throw new Error('No authentication token found');
        }

        const hostname = window.location.hostname;
        const baseUrl = hostname === 'localhost' || hostname === '127.0.0.1'
          ? 'http://127.0.0.1:5364'
          : 'https://avai.fi';

        const mappings = this.teamMappings
          .split('\n')
          .map(line => line.trim())
          .filter(line => line && line.includes('='));

        const response = await fetch(`${baseUrl}/team-mappings`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.userData.token}`
          },
          body: JSON.stringify({ mappings })
        });

        if (!response.ok) {
          throw new Error('Failed to save team mappings');
        }

        this.showToastMessage('Joukkuenimet tallennettu', 'success');
        this.showTeamMappingsEditor = false;  // Close the dialog after successful save
      } catch (error) {
        console.error('Error saving team mappings:', error);
        this.showToastMessage('Virhe joukkuenimien tallentamisessa', 'error');
      } finally {
        this.savingMappings = false;
      }
    },
    getToken() {
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      return userData.token || '';
    },
    isFinnishLeague(league) {
      if (!league) return false;
      const finnishKeywords = ['Finland', 'Suomi', 'Finnish', 'Veikkausliiga', 'Liigacup', 'Kansallinen liiga', 'Kakkonen'];
      return finnishKeywords.some(keyword => {
        if (keyword === 'Liigacup') {
          return league.toLowerCase() === keyword.toLowerCase();
        }
        return league.toLowerCase().includes(keyword.toLowerCase());
      });
    },
  },
};
</script>

