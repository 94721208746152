<template>
  <v-card>
    <v-toolbar density="compact">
      <v-toolbar-title>Live Events</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch
        v-model="showCompleted"
        label="Näytä päättyneet"
        density="compact"
        hide-details
        class="mr-4"
      ></v-switch>
      <v-btn icon @click="refreshData" density="compact" size="small">
        <v-icon size="small">mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <div v-if="loading" class="d-flex justify-center align-center pa-4">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>

      <div v-else-if="error" class="text-center pa-4 text-error">
        {{ error }}
      </div>

      <div v-else>
        <v-list>
          <!-- Active matches -->
          <v-list-item
            v-for="event in activeMatches"
            :key="event.event_id"
            class="mb-2"
          >
            <v-card width="100%" variant="outlined">
              <v-card-text>
                <div class="d-flex justify-space-between align-center mb-2">
                  <div class="text-subtitle-2">{{ event.competition }}</div>
                  <v-chip
                    size="small"
                    :color="getStatusColor(event.minutes)"
                    class="ml-2"
                  >
                    {{ event.minutes }}'
                  </v-chip>
                </div>

                <div class="team-row d-flex align-center justify-space-between mb-1">
                  <span class="team-name">{{ event.home_team }}</span>
                  <div class="d-flex align-center">
                    <span class="score mr-2">{{ event.home_score }}</span>
                    <span class="xg">
                      (xG: {{ formatXG(event.model_data.xg.home) }} | 
                      jäljellä: {{ formatXG(getRemainingXG(event.model_data.xg.home, event.minutes)) }})
                    </span>
                  </div>
                </div>

                <div class="team-row d-flex align-center justify-space-between">
                  <span class="team-name">{{ event.away_team }}</span>
                  <div class="d-flex align-center">
                    <span class="score mr-2">{{ event.away_score }}</span>
                    <span class="xg">
                      (xG: {{ formatXG(event.model_data.xg.away) }} | 
                      jäljellä: {{ formatXG(getRemainingXG(event.model_data.xg.away, event.minutes)) }})
                    </span>
                  </div>
                </div>

                <div class="odds-row d-flex justify-space-between mt-2">
                  <span class="odds-label">Kertoimet:</span>
                  <span class="odds-values">
                    1: {{ formatOdds(getMatchOdds(event).match.H) }} |
                    X: {{ formatOdds(getMatchOdds(event).match.D) }} |
                    2: {{ formatOdds(getMatchOdds(event).match.A) }}
                  </span>
                </div>
                
                <v-expand-transition>
                  <div v-if="selectedMatch === event.event_id">
                    <v-divider class="my-2"></v-divider>
                    
                    <div class="additional-odds">
                      <div class="handicap-odds mb-2">
                        <div class="odds-subtitle">Tasoitus:</div>
                        <div v-for="line in sortedHandicapLines(getMatchOdds(event).handicaps)" :key="line"
                          class="odds-line d-flex justify-space-between">
                          <span>{{ formatHandicapLine(line) }}</span>
                          <span>
                            {{ formatOdds(getMatchOdds(event).handicaps[line].home) }} | {{ formatOdds(getMatchOdds(event).handicaps[line].away) }}
                          </span>
                        </div>
                      </div>
                      
                      <div class="totals-odds">
                        <div class="odds-subtitle">Maalimäärä:</div>
                        <div v-for="line in sortedTotalLines(getMatchOdds(event).totals)" :key="line"
                          class="odds-line d-flex justify-space-between">
                          <span>{{ line }}</span>
                          <span>
                            yli: {{ formatOdds(getMatchOdds(event).totals[line].over) }} | alle: {{ formatOdds(getMatchOdds(event).totals[line].under) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expand-transition>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  block
                  variant="text"
                  @click="toggleMatchDetails(event.event_id)"
                >
                  {{ selectedMatch === event.event_id ? 'Piilota' : 'Näytä lisää' }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-list-item>

          <!-- Completed matches -->
          <template v-if="showCompleted && completedMatches.length > 0">
            <v-divider class="my-4"></v-divider>
            <div class="text-subtitle-1 mb-2 px-2">Päättyneet ottelut</div>
            
            <v-list-item
              v-for="event in completedMatches"
              :key="event.event_id"
              class="mb-2"
            >
              <v-card width="100%" variant="outlined" color="grey-lighten-3">
                <v-card-text>
                  <div class="d-flex justify-space-between align-center mb-2">
                    <div class="text-subtitle-2">{{ event.competition }}</div>
                    <v-chip
                      size="small"
                      color="grey"
                      class="ml-2"
                    >
                      Päättynyt
                    </v-chip>
                  </div>

                  <div class="team-row d-flex align-center justify-space-between mb-1">
                    <span class="team-name">{{ event.home_team }}</span>
                    <div class="d-flex align-center">
                      <span class="score mr-2">{{ event.home_score }}</span>
                      <span class="xg">
                        (xG: {{ formatXG(event.model_data.xg.home) }} | 
                        jäljellä: {{ formatXG(getRemainingXG(event.model_data.xg.home, event.minutes)) }})
                      </span>
                    </div>
                  </div>

                  <div class="team-row d-flex align-center justify-space-between">
                    <span class="team-name">{{ event.away_team }}</span>
                    <div class="d-flex align-center">
                      <span class="score mr-2">{{ event.away_score }}</span>
                      <span class="xg">
                        (xG: {{ formatXG(event.model_data.xg.away) }} | 
                        jäljellä: {{ formatXG(getRemainingXG(event.model_data.xg.away, event.minutes)) }})
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { calculateAllOdds } from '../utils/probabilityCalculations';

export default {
  name: 'LiveEvents',
  
  data() {
    return {
      liveEvents: [],
      loading: false,
      error: null,
      refreshInterval: null,
      showCompleted: false,
      selectedMatch: null
    }
  },

  computed: {
    activeMatches() {
      return this.liveEvents
        .filter(event => !this.isMatchCompleted(event))
        .sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
    },
    completedMatches() {
      return this.liveEvents
        .filter(event => this.isMatchCompleted(event))
        .sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
    },
    sortedHandicapLines() {
      return (handicaps) => Object.keys(handicaps).map(Number).sort((a, b) => a - b);
    },
    sortedTotalLines() {
      return (totals) => Object.keys(totals).map(Number).sort((a, b) => a - b);
    }
  },

  methods: {
    formatXG(xgArray) {
      // If input is an array, get the last value
      if (Array.isArray(xgArray)) {
        if (!xgArray.length) return '0.00';
        return xgArray[xgArray.length - 1].toFixed(2);
      }
      // If input is a number, format it directly
      if (typeof xgArray === 'number') {
        return xgArray.toFixed(2);
      }
      return '0.00';
    },

    getStatusColor(minutes) {
      const mins = parseInt(minutes);
      if (mins <= 15) return 'green';
      if (mins <= 33) return 'light-green';
      if (mins <= 66) return 'orange';
      return 'red';
    },

    isMatchCompleted(event) {
      const startTime = new Date(event.start_time);
      const now = new Date();
      const hoursSinceStart = (now - startTime) / (1000 * 60 * 60);
      const minutes = parseInt(event.minutes);
      
      return hoursSinceStart > 0 && minutes >= 89;
    },

    async fetchData(isInitialLoad = false) {
      if (isInitialLoad) {
        this.loading = true;
      }
      
      try {
        const response = await fetch('https://vedonlyoja.com/data/live/live_events_web.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        const newEvents = Object.values(data);
        
        // Clear error if successful
        this.error = null;
        
        // Create a new array with updated events
        const updatedEvents = [...this.liveEvents];
        
        // Update or add new events
        newEvents.forEach(newEvent => {
          const existingEventIndex = updatedEvents.findIndex(e => e.event_id === newEvent.event_id);
          if (existingEventIndex !== -1) {
            // Replace the entire event object to ensure reactivity
            updatedEvents[existingEventIndex] = { ...newEvent };
          } else {
            updatedEvents.push(newEvent);
          }
        });
        
        // Filter out events that no longer exist
        const finalEvents = updatedEvents.filter(event => 
          newEvents.some(newEvent => newEvent.event_id === event.event_id)
        );
        
        // Replace the entire array to trigger reactivity
        this.liveEvents = finalEvents;
        
      } catch (error) {
        console.error('Error fetching live events:', error);
        if (isInitialLoad || this.liveEvents.length === 0) {
          this.error = 'Failed to load live events';
        }
      } finally {
        if (isInitialLoad) {
          this.loading = false;
        }
      }
    },

    async refreshData() {
      try {
        await this.fetchData(false);
      } catch (error) {
        console.error('Error during refresh:', error);
      }
    },

    startAutoRefresh() {
      this.refreshInterval = setInterval(() => {
        this.refreshData();
      }, 30000);
    },

    stopAutoRefresh() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
      }
    },
    getRemainingXG(xgArray, minutes) {
      const currentXG = xgArray[xgArray.length - 1];
      const mins = parseInt(minutes) || 0;
      const remainingPortion = Math.max(0, mins / 94);
      if (mins < 80) {
        return currentXG * (1 - remainingPortion);
      } else {
        return 0;
      }
    },
    getMatchOdds(event) {
      const mins = parseInt(event.minutes) || 0;
      if (mins >= 80) {
        return {
          match: { H: null, D: null, A: null },
          handicaps: {},
          totals: {}
        };
      }

      const homeXG = this.getRemainingXG(event.model_data.xg.home, event.minutes);
      const awayXG = this.getRemainingXG(event.model_data.xg.away, event.minutes);
      const homePred = homeXG;
      const awayPred = awayXG;
      const odds = calculateAllOdds(homePred, awayPred, event.home_score, event.away_score);

      // Filter match odds
      const filteredMatch = Object.fromEntries(
        Object.entries(odds.match).map(([key, value]) => [
          key,
          value && value >= 1.0 && value <= 1000 ? value : null
        ])
      );

      // Filter handicap odds
      const filteredHandicaps = {};
      Object.entries(odds.handicaps).forEach(([line, values]) => {
        const filteredLine = {
          home: values.home && values.home >= 1.1 && values.home <= 11 ? values.home : null,
          away: values.away && values.away >= 1.1 && values.away <= 11 ? values.away : null
        };
        // Only include the line if at least one value is valid
        if (filteredLine.home || filteredLine.away) {
          filteredHandicaps[line] = filteredLine;
        }
      });

      // Filter total odds
      const filteredTotals = {};
      Object.entries(odds.totals).forEach(([line, values]) => {
        const filteredLine = {
          over: values.over && values.over >= 1.1 && values.over <= 11 ? values.over : null,
          under: values.under && values.under >= 1.1 && values.under <= 11 ? values.under : null
        };
        // Only include the line if at least one value is valid
        if (filteredLine.over || filteredLine.under) {
          filteredTotals[line] = filteredLine;
        }
      });

      return {
        match: filteredMatch,
        handicaps: filteredHandicaps,
        totals: filteredTotals
      };
    },
    formatOdds(odds) {
      return odds ? odds.toFixed(2) : '-';
    },
    toggleMatchDetails(eventId) {
      this.selectedMatch = this.selectedMatch === eventId ? null : eventId;
    },
    formatHandicapLine(line) {
      return line > 0 ? `+${line}` : line;
    }
  },

  mounted() {
    // Initial load with loading state
    this.fetchData(true);
    this.startAutoRefresh();
  },

  beforeUnmount() {
    this.stopAutoRefresh();
  }
}
</script>

<style scoped>
.team-name {
  font-weight: 500;
}

.score {
  font-weight: bold;
  font-size: 1.1em;
}

.xg {
  color: #666;
  font-size: 0.9em;
}

.odds-row {
  font-size: 0.9em;
  color: #666;
}

.odds-label {
  font-weight: 500;
}

.additional-odds {
  padding: 8px;
}

.odds-subtitle {
  font-weight: 500;
  margin-bottom: 4px;
  color: #666;
}

.odds-line {
  font-size: 0.9em;
  padding: 2px 0;
}
</style> 