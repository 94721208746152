<template>
  <div class="tracking-wrapper">
    <v-card class="tracking-card" flat>
      <v-card-text class="pa-2 d-flex flex-column" style="height: 100%">
        <!-- Stats Section -->
        <v-row v-if="!isLoading" class="mb-0 mx-0 flex-grow-0" dense>
          <v-col v-for="(stat, index) in displayedStats" 
            :key="index" 
            cols="4"
            sm="3"
            md="2"
            lg="2"
            class="stat-col"
          >
            <v-card elevation="0" :color="stat.color" class="stat-card" density="compact">
              <v-card-text class="stat-content py-0 px-1">
                <div class="text-caption text-medium-emphasis text-caption-small">{{ stat.title }}</div>
                <div class="text-caption font-weight-bold" :class="stat.textClass">
                  {{ stat.value }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mb-1 mx-0 flex-grow-0" dense>
          <v-col cols="12">
            <div class="d-flex flex-wrap gap-1">
              <v-menu v-if="$vuetify.display.smAndDown">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    prepend-icon="mdi-menu"
                  >
                    Toiminnot
                  </v-btn>
                  <v-btn
                    v-if="$vuetify.display.smAndDown"
                    @click="toggleStats"
                    density="compact"
                  >
                    {{ showAllStats ? 'Pienennä' : 'Lisää tilastoja' }}
                  </v-btn>
                </template>
                <v-list density="compact">
                  <v-list-item @click="expandAll">
                    <template v-slot:prepend>
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                    <v-list-item-title>Laajenna</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="collapseAll">
                    <template v-slot:prepend>
                      <v-icon>mdi-chevron-up</v-icon>
                    </template>
                    <v-list-item-title>Pienennä</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="showFilters = !showFilters">
                    <template v-slot:prepend>
                      <v-icon>mdi-filter</v-icon>
                    </template>
                    <v-list-item-title>Suodattimet</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="showChart" @click="toggleChart">
                    <template v-slot:prepend>
                      <v-icon>mdi-chart-line</v-icon>
                    </template>
                    <v-list-item-title>Kaavio</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="toggleSort('start_time')">
                    <template v-slot:prepend>
                      <v-icon>mdi-calendar</v-icon>
                    </template>
                    <v-list-item-title>Alku</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="toggleSort('placement_timestamp')">
                    <template v-slot:prepend>
                      <v-icon>mdi-clock-outline</v-icon>
                    </template>
                    <v-list-item-title>Pelattu</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <template v-else>
                <v-btn-group density="compact" class="mb-2 mr-2">
                  <v-btn
                    prepend-icon="mdi-chevron-down"
                    @click="expandAll"
                    :loading="isLoading"
                  >
                    Laajenna
                  </v-btn>
                  <v-btn
                    prepend-icon="mdi-chevron-up"
                    @click="collapseAll"
                    :loading="isLoading"
                  >
                    Pienennä
                  </v-btn>
                </v-btn-group>

                <v-btn-group density="compact" class="mb-2 mr-2">
                  <v-btn
                    prepend-icon="mdi-filter"
                    @click="showFilters = !showFilters"
                    :loading="isLoading"
                  >
                    Suodattimet
                    <v-icon end :icon="showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
                  </v-btn>
                  <v-btn
                    v-if="showChart"
                    prepend-icon="mdi-chart-line"
                    @click="toggleChart"
                    :loading="isLoading"
                  >
                    Kaavio
                    <v-icon end :icon="chartVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>
                  </v-btn>
                </v-btn-group>

                <v-btn-group density="compact" class="mb-2 mr-2">
                  <v-btn
                    prepend-icon="mdi-calendar"
                    :append-icon="getSortIcon('start_time')"
                    @click="toggleSort('start_time')"
                    :color="sortBy === 'start_time' ? 'default' : undefined"
                    :class="{ 'sort-active': sortBy === 'start_time' }"
                    :loading="isLoading"
                  >
                    Alku
                  </v-btn>
                  <v-btn
                    prepend-icon="mdi-clock-outline"
                    :append-icon="getSortIcon('placement_timestamp')"
                    @click="toggleSort('placement_timestamp')"
                    :color="sortBy === 'placement_timestamp' ? 'default' : undefined"
                    :class="{ 'sort-active': sortBy === 'placement_timestamp' }"
                    :loading="isLoading"
                  >
                    Pelattu
                  </v-btn>
                </v-btn-group>
              </template>
            </div>
          </v-col>
        </v-row>

        <!-- Only render the chart row when both conditions are met -->
        <template v-if="showChart && chartVisible">
          <v-row class="mb-1 mx-0 flex-grow-0">
            <v-col cols="12">
              <v-expansion-panels v-model="chartExpanded">
                <v-expansion-panel>

                  <v-expansion-panel-text>
                    <suspense>
                      <template #default>
                        <profit-chart 
                          :matches="matches" 
                          :key="chartKey"
                        />
                      </template>
                      <template #fallback>
                        <v-progress-circular indeterminate></v-progress-circular>
                      </template>
                    </suspense>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </template>

        <v-expand-transition>
          <v-row v-show="showFilters" class="mb-1 mx-0 flex-grow-0" dense>
            <v-col cols="12" sm="auto">
              <v-text-field
                v-model="searchQuery"
                label="Haku"
                prepend-inner-icon="mdi-magnify"
                density="compact"
                hide-details
                class="search-field"
                style="min-width:160px;"
                clearable
                @update:model-value="debouncedRefresh"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="auto">
              <v-text-field
                v-model="pageSize"
                label="Ottelumäärä"
                type="number"
                min="1"
                max="20000"
                density="compact"
                hide-details
                class="page-size-select"
                style="min-width: 160px;"
                @update:model-value="handlePageSizeChange"
              >
                <template v-slot:prepend-inner>
                  <v-icon size="x-small" class="mr-1">mdi-format-list-numbered</v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="auto">
              <v-select
                v-model="selectedBookie"
                :items="bookieOptions"
                label="Vedonvälittäjä"
                density="compact"
                hide-details
                clearable
                class="filter-select"
                style="min-width: 160px;"
                @update:model-value="handleFilterChange"
              >
                <template v-slot:prepend-inner>
                  <v-icon size="x-small" class="mr-1">mdi-office-building</v-icon>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="auto">
              <v-select
                v-model="selectedMarket" 
                :items="marketOptions"
                label="Markkina"
                density="compact"
                hide-details
                clearable
                class="filter-select"
                style="min-width: 160px;"
                @update:model-value="handleFilterChange"
              >
                <template v-slot:prepend-inner>
                  <v-icon size="x-small" class="mr-1">mdi-tag-multiple</v-icon>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="auto">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ props }">
                  <v-text-field
                    v-model="startDate"
                    label="Alkupvm"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="props"
                    density="compact"
                    hide-details
                    class="date-field"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @update:model-value="startDateMenu = false"
                  :max="endDate || undefined"
                  locale="fi-FI"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            
            <v-col cols="12" sm="auto">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ props }">
                  <v-text-field
                    v-model="endDate"
                    label="Loppupvm"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="props"
                    density="compact"
                    hide-details
                    class="date-field"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @update:model-value="endDateMenu = false"
                  :min="startDate || undefined"
                  locale="fi-FI"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            
            <v-col cols="12" sm="auto" class="d-flex align-center">
              <v-btn
                color="error"
                variant="text"
                density="compact"
                @click="clearDateFilters"
                :disabled="!startDate && !endDate && !selectedBookie && !selectedMarket"
              >
                Tyhjennä suodattimet
              </v-btn>
            </v-col>
          </v-row>
        </v-expand-transition>

        <!-- Loading State -->
        <div v-if="isLoading" class="d-flex justify-center align-center pa-4">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        
        <!-- Matches List -->
        <div v-show="!isLoading" class="matches-container flex-grow-1">
          <v-virtual-scroll
            :items="sortedMatches"
            height="100%"
            v-bind="virtualScrollConfig"
            :key="virtualScrollKey"
          >
            <template v-slot:default="{ item }">
              <v-expansion-panels
                v-model="item.expanded"
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    <div class="d-flex align-center justify-space-between w-100">
                      <div class="d-flex align-center">
                        <v-chip
                          :color="getMatchStatusColor(item)"
                          size="x-small"
                          class="status-chip"
                          :class="{'live-chip': item.match_status === 'live'}"
                          @click.stop="openEditMatch(item)"
                          style="cursor: pointer;"
                        >
                          {{ statusTranslations[item.match_status] || item.match_status }}
                        </v-chip>
                        <div>
                          <span class="team-name font-weight-medium">{{ item.home_team }}</span>
                          <span v-if="['live', 'settled'].includes(item.match_status)" 
                            :class="['mx-2', 'match-score', { 'live-score': item.match_status === 'live' }]"
                            @click.stop="openEditMatch(item)"
                            style="cursor: pointer;"
                          >
                            {{ item.home_score }} - {{ item.away_score }}
                          </span>
                          <span v-else class="mx-2 match-score">vs</span>
                          <span class="team-name font-weight-medium">{{ item.away_team }}</span>
                          <v-tooltip v-if="isMatchDelayed(item)" location="top">
                            <template v-slot:activator="{ props }">
                              <v-icon
                                v-bind="props"
                                color="warning"
                                size="small"
                                class="ml-1"
                                icon="mdi-clock-alert"
                              ></v-icon>
                            </template>
                            Lopputulos puuttuu
                          </v-tooltip>
                          <div class="match-info text-body-2 text-medium-emphasis mt-1">
                            <span class="match-date">{{ formatDate(item.start_time) }}</span>
                            <span class="mx-1">|</span>
                            <span v-if="item.match_status === 'pending' || item.match_status === 'live'" 
                              class="match-countdown"
                            >
                              {{ getStartTimeInfo(item.start_time) }}
                              <span class="mx-1">|</span>
                            </span>
                            <span class="match-sport">{{ item.sport }}</span>
                            <span class="mx-1">|</span>
                            <span class="match-league">{{ item.league }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-right d-flex align-center">
                        <span 
                          v-if="!['pending', 'live'].includes(item.match_status)"
                          class="profit-gap" 
                          :class="getStatusColorClass(calculateMatchProfit(item))"
                        >
                          {{ calculateMatchProfit(item) }}
                        </span>
                        <span class="profit-gap" :class="getStatusColorClass(calculateMatchExpectedProfit(item))">
                          {{ calculateMatchExpectedProfit(item) }}
                        </span>
                      </div>
                    </div>
                  </v-expansion-panel-title>
                  
                  <!-- Lazy load bet details -->
                  <v-expansion-panel-text>
                    <suspense>
                      <template #default>
                        <bet-details-table 
                          :bets="item.bets"
                          :headers="visibleHeaders"
                          :match-status="item.match_status"
                          @edit-bet="startEdit"
                          @save-bet="saveBetEdit"
                          @cancel-edit="cancelEdit"
                          @delete-bet="deleteBet"
                          v-model:editing-bet="editingBet"
                          :result-options="resultOptions"
                        />
                      </template>
                      <template #fallback>
                        <v-progress-circular indeterminate></v-progress-circular>
                      </template>
                    </suspense>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-virtual-scroll>
        </div>
      </v-card-text>
    </v-card>

    <!-- Error State -->
    <div v-if="error" class="error-state pa-4 text-center">
      <v-alert type="error" class="mb-4">
        {{ error.message }}
      </v-alert>
      <v-btn color="primary" @click="refreshData">
        Yritä uudelleen
      </v-btn>
    </div>

    <!-- Add the dialog component after the error state div -->
    <v-dialog v-model="showEditMatchDialog" max-width="500px">
      <v-card>
        <v-card-title>Muokkaa tulosta</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="saveMatchEdit">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editMatchForm.home_score"
                  label="Koti"
                  type="number"
                  min="0"
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editMatchForm.away_score"
                  label="Vieras"
                  type="number"
                  min="0"
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="editMatchForm.status"
                  :items="matchStatusOptions"
                  item-title="title"
                  item-value="value"
                  label="Tila"
                  density="compact"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" variant="text" @click="showEditMatchDialog = false">
            Peruuta
          </v-btn>
          <v-btn color="primary" @click="saveMatchEdit">
            Tallenna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Add debounce utility function
function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
}

// Lazy load components
const BetDetailsTable = defineAsyncComponent(() => 
  import('./BetDetailsTable.vue')
)
const ProfitChart = defineAsyncComponent(() => 
  import('./ProfitChart.vue')
)

export default {
  name: 'TrackingPage',
  components: {
    BetDetailsTable,
    ProfitChart
  },
  props: {
    selectedStatus: {
      type: String,
      default: null
    },
    selectedSport: {
      type: String,
      default: null
    }
  },
  emits: [
    'update:selectedStatus', 
    'update:selectedSport', 
    'refresh-data',
    'refresh-complete'
  ],
  data() {
    return {
      matches: [],
      pageSize: '',
      isLoading: false,
      headers: [
        { title: 'Markkina', key: 'market' },
        { title: 'Valinta', key: 'outcome' },
        { title: 'Kerroin', key: 'odds' },
        { title: 'Panos', key: 'stake' },
        { title: 'Value', key: 'value' },
        { title: 'Tulos', key: 'status' },
        { title: 'Tuotto', key: 'profit' },
        { title: 'Odotettu', key: 'expected_profit' },
        { title: 'Voiton toden.', key: 'live_win_percentage' },
        { title: 'Vedonvälittäjä', key: 'bookie' },
        { title: 'Pelattu', key: 'placement_time' },
      ],
      editingBet: null,
      resultOptions: [
        { title: 'Avoin', value: null },
        { title: 'Voitto', value: 'Win' },
        { title: 'Häviö', value: 'Loss' },
        { title: 'Palautettu', value: 'Push' },
        { title: 'Puolikas voitto', value: 'Half Win' },
        { title: 'Puolikas häviö', value: 'Half Loss' }
      ],
      showEditMatchDialog: false,
      editMatchForm: {
        match_id: null,
        home_score: null,
        away_score: null,
        status: null
      },
      matchStatusOptions: [
        { title: 'Avoinna', value: 'pending' },
        { title: 'Käynnissä', value: 'live' },
        { title: 'Ratkennut', value: 'settled' },
        { title: 'Peruttu', value: 'cancelled' }
      ],
      memoizedColors: new Map(),
      ws: null,
      wsConnected: false,
      virtualScrollKey: 0,
      profitCache: {
        value: null,
        matchesLength: 0
      },
      statusColorCache: new Map(),
      apiStats: null,
      debounceTimer: null,
      pageSizeOptions: [
        { title: '100', value: 100 },
        { title: '1000', value: 1000 },
        { title: 'Kaikki', value: 20000 }
      ],
      expandedPanels: [],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      showFilters: false,
      chartVisible: false,
      chartExpanded: [0],
      sortBy: 'placement_timestamp',
      sortDesc: true,
      sortedMatchesCache: null,
      statsCache: null,
      isLoadingMore: false,
      hasMoreItems: true,
      error: null,
      retryCount: 0,
      maxRetries: 3,
      renderTimes: [],
      loadTimes: [],
      showAllStats: false,
      searchQuery: '',
      selectedBookie: null,
      selectedMarket: null,
      bookieOptions: [],
      marketOptions: [],
    }
  },
  computed: {
    processedMatches() {
      return this.matches.map(match => ({
        ...match,
        formattedDate: this.formatDate(match.start_time),
        statusColor: this.getMatchStatusColor(match),
        translatedStatus: this.statusTranslations[match.match_status] || match.match_status,
        bets: Array.isArray(match.bets) ? match.bets : []
      }))
    },
    totalProfit() {
      if (this.profitCache.value !== null && 
          this.profitCache.matchesLength === this.matches.length) {
        return this.profitCache.value
      }
      return this.calculateTotalProfit()
    },
    totalBets() {
      return (this.matches || []).reduce((sum, match) => {
        const bets = Array.isArray(match.bets) ? match.bets : [];
        return sum + bets.length;
      }, 0);
    },
    roi() {
      const totalStake = (this.matches || []).reduce((sum, match) => {
        const bets = Array.isArray(match.bets) ? match.bets : [];
        return sum + bets.reduce((betSum, bet) => betSum + (Number(bet.stake) || 0), 0);
      }, 0);
      return totalStake ? (this.totalProfit / totalStake) * 100 : 0;
    },
    averageValue() {
      const allBets = (this.matches || []).flatMap(match => {
        const bets = Array.isArray(match.bets) ? match.bets : [];
        return bets;
      });
      return allBets.length ? 
        allBets.reduce((sum, bet) => sum + (Number(bet.value) || 0), 0) / allBets.length : 0;
    },
    winRate() {
      const allBets = this.matches.flatMap(m => m.bets)
      const completedBets = allBets.filter(b => b.result)
      const wins = completedBets.filter(b => 
        ['Win', 'Half Win'].includes(b.result)
      ).length
      return completedBets.length ? (wins / completedBets.length) * 100 : 0
    },
    averageOdds() {
      const allBets = this.matches.flatMap(m => m.bets)
      return allBets.length ? 
        allBets.reduce((sum, bet) => sum + Number(bet.odds), 0) / allBets.length : 0
    },
    totalStakes() {
      return this.matches.reduce((sum, match) => {
        return sum + match.bets.reduce((betSum, bet) => betSum + Number(bet.stake), 0)
      }, 0)
    },
    averageStake() {
      const allBets = this.matches.flatMap(m => m.bets)
      return allBets.length ? this.totalStakes / allBets.length : 0
    },
    visibleHeaders() {
      const baseHeaders = [...this.headers]
      return baseHeaders
    },
    totalExpectedProfit() {
      return (this.matches || []).reduce((sum, match) => {
        const bets = Array.isArray(match.bets) ? match.bets : [];
        return sum + bets.reduce((betSum, bet) => {
          const expectedProfit = Number(bet.expected_profit);
          return betSum + (isNaN(expectedProfit) ? 0 : expectedProfit);
        }, 0);
      }, 0);
    },
    expectedRoi() {
      return this.totalStakes ? (this.totalExpectedProfit / this.totalStakes) * 100 : 0;
    },
    stats() {
      if (!this.apiStats) return [];
      
      // Calculate positive CLV percentage
      const positiveClvCount = this.matches.filter(match => {
        const expectedProfit = match.bets.reduce((sum, bet) => sum + (bet.expected_profit || 0), 0);
        return expectedProfit > 0;
      }).length;
      
      const positiveClvPercentage = this.matches.length ? 
        ((positiveClvCount / this.matches.length) * 100).toFixed(1) : 
        0;

      return [
        {
          title: 'Tuotto',
          value: `${this.apiStats.total_profit.toFixed(2)}€ (${this.apiStats.roi.toFixed(2)}%)`,
          color: '',
          textClass: this.apiStats.total_profit >= 0 ? 'text-success' : 'text-red-darken-3'
        },
        {
          title: 'Odotettu tuotto',
          value: `${this.apiStats.total_expected_profit.toFixed(2)}€ (${this.apiStats.expected_roi.toFixed(2)}%)`,
          color: '',
          textClass: this.apiStats.total_expected_profit >= 0 ? 'text-success' : 'text-red-darken-3'
        },
        {
          title: 'Voitto%',
          value: `${this.apiStats.win_rate.toFixed(1)}%`,
          color: '',
          textClass: ''
        },
        {
          title: 'Avoinna',
          value: `${this.apiStats.open_bets} vetoa I ${this.apiStats.open_stakes.toFixed(2)}€`,
          color: '',
          textClass: ''
        },
        {
          title: 'Pelattu',
          value: `${this.totalBets} vetoa I ${this.matches.length} ottelua`,
          color: '',
          textClass: ''
        },
        {
          title: 'Panostettu',
          value: `${this.apiStats.total_stakes.toFixed(0)}€`,
          color: '',
          textClass: ''
        },
        {
          title: 'Keskipanos/kerroin',
          value: `${(this.apiStats.total_stakes / this.totalBets).toFixed(2)}€ I ${this.apiStats.avg_odds.toFixed(2)}`,
          color: '',
          textClass: ''
        },
        {
          title: 'Positiivinen Odotettu Tuotto',
          value: `${positiveClvPercentage}%`,
          color: '',
          textClass: positiveClvPercentage >= 66 ? 'text-success' : 'text-red-darken-3'
        },
        {
          title: 'Tuotto/veto',
          value: `${(this.apiStats.total_profit / this.totalBets).toFixed(2)}€`,
          color: '',
          textClass: (this.apiStats.total_profit / this.totalBets) >= 0 ? 'text-success' : 'text-red-darken-3'
        },
        {
          title: 'Odotettu tuotto/veto',
          value: `${(this.apiStats.total_expected_profit / this.totalBets).toFixed(2)}€`,
          color: '',
          textClass: (this.apiStats.total_expected_profit / this.totalBets) >= 0 ? 'text-success' : 'text-red-darken-3'
        },
      ];
    },
    virtualScrollConfig() {
      return {
        itemHeight: 80,
        minItemHeight: 80,
        buffer: 5,
        overscan: 5,
        pageMode: true,
        updatePriority: 'performance'
      }
    },
    statusTranslations() {
      return {
        'settled': this.$vuetify.display.smAndDown ? 'R' : 'Ratkennut',
        'pending': this.$vuetify.display.smAndDown ? 'A' : 'Avoinna',
        'cancelled': this.$vuetify.display.smAndDown ? 'P' : 'Peruttu',
        'live': this.$vuetify.display.smAndDown ? 'K' : 'Käynnissä'
      }
    },
    resultTranslations() {
        return {
            'Win': 'Voitto',
            'Loss': 'Häviö',
            'Push': 'Palautettu',
            'Half Win': 'Puolikas Voitto',
            'Half Loss': 'Puolikas Häviö'
        };
    },
    showChart() {
      return !['live', 'pending'].includes(this.selectedStatus);
    },
    getStartTimeInfo() {
      return (startTime) => {
        const now = new Date();
        const start = new Date(startTime);
        const diffMs = start - now;
        
        // For pending matches - show time until start
        if (diffMs > 0) {
          const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
          const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
          
          if (diffHours > 0) {
            return `Alkuun: ${diffHours}h`;
          } else {
            return `Alkuun: ${diffMinutes}min`;
          }
        }
        
        // For started matches - show time since start
        const timeSinceStart = now - start;
        const hoursSinceStart = Math.floor(timeSinceStart / (1000 * 60 * 60));
        const minutesSinceStart = Math.floor((timeSinceStart % (1000 * 60 * 60)) / (1000 * 60));
        
        if (hoursSinceStart > 0) {
          return `Alkanut ${hoursSinceStart}h sitten`;
        } else {
          return `Alkanut ${minutesSinceStart}min sitten`;
        }
      };
    },
    sortedMatches() {
      if (!this.matches) return [];
      
      let filtered = [...this.matches];
      
      // Apply search filter
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(match => 
          match.home_team.toLowerCase().includes(query) ||
          match.away_team.toLowerCase().includes(query) ||
          match.league.toLowerCase().includes(query) ||
          match.sport.toLowerCase().includes(query) ||
          match.bets.some(bet => 
            bet.market.toLowerCase().includes(query) ||
            bet.outcome.toLowerCase().includes(query) ||
            bet.bookie.toLowerCase().includes(query)
          )
        );
      }
      
      // Existing sorting logic
      return filtered.sort((a, b) => {
        let aValue, bValue;
        
        switch (this.sortBy) {
          case 'start_time':
            aValue = new Date(a.start_time);
            bValue = new Date(b.start_time);
            break;
            
          case 'placement_timestamp':
            aValue = new Date(Math.max(...a.bets.map(bet => new Date(bet.placement_timestamp))));
            bValue = new Date(Math.max(...b.bets.map(bet => new Date(bet.placement_timestamp))));
            break;
            
          case 'profit':
            aValue = a.bets.reduce((sum, bet) => sum + (bet.profit || 0), 0);
            bValue = b.bets.reduce((sum, bet) => sum + (bet.profit || 0), 0);
            break;
            
          case 'expected_profit':
            aValue = a.bets.reduce((sum, bet) => sum + bet.expected_profit, 0);
            bValue = b.bets.reduce((sum, bet) => sum + bet.expected_profit, 0);
            break;
            
          default:
            return 0;
        }
        
        return this.sortDesc ? bValue - aValue : aValue - bValue;
      });
    },
    memoizedStats() {
      return this.statsCache?.matchesLength === this.matches.length && 
             this.statsCache?.apiStats === this.apiStats
        ? this.statsCache.value
        : this.stats;
    },
    mobileStats() {
      // Show fewer stats on mobile
      const importantStats = [
        'Tuotto',
        'Odotettu tuotto',
        'Voitto%',
        'Avoinna',
        'Pelattu',
        'Panostettu'
      ];
      
      if (this.$vuetify.display.smAndDown) {
        return this.stats.filter(stat => importantStats.includes(stat.title));
      }
      return this.stats;
    },
    displayedStats() {
      if (this.$vuetify.display.smAndDown && !this.showAllStats) {
        return this.mobileStats;
      }
      return this.stats;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';
      try {
        // Handle ISO format with timezone
        const dateObj = new Date(date.replace(' ', 'T'));
        return dateObj.toLocaleString('fi-FI', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
      } catch (e) {
        console.error('Error formatting date:', e);
        return '-';
      }
    },
    getStatusColor(bet) {
      if (!bet.result) return 'grey';
      switch (bet.result) {
        case 'Win': return 'success';
        case 'Loss': return 'red-darken-3';
        case 'Push': return 'blue';
        case 'Half Win': return 'light-green';
        case 'Half Loss': return 'deep-orange';
        default: return 'grey';
      }
    },
    getMatchStatusColor(match) {
      const key = `${match.match_id}-${match.match_status}-${match.bets?.length}`
      if (this.statusColorCache.has(key)) {
        return this.statusColorCache.get(key)
      }

      const color = this.calculateMatchStatusColor(match)
      this.statusColorCache.set(key, color)
      return color
    },
    calculateMatchStatusColor(match) {
      let color
      switch (match.match_status?.toLowerCase()) {
        case 'live': 
          color = 'grey'
          break
        case 'settled': {
          const results = (match.bets || [])
            .map(bet => bet.result)
            .filter(Boolean)
          
          if (!results.length) {
            color = 'grey'
            break
          }

          const resultCounts = {}
          let maxCount = 0
          let mostCommon = null

          for (const result of results) {
            resultCounts[result] = (resultCounts[result] || 0) + 1
            if (resultCounts[result] > maxCount) {
              maxCount = resultCounts[result]
              mostCommon = result
            }
          }

          color = this.getStatusColor({ result: mostCommon })
          break
        }
        case 'cancelled': 
          color = 'error'
          break
        default: 
          color = 'grey'
      }
      return color
    },
    debouncedRefresh: debounce(function() {
      this.refreshData();
    }, 1000),
    getApiBaseUrl() {
      const hostname = window.location.hostname;
      return hostname === 'localhost' || hostname === '127.0.0.1' 
        ? 'http://127.0.0.1:5360'
        : 'https://avai.fi';
    },
    async refreshData() {
      const startTime = performance.now();
      this.error = null;
      
      try {
        if (this.isLoading) return;
        
        try {
          this.isLoading = true;
          
          const token = this.getToken();
          if (!token) {
            throw new Error('No authentication token found');
          }

          const baseUrl = this.getApiBaseUrl();
          const params = new URLSearchParams({
            ...(this.pageSize && { page_size: this.pageSize }),
            ...(this.selectedStatus && { status: this.selectedStatus }),
            ...(this.selectedSport && { sport: this.selectedSport }),
            ...(this.selectedBookie && { bookie: this.selectedBookie }),
            ...(this.selectedMarket && { market: this.selectedMarket }),
            ...(this.startDate && { start_date: this.formatDateForApi(this.startDate) }),
            ...(this.endDate && { end_date: this.formatDateForApi(this.endDate, true) }),
            ...(this.searchQuery && { search: this.searchQuery }),
            _t: Date.now()
          });
          
          const response = await fetch(`${baseUrl}/tracker?${params}`, {
            headers: { 
              'Authorization': `Bearer ${token}`,
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': '0'
            }
          });
          
          if (!response.ok) throw new Error('Failed to fetch bets');
          
          const data = await response.json();

          const processedMatches = data.matches?.map(match => ({
            ...match,
            expanded: [], 
            bets: Array.isArray(match.bets) ? match.bets : 
                  (typeof match.bets === 'string' ? JSON.parse(match.bets) : [])
          })) || [];

          this.matches = processedMatches;
          this.apiStats = data.stats;

          this.refreshVirtualScroll();
          
          this.$emit('refresh-complete', { success: true });
          
        } catch (error) {
          console.error('Error fetching bets:', error);
          this.matches = [];
          this.apiStats = null;
          
          this.$emit('refresh-complete', { success: false, error: error.message });
        } finally {
          this.isLoading = false;
        }
      } catch (error) {
        this.error = error;
        if (this.retryCount < this.maxRetries) {
          this.retryCount++;
          await new Promise(resolve => setTimeout(resolve, 1000 * this.retryCount));
          return this.refreshData();
        }
      }
      this.measurePerformance('load', startTime);
    },
    getToken() {
      const userData = localStorage.getItem('userData') || sessionStorage.getItem('userData');
      if (userData) {
        const parsed = JSON.parse(userData);
        return parsed.token;
      }
      return null;
    },
    getLiveWinColor(percentage) {
      if (percentage >= 70) return 'success'
      if (percentage >= 50) return 'warning'
      return 'error'
    },
    startEdit(bet) {
      this.editingBet = {
        bet_id: bet.bet_id,
        odds: bet.odds,
        stake: bet.stake,
        result: bet.result
      }
    },

    cancelEdit() {
      this.editingBet = null
    },

    async saveBetEdit(bet) {
      try {
        const token = this.getToken();
        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const baseUrl = this.getApiBaseUrl();
        const response = await fetch(`${baseUrl}/tracker/bet/${bet.bet_id}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            odds: parseFloat(this.editingBet.odds),
            stake: parseFloat(this.editingBet.stake),
            result: this.editingBet.result
          })
        });

        if (!response.ok) throw new Error('Failed to update bet');

        // Update the bet in the UI
        bet.odds = parseFloat(this.editingBet.odds);
        bet.stake = parseFloat(this.editingBet.stake);
        bet.result = this.editingBet.result;

        this.editingBet = null;
        await this.refreshData(); 
      } catch (error) {
        console.error('Error updating bet:', error);
      }
    },

    async deleteBet(bet) {
      if (!confirm('Veto poistetaan, oletko varma?')) return;

      try {
        const token = this.getToken();
        if (!token) {
          console.error('No authentication token found');
          return;
        }

        const baseUrl = this.getApiBaseUrl();
        const response = await fetch(`${baseUrl}/tracker/bet/${bet.bet_id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to delete bet');

        await this.refreshData(); // Refresh the data
      } catch (error) {
        console.error('Error deleting bet:', error);
      }
    },
    openEditMatch(match) {
      this.editMatchForm = {
        match_id: match.match_id,
        home_score: match.home_score || 0,
        away_score: match.away_score || 0,
        status: match.match_status || 'pending'
      };
      this.showEditMatchDialog = true;
    },
    async saveMatchEdit() {
      try {
        const token = this.getToken();
        const baseUrl = this.getApiBaseUrl();
        const response = await fetch(`${baseUrl}/matches/${this.editMatchForm.match_id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            home_score: parseInt(this.editMatchForm.home_score),
            away_score: parseInt(this.editMatchForm.away_score),
            status: this.editMatchForm.status
          })
        });

        if (!response.ok) {
          throw new Error('Failed to update match');
        }

        // Close dialog and refresh data
        this.showEditMatchDialog = false;
        await this.refreshData();
        this.$emit('show-toast', {
          message: 'Ottelu päivitetty onnistuneesti',
          type: 'success'
        });
      } catch (error) {
        console.error('Error updating match:', error);
        this.$emit('show-toast', {
          message: 'Virhe ottelun päivityksessä: ' + error.message,
          type: 'error'
        });
      }
    },
    handleScoreUpdate(data) {
      const match = this.matches.find(m => m.match_id === data.match_id)
      if (match) {
        match.home_score = data.home_score
        match.away_score = data.away_score
        match.match_status = data.status
      }
    },
    refreshVirtualScroll() {
      this.virtualScrollKey++
    },
    calculateTotalProfit() {
      const total = (this.matches || []).reduce((sum, match) => {
        const bets = Array.isArray(match.bets) ? match.bets : [];
        return sum + bets.reduce((betSum, bet) => betSum + (Number(bet.profit) || 0), 0);
      }, 0);

      // Update cache in a method instead of computed
      this.updateProfitCache(total)
      return total
    },
    updateProfitCache(value) {
      this.profitCache = {
        value,
        matchesLength: this.matches.length
      }
    },
    translateResult(result) {
        return this.resultTranslations[result] || result;
    },
    handlePageSizeChange: debounce(function(value) {
      const numValue = parseInt(value);
      if (!isNaN(numValue) && numValue > 0 && numValue <= 20000) {
        this.refreshData();
      }
    }, 1000), 
    expandAll() {
      // Create a new array reference to force reactivity
      const updatedMatches = this.matches.map(match => ({
        ...match,
        expanded: [0]
      }));
      
      // Force reactive update
      this.matches = [];
      this.$nextTick(() => {
        this.matches = updatedMatches;
        this.virtualScrollKey++;
      });
    },
    collapseAll() {
      // Create a new array reference to force reactivity
      const updatedMatches = this.matches.map(match => ({
        ...match,
        expanded: []
      }));
      
      // Force reactive update
      this.matches = [];
      this.$nextTick(() => {
        this.matches = updatedMatches;
        this.virtualScrollKey++;
      });
    },
    formatDateForApi(date, isEndDate = false) {
      if (!date) return null;
      // Convert the date to ISO format and adjust for end of day if it's an end date
      const dateObj = new Date(date);
      if (isEndDate) {
        dateObj.setHours(23, 59, 59, 999);
      } else {
        dateObj.setHours(0, 0, 0, 0);
      }
      return dateObj.toISOString();
    },
    clearDateFilters() {
      this.startDate = null;
      this.endDate = null;
      this.selectedBookie = null;
      this.selectedMarket = null;
      this.debouncedRefresh();
    },
    toggleChart() {
      this.chartVisible = !this.chartVisible;
      if (this.chartVisible) {
        this.chartExpanded = [0]; // Expand when showing
      }
    },
    updateCountdowns() {
      // Force a reactive update of the countdowns
      this.matches = [...this.matches];
    },
    toggleSort(field) {
      if (this.sortBy === field) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = field;
        this.sortDesc = true; // Default to descending when changing sort field
      }
    },
    getSortIcon(field) {
      if (this.sortBy !== field) return 'mdi-sort';
      return this.sortDesc ? 'mdi-sort-descending' : 'mdi-sort-ascending';
    },
    handleFilterChange() {
      this.debouncedRefresh();
    },
    async handleScroll({ target }) {
      if (this.isLoadingMore || !this.hasMoreItems) return;
      
      const { scrollTop, clientHeight, scrollHeight } = target;
      if (scrollHeight - scrollTop - clientHeight < 200) {
        this.isLoadingMore = true;
        this.page++;
        await this.loadMoreData();
        this.isLoadingMore = false;
      }
    },

    async loadMoreData() {
      try {
        const response = await this.fetchData(this.page);
        if (response.matches.length === 0) {
          this.hasMoreItems = false;
          return;
        }
        this.matches = [...this.matches, ...response.matches];
      } catch (error) {
        console.error('Error loading more data:', error);
      }
    },
    measurePerformance(action, startTime) {
      const duration = performance.now() - startTime;
      if (action === 'render') {
        this.renderTimes.push(duration);
        if (this.renderTimes.length > 10) this.renderTimes.shift();
      } else if (action === 'load') {
        this.loadTimes.push(duration);
        if (this.loadTimes.length > 10) this.loadTimes.shift();
      }
      
      console.debug(`${action} took ${duration.toFixed(2)}ms`);
    },
    isMatchDelayed(match) {
      if (!['live', 'pending'].includes(match.match_status)) {
        return false;
      }
      
      const startTime = new Date(match.start_time);
      const now = new Date();
      const threeHoursInMs = 3 * 60 * 60 * 1000;
      
      return (now - startTime) > threeHoursInMs;
    },
    toggleStats() {
      this.showAllStats = !this.showAllStats;
    },
    handleCustomPageSize(value) {
      const numValue = parseInt(value);
      if (!isNaN(numValue) && numValue > 0 && numValue <= 20000) {
        this.pageSize = numValue;
        this.refreshData();
      }
    },
    calculateMatchProfit(match) {
      return match.bets.reduce((sum, bet) => sum + (bet.profit || 0), 0).toFixed(2);
    },
    calculateMatchExpectedProfit(match) {
      return match.bets.reduce((sum, bet) => sum + (bet.expected_profit || 0), 0).toFixed(2);
    },
    getStatusColorClass(value) {
      if (value > 0) return 'text-success';
      if (value < 0) return 'text-red-darken-3';
      return 'text-medium-emphasis';
    },
    updateFilterOptions() {
      // Get unique bookies from matches
      const bookies = new Set();
      const markets = new Set();
      
      this.matches.forEach(match => {
        match.bets.forEach(bet => {
          bookies.add(bet.bookie);
          markets.add(bet.market);
        });
      });
      
      this.bookieOptions = Array.from(bookies).sort();
      this.marketOptions = Array.from(markets).sort();
    },
  },
  watch: {
    selectedStatus() {
      this.handleFilterChange();
    },
    selectedSport() {
      this.handleFilterChange();
    },
    startDate() {
      this.handleFilterChange();
    },
    endDate() {
      this.handleFilterChange();
    },
    memoizedStats: {
      handler(newValue) {
        this.statsCache = {
          matchesLength: this.matches.length,
          apiStats: this.apiStats,
          value: newValue
        };
      },
      deep: true
    },
    searchQuery() {
      this.handleFilterChange();
    },
    matches: {
      handler() {
        this.updateFilterOptions();
      },
      deep: true
    }
  },
  mounted() {
    // Add window resize listener
    window.addEventListener('resize', this.refreshVirtualScroll);
    this.refreshData();
    
    // Start countdown timer
    this.countdownInterval = setInterval(() => {
      this.updateCountdowns();
    }, 60000); // Update every minute

    const startTime = performance.now();
    this.$nextTick(() => {
      this.measurePerformance('render', startTime);
    });
    this.updateFilterOptions();
  },
  beforeUnmount() {
    // Remove window resize listener
    window.removeEventListener('resize', this.refreshVirtualScroll);
    if (this.ws) {
      this.ws.close()
    }
    // Clear caches
    this.statusColorCache.clear()
    this.profitCache = {
      value: null,
      matchesLength: 0
    }
    clearTimeout(this.debounceTimer)
    
    // Clear countdown interval
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
}
</script>

<style>
.matches-container {
  position: relative;
  min-height: 0;
}

.stat-col {
  padding: 2px !important;
}

.stat-card {
  margin: 0 !important;
}

.stat-content {
  padding: 4px !important;
}

.v-expansion-panel-title {
  padding: 8px 8px !important;
  min-height: unset !important;
}

@keyframes breathe {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

.live-score {
  color: rgb(198, 40, 40) !important;
  font-weight: 500;
  font-size: 0.99rem;
  animation: breathe 2s ease-in-out infinite;
}

.team-name {
  color: var(--v-theme-on-surface);
  font-size: 0.99rem;
  font-weight: 300;
}

.match-score {
  font-weight: 400;
  font-size: 0.98rem;
  color: rgb(196, 196, 196);
}

.match-info {
  color: var(--v-theme-on-surface-variant);
}

.match-date {
  color: var(--v-theme-on-surface-variant);
  font-weight: 500;
}

.match-sport {
  color: var(--v-theme-on-surface-variant);
  font-weight: 500;
}

.match-league {
  color: var(--v-theme-on-surface-variant);
}

.live-score {
  color: rgb(198, 40, 40) !important;
  font-weight: 500;
  font-size: 0.99rem;
  animation: breathe 2s ease-in-out infinite;
}

/* Add hover effect for better interaction feedback */
.v-expansion-panel-title:hover .team-name,
.v-expansion-panel-title:hover .match-score:not(.live-score) {
  color: var(--v-theme-primary);
}

/* Add transition for smooth color changes */
.team-name,
.match-score,
.match-info,
.match-date,
.match-sport,
.match-league {
  transition: color 0.2s ease;
}

.tracking-wrapper {
  height: calc(100vh - 48px); /* Subtract toolbar height */
  display: flex;
  flex-direction: column;
  overflow: hidden; 
}

.tracking-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.matches-container {
  flex: 1;
  min-height: 0; 
  display: flex;
  flex-direction: column;
}

.pagination-controls {
  padding: 8px;
  background: var(--v-theme-surface);
  border-top: 1px solid var(--v-border-color);
  flex-shrink: 0;
}

.v-virtual-scroll {
  flex: 1;
  min-height: 0;
}

.date-field {
  min-width: 150px;
}

.status-indicator,
.status-live,
.status-win,
.status-loss,
.status-mixed,
.status-cancelled,
.status-pending,
.status-half-win,
.status-half-loss {
  display: none;
}

.v-expansion-panel-title {
  padding-left: 0 !important;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.v-btn {
  text-transform: none !important;
}

.live-chip {
  color: rgb(198, 40, 40) !important;
  animation: breathe 2s ease-in-out infinite;
}

.live-chip .v-chip__content {
  color: rgb(198, 40, 40) !important;
  animation: breathe 2s ease-in-out infinite;
}

/* Add will-change for smoother animations */
.v-virtual-scroll {
  will-change: transform;
}

/* Use transform instead of top/left for animations */
.v-expansion-panel-content {
  transform: translateZ(0);
}

/* Optimize rendering performance */
.tracking-wrapper {
  contain: content;
}

.matches-container {
  contain: strict;
  height: 100%;
}

.live-chip {
  transform: translateZ(0);
  will-change: opacity;
}

.stat-card {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.gap-2 {
  gap: 8px;
}

.v-btn-group {
  flex-wrap: nowrap;
}

.status-chip {
  min-width: 24px !important;
  height: 24px !important;
  padding: 0 8px !important;
  margin-right: 8px !important;
}

.profit-gap {
  margin-left: 8px; 
}

@media (max-width: 955px) {
  .profit-gap {
    font-size: 0.77rem;
    padding: 2px;
  }
  
  .search-field {
    max-width: none;
    font-size: 0.8rem !important;
  }
  
  .search-field .v-field__input {
    min-height: 32px !important;
    padding: 0 !important;
  }
  
  .search-field .v-field__append-inner {
    padding-inline-start: 4px !important;
  }

  .team-name {
    font-size: 0.75rem !important;
    font-weight: 300 !important;
  }

  .match-score {
    font-size: 0.7rem !important;
  }

  .match-info {
    font-size: 0.65rem !important;
    line-height: 1.1 !important;
  }

  .match-date,
  .match-sport,
  .match-league {
    font-size: 0.65rem !important;
  }

  .v-chip {
    font-size: 0.55rem !important;
  }

  .v-icon {
    font-size: 16px !important;
  }

  .v-expansion-panel {
    margin-bottom: 1px !important;
  }

  .live-chip {
    padding: 0 !important;
    margin-left: 2px !important;
  }

  .v-expansion-panel-title .v-chip {
    height: 20px !important;
    min-width: 20px !important;
    padding: 0 6px !important;
  }

  .v-expansion-panel-title .v-chip.status-chip {
    height: 20px !important;
    min-width: 20px !important;
    padding: 0 6px !important;
  }

  .v-expansion-panel-title .v-chip.live-chip {
    height: 20px !important;
    min-width: 20px !important;
    padding: 0 6px !important;
  }

  .v-expansion-panel-title .v-chip .v-chip__content {
    font-size: 0.7rem !important;
    line-height: 1 !important;
    padding: 0 !important;
  }

  .v-expansion-panel-title .live-chip {
    padding: 0 !important;
    margin-left: 2px !important;
    height: 12px !important;
  }

  .v-expansion-panel-title .v-chip__underlay {
    border-radius: 2px !important;
  }

  .v-btn {
    font-size: 0.7rem !important;
  }

  .match-countdown {
    font-size: 0.65rem !important;
    color: rgba(var(--v-theme-on-surface), 0.7) !important;
  }

  .stat-col {
    padding: 0 !important;
  }

  .text-caption-small {
    font-size: 0.45rem !important;
    line-height: 1 !important;
    opacity: 0.8 !important;
  }

  .stat-card .text-caption {
    font-size: 0.6rem !important;
    line-height: 1.1 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .stat-card {
    margin: 0 !important;
    min-height: unset !important;
  }

  .stat-content {
    padding: 2px !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .stat-col {
    padding: 1px !important;
  }

  .text-success {
    opacity: 0.9 !important;
  }

  .text-error {
    opacity: 1 !important;
    color: rgb(198, 40, 40) !important;
  }

  .pagination-controls {
    padding: 2px !important;
  }

  .pagination-controls .v-pagination__item {
    height: 24px !important;
    min-width: 24px !important;
    font-size: 0.7rem !important;
    padding: 0 !important;
  }

  .pagination-controls .v-pagination__navigation {
    height: 24px !important;
    width: 24px !important;
    margin: 0 2px !important;
  }

  .pagination-controls .v-pagination__navigation .v-icon {
    font-size: 16px !important;
  }

  .pagination-controls .v-select {
    max-width: 110px !important;
    font-size: 0.7rem !important;
  }

  .pagination-controls .v-field {
    height: 24px !important;
    min-height: 24px !important;
  }

  .pagination-controls .v-field__input {
    min-height: 24px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .pagination-controls .v-select__selection {
    font-size: 0.7rem !important;
  }

  .pagination-controls {
    padding: 2px 4px !important;
    gap: 4px !important;
  }

  .items-per-page-select {
    .v-field__input {
      min-height: 24px !important;
      padding: 0 !important;
    }

    .v-field__append-inner {
      padding-inline-start: 4px !important;
    }

    .v-select__selection {
      font-size: 0.65rem !important;
    }

    .v-icon {
      font-size: 12px !important;
    }
  }

  /* Show fewer pagination buttons on mobile */
  .v-pagination .v-pagination__item {
    display: none;
  }

  .v-pagination .v-pagination__item.v-pagination__item--active,
  .v-pagination .v-pagination__item:first-child,
  .v-pagination .v-pagination__item:last-child,
  .v-pagination .v-pagination__navigation {
    display: flex;
  }

  .stat-card .text-caption {
    font-size: 0.6rem !important;
    line-height: 1.1 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .stat-card .text-caption[title="Tuotto"],
  .stat-card .text-caption[title="Odotettu tuotto"] {
    font-size: 0.55rem !important;
    letter-spacing: -0.2px !important;
  }

  .stat-card .text-caption[title="Avoimet"] {
    font-size: 0.55rem !important;
    letter-spacing: -0.2px !important;
  }

  .stat-card .text-caption[title="Pelattu"] {
    font-size: 0.55rem !important;
    letter-spacing: -0.2px !important;
  }

  .stat-card .text-caption[title="Keskipanos/kerroin"] {
    font-size: 0.55rem !important;
    letter-spacing: -0.2px !important;
  }
}

.text-red-darken-3 {
  color: rgb(198, 40, 40) !important;
}

.search-field {
  max-width: 300px;
}

</style>
