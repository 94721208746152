// Helper functions
function dixonColesCorrection(homeGoals, awayGoals, homePred, awayPred, rho) {
  if (homeGoals === 0 && awayGoals === 0) {
    return 1 - homePred * awayPred * rho;
  } else if (homeGoals === 0 && awayGoals === 1) {
    return 1 + homePred * rho;
  } else if (homeGoals === 1 && awayGoals === 0) {
    return 1 + awayPred * rho;
  } else if ([1,2,3,4,5].includes(homeGoals) && homeGoals === awayGoals) {
    return 1 - rho;
  }
  return 1;
}

function poissonPmf(k, lambda) {
  return Math.exp(-lambda) * Math.pow(lambda, k) / factorial(k);
}

function factorial(n) {
  if (n === 0 || n === 1) return 1;
  return n * factorial(n - 1);
}

function createMatrix(size) {
  return Array(size + 1).fill().map(() => Array(size + 1).fill(0));
}

function calculateProbabilities(homePred, awayPred, homeScore, awayScore) {
  const startingRho = -0.07;
  const goalFactorMultiplier = 0.25;
  const favBoostMultiplier = 0.03;
  let maxGoals = 10;
  const rhoValue = 0;

  if (homeScore > 0 || awayScore > 0) {
    maxGoals = homeScore + awayScore + 10;
  }

  const goalDiff = Math.abs(homePred - awayPred);
  const totalGoals = homePred + awayPred;

  const rho = startingRho * totalGoals * goalFactorMultiplier - rhoValue;
  const favouriteBoost = goalDiff * favBoostMultiplier + 1;

  const homeAdjustment = homePred > awayPred ? favouriteBoost : 2 - favouriteBoost;
  const awayAdjustment = awayPred > homePred ? favouriteBoost : 2 - favouriteBoost;

  const matrix = createMatrix(maxGoals);
  let totalProb = 0;

  // First pass: Calculate probabilities
  for (let i = 0; i <= maxGoals; i++) {
    for (let j = 0; j <= maxGoals; j++) {
      if (i < homeScore || j < awayScore) {
        matrix[i][j] = 0;
      } else {
        // Calculate probabilities for remaining goals
        const remainingHomeGoals = i - homeScore;
        const remainingAwayGoals = j - awayScore;
        
        const homePoisson = poissonPmf(remainingHomeGoals, homePred) * (i > j ? homeAdjustment : 1);
        const awayPoisson = poissonPmf(remainingAwayGoals, awayPred) * (j > i ? awayAdjustment : 1);
        const correction = dixonColesCorrection(remainingHomeGoals, remainingAwayGoals, homePred, awayPred, rho);
        
        matrix[i][j] = homePoisson * awayPoisson * correction;
        totalProb += matrix[i][j];
      }
    }
  }

  // Add console logging to verify
  console.log(`Current score: ${homeScore}-${awayScore}`);
  console.log('Sample of matrix values before normalization:');
  for (let i = 0; i <= Math.min(5, maxGoals); i++) {
    for (let j = 0; j <= Math.min(5, maxGoals); j++) {
      if (matrix[i][j] > 0) {
        console.log(`Score ${i}-${j}: ${matrix[i][j]}`);
      }
    }
  }

  // Normalize only if we have valid probabilities
  if (totalProb > 0) {
    for (let i = 0; i <= maxGoals; i++) {
      for (let j = 0; j <= maxGoals; j++) {
        matrix[i][j] /= totalProb;
      }
    }
  }

  let homeWinProb = 0;
  let drawProb = 0;
  let awayWinProb = 0;

  for (let i = 0; i <= maxGoals; i++) {
    for (let j = 0; j <= maxGoals; j++) {
      if (i > j) homeWinProb += matrix[i][j];
      else if (i === j) drawProb += matrix[i][j];
      else awayWinProb += matrix[i][j];
    }
  }

  return {
    H: 1 / homeWinProb,
    D: 1 / drawProb,
    A: 1 / awayWinProb,
    matrix
  };
}

function calculateHandicapProbabilities(matrix, handicap) {
  let homeWinProb = 0;
  let awayWinProb = 0;

  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[0].length; j++) {
      const adjustedHomeGoals = i + handicap;
      if (adjustedHomeGoals > j) {
        homeWinProb += matrix[i][j];
      } else if (adjustedHomeGoals < j) {
        awayWinProb += matrix[i][j];
      }
    }
  }

  const totalProb = homeWinProb + awayWinProb;
  if (totalProb > 0) {
    homeWinProb /= totalProb;
    awayWinProb /= totalProb;
  }

  return {
    home: homeWinProb > 0 ? 1 / homeWinProb : null,
    away: awayWinProb > 0 ? 1 / awayWinProb : null
  };
}

function calculateTotalsProbabilities(matrix, total) {
  let overProb = 0;
  let underProb = 0;

  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[0].length; j++) {
      const totalGoals = i + j;
      if (totalGoals > total) {
        overProb += matrix[i][j];
      } else if (totalGoals < total) {
        underProb += matrix[i][j];
      }
    }
  }

  const totalProb = overProb + underProb;
  if (totalProb > 0) {
    overProb /= totalProb;
    underProb /= totalProb;
  }

  return {
    over: overProb > 0 ? 1 / overProb : null,
    under: underProb > 0 ? 1 / underProb : null
  };
}

export function calculateAllOdds(homeXG, awayXG, homeScore, awayScore) {
  const baseOdds = calculateProbabilities(homeXG, awayXG, homeScore, awayScore);
  const matrix = baseOdds.matrix;

  const handicapLines = [-7, -6, -5, -4, -3, -2.5, -2, -1.5, -1, -0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7];
  const handicaps = {};
  handicapLines.forEach(line => {handicaps[line] = calculateHandicapProbabilities(matrix, line);});

  const totalLines = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10];
  const totals = {};
  totalLines.forEach(line => {totals[line] = calculateTotalsProbabilities(matrix, line);
  });

  return {
    match: {
      H: baseOdds.H,
      D: baseOdds.D,
      A: baseOdds.A
    },
    handicaps,
    totals
  };
} 